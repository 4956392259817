/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
/* styles.scss */
@import '~video.js/dist/video-js.css';
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";



// .video-container {
//     position: relative;
//     width: 100%;
//     height: 100vh;
//     overflow: hidden;
// }

// #video-bg {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     /* Set the width to 100% */
//     height: 100%;
// }

/* styles.css (or your component's CSS file) */
// .process-item {
//     opacity: 0;
//     transform: translateX(-50%);
//     transition: opacity 0.5s ease, transform 0.5s ease;
//   }

//   .process-item.active {
//     opacity: 1;
//     transform: translateX(0);
//   }
/* your.component.css */
.carousel {
    width: 80%;
    margin: auto;
}

.city-card {
    /* Your card styles */
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.city-card img {
    max-width: 100%;
    height: auto;
}

.video-container {
    position: relative;
    overflow: hidden;
}

#video-bg {
    position: absolute;
    width: 100%;
    height: auto;
    transform-origin: center center;
    animation: zoom 20s linear infinite;
    /* Adjust the duration as needed */
}

@keyframes zoom {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}



::ng-deep .ngx-youtube-player-container {
    /* Your custom styles here */
    width: 100% !important;
    height: 0 !important;
    padding-bottom: 75% !important;
    /* Adjust the aspect ratio as needed */
}

::ng-deep .ngx-youtube-player-container iframe {
    /* Your custom styles for the iframe */
    width: 100% !important;
    height: 100% !important;
}













.pt-25 {
    padding-top: 25px;
}

.images {
    opacity: 0.7;
}

.images:hover {
    opacity: 1.0;
}


/* styles.scss */
.custom-ag-grid-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ag-header {
    background-color: #2196f3;
    /* Header color */
    color: #ffffff;
    /* Header text color */
}

.ag-header-cell {
    border-bottom: 1px solid #ffffff;
    /* Header cell border */
}

.ag-footer {
    background-color: #2196f3;
    /* Footer color */
    color: #ffffff;
    /* Footer text color */
}

.ag-footer-cell {
    border-top: 1px solid #ffffff;
    /* Footer cell border */
}

.ag-header-cell-filter {
    background-color: #e0e0e0;
    /* Header filter background color */
}

.ag-theme-alpine {
    height: 100%;
    width: 100%;
}

.zoomable-photo-cell img {
    transition: transform 0.3s ease;
    /* Add a smooth transition effect */
}

.zoomable-photo-cell:hover img {
    transform: scale(1.2);
    /* Zoom effect on hover */
}


.Actions-btn {
    margin-right: 10px;
    /* Add margin to the right of each button */
    padding: 0px 15px;
    color: var(--whiteColor);
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-transform: capitalize;
    background-color: var(--mainColor);
    border: none;
    outline: none;

    i {
        margin-left: 5px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: var(--titleColor);
        z-index: -1;
        transition: all 0.5s;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 100%;
        background-color: var(--titleColor);
        z-index: -1;
        transition: all 0.5s;
    }

    &:hover {
        color: var(--whiteColor);
        border: none;
    }

    &:hover::before {
        left: auto;
        right: 0;
        width: 50%;
    }

    &:hover::after {
        left: 0;
        right: 0;
        width: 50%;
    }
}

/*Start Overlay div Loader*/
.overlay-inner {
    min-height: 200px;
    text-align: center;
    vertical-align: middle;
    line-height: 200px;
    font-size: 30px;
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /*background-color: rgba(33,37,41,0.3);*/
    z-index: 2;
}

.overlay-inner i {
    position: absolute;
    top: 49%;
    margin-top: 25px;
}

/*End Overlay div Loader*/

:root {
    --fontFamily: 'Poppins', sans-serif;
    --mainColor: #f39609;
    --paragraphColor: #8c8c8c;
    --whiteColor: #ffffff;
    --titleColor: #2a2a2a;
    --optionalColor: #ffc0a7;
    --fontSize: 16px;
    --transition: .5s;
}

body {
    padding: 0;
    margin: 0;

    font: {
        size: var(--fontSize);
        family: var(--fontFamily);
    }

    ;
}

p {
    color: var(--paragraphColor);
}

a {
    display: inline-block;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

button {
    margin: 0;
    padding: 0;
    outline: 0;

    &:focus {
        outline: 0;
        border: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    color: var(--titleColor);
    line-height: 1.4;
}

h3 {
    font-size: 20px;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    display: table-cell;
    vertical-align: middle;
}

img {
    max-width: 100%;
}

.border-radius {
    border-radius: 50px;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.ptb-700 {
    padding-top: 100px;
    padding-bottom: 100px;
}


.pb-100 {
    padding-bottom: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pt-45 {
    padding-top: 45px;
}

.mb-45 {
    margin-bottom: 45px;
}

.bg-dark-orange {
    background-color: var(--mainColor);
}

.bg-color-blue {
    background-color: #2d5eff;
}

.bg-color-heliotrope {
    background-color: #cf3cff;
}

.bg-color-green {
    background-color: #028311;
}

.text-color-white {
    color: var(--whiteColor) !important;
}

.margin-auto {
    margin: 0 auto;
}

/*================================
Default Btn Style
===================================*/

.default-btn {
    padding: 12px 27px;
    color: var(--whiteColor);
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-transform: capitalize;
    background-color: var(--mainColor);
    border: none;
    outline: none;

    i {
        margin-left: 5px;
    }

    &::before {
        content: "";
        position: absolute;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: var(--titleColor);
        z-index: -1;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 100%;
        background-color: var(--titleColor);
        z-index: -1;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }

    &:hover {
        color: var(--whiteColor);
        border: none;
    }

    &:hover::before {
        left: auto;
        right: 0;
        width: 50%;
    }

    &:hover::after {
        left: 0;
        right: 0;
        width: 50%;
    }
}

/*================================
Default Btn Style End
===================================*/

/*================================
Section Title Style
===================================*/
.section-title {
    span {
        color: var(--mainColor);
        line-height: 0;
        font-size: 16px;
        text-transform: capitalize;
        margin: 0;
        padding-bottom: 5px;
        font-weight: 600;
    }

    h2 {
        font-size: 38px;
        font-weight: 500;
        margin-top: 5px;
        line-height: 1.5;

        b {
            font-weight: 300;
        }
    }

    p {
        padding-top: 10px;
        margin-bottom: 0;
    }
}

/*================================
Section Title Style End
===================================*/

/*=================================
Navbar Area
====================================*/
.navbar-area {
    background-color: transparent;
    transition: 0.3s;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
}

.navbar-light {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;

    .navbar-brand {
        img {
            float: left;
            padding: 5px 0;
        }
    }

    .navbar-brand-sticky {
        display: none;
    }
}

/* Main nav */
.main-nav {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    z-index: 999;
    height: auto;

    nav {
        .navbar-nav {
            .nav-item {

                &:hover a,
                .active {
                    color: var(--mainColor) !important;
                }

                a {
                    text-transform: capitalize;
                    color: var(--whiteColor);
                    font-weight: 400;
                    margin-left: 12px;
                    margin-right: 12px;

                    i {
                        line-height: 0;
                        position: relative;
                        top: 3px;
                        font-size: 18px;
                    }

                    &:hover,
                    :focus {
                        color: var(--mainColor) !important;
                    }

                    &.active {
                        color: var(--mainColor) !important;
                    }

                    .active::before {
                        transform: scale(1);
                    }
                }

                .dropdown-menu {
                    border: none;
                    border-top: 3px solid var(--mainColor);
                    padding: 0;
                    border-radius: 0;
                    background-color: var(--whiteColor) !important;

                    li {
                        border-bottom: 1px solid var(--whiteColor);

                        &:last-child {
                            border-bottom: none;
                        }

                        a {
                            text-transform: capitalize;
                            color: var(--titleColor) !important;
                            position: relative;
                            transition: 0.7s;
                            font-weight: 400;
                            padding: 10px;
                            border-bottom: 1px dashed #ebebeb;

                            &.active {
                                border-radius: .25rem;
                                color: var(--mainColor) !important;
                            }

                            &:hover,
                            :focus,
                            .active {
                                color: var(--mainColor) !important;
                                margin-left: 5px;
                                border-radius: .25rem;
                            }
                        }

                        &.active {
                            color: var(--mainColor) !important;
                        }

                        .dropdown-menu {
                            left: 100%;
                            top: 20px;

                            li {
                                .dropdown-menu {
                                    left: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .side-nav {
            padding: 10px 18px 3px;
            margin-left: 30px;

            .side-item {
                padding-left: 15px;
                position: relative;
                display: inline-block;

                .cart-btn {
                    position: relative;
                    top: -12px;

                    i {
                        display: inline-block;
                        font-size: 25px;
                        color: var(--whiteColor);
                        border-radius: 50px;
                        text-align: center;
                    }

                    span {
                        position: absolute;
                        right: 0;
                        bottom: 0px;
                        width: 18px;
                        height: 18px;
                        text-align: center;
                        line-height: 20px;
                        border-radius: 50%;
                        background-color: var(--whiteColor);
                        color: var(--mainColor);
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                .search-box {
                    position: relative;
                    top: -12px;

                    i {
                        cursor: pointer;
                        font-size: 18px;
                        color: var(--whiteColor);
                        position: relative;
                        display: inline-block;
                        top: -2px;
                        line-height: 35px;
                        border-radius: 50px;
                        width: 35px;
                        height: 35px;
                        border: 1px solid var(--whiteColor);
                        text-align: center;
                        transition: var(--transition);
                    }

                    &:hover i {
                        border-color: var(--mainColor);
                        color: var(--mainColor);
                    }
                }

                .user-btn {
                    position: relative;
                    top: -12px;

                    i {
                        cursor: pointer;
                        font-size: 18px;
                        color: var(--whiteColor);
                        position: relative;
                        display: inline-block;
                        top: -2px;
                        line-height: 35px;
                        border-radius: 50px;
                        width: 35px;
                        height: 35px;
                        border: 1px solid var(--whiteColor);
                        text-align: center;
                        transition: var(--transition);
                    }

                    &:hover i {
                        border-color: var(--mainColor);
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
}

.nav-two {
    position: inherit;
    background-color: var(--whiteColor);

    nav {
        .navbar-nav {
            .nav-item {
                a {
                    color: var(--titleColor);
                }
            }
        }

        .side-nav {
            .side-item {
                .cart-btn {
                    i {
                        color: var(--mainColor);
                    }

                    span {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                    }

                    &:hover {
                        i {
                            color: var(--titleColor);
                        }

                        span {
                            background-color: var(--titleColor);
                        }

                    }
                }

                .search-box {
                    i {
                        color: var(--mainColor);
                        border: 1px solid var(--mainColor);
                    }

                    &:hover i {
                        border-color: var(--titleColor);
                        color: var(--titleColor);
                    }
                }

                .user-btn {
                    position: relative;
                    top: -12px;

                    i {
                        color: var(--mainColor);
                        border: 1px solid var(--mainColor);
                    }

                    &:hover i {
                        border-color: var(--titleColor);
                        color: var(--titleColor);
                    }
                }
            }
        }
    }
}

.side-nav-responsive {
    display: none;

    .dot-menu {
        padding: 0 10px;
        height: 30px;
        cursor: pointer;
        z-index: 9999;
        position: absolute;
        right: 60px;
        top: 12px;

        .circle-inner {
            display: flex;
            align-items: center;
            height: 30px;

            .circle {
                height: 5px;
                width: 5px;
                border-radius: 100%;
                margin: 0 2px;
                transition: var(--transition);
                background-color: var(--whiteColor);
            }
        }

        &:hover {
            .circle-inner {
                .circle {
                    background-color: var(--mainColor);
                }
            }
        }
    }

    .container {
        position: relative;
        display: flex;

        .container {
            position: absolute;
            right: 0;
            top: 55px;
            max-width: 300px;
            margin-left: auto;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
            transform: scaleX(0);
            z-index: 2;
            padding-left: 15px;
            padding-right: 15px;
        }

        .container.active {
            opacity: 1;
            visibility: visible;
            transform: scaleX(1);
        }
    }

    .side-nav-inner {
        padding: 10px;
        -webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
        background-color: var(--whiteColor);

        .side-nav {
            background-color: var(--mainColor);
            border-radius: 15px;
            padding: 10px 18px 3px;

            .side-item {
                margin-left: 15px;
                padding-left: 15px;
                position: relative;
                display: inline-block;

                .cart-btn {
                    position: relative;
                    display: inline-block;
                    top: -4px;

                    i {
                        display: inline-block;
                        font-size: 25px;
                        color: var(--whiteColor);
                        border-radius: 50px;
                        text-align: center;
                    }

                    span {
                        position: absolute;
                        right: 0;
                        bottom: 0px;
                        width: 18px;
                        height: 18px;
                        text-align: center;
                        line-height: 20px;
                        border-radius: 50%;
                        background-color: var(--whiteColor);
                        color: var(--mainColor);
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                .nav-add-btn {
                    margin-top: 15px;

                    .default-btn {
                        background-color: transparent;
                        border: 1px solid var(--whiteColor);
                    }
                }
            }

            .search-box {
                position: relative;
                display: inline-block;

                i {
                    cursor: pointer;
                    font-size: 18px;
                    color: var(--whiteColor);
                    position: relative;
                    display: inline-block;
                    top: -2px;
                    line-height: 35px;
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    border: 1px solid var(--whiteColor);
                    text-align: center;
                    transition: var(--transition);
                }

                &:hover i {
                    border-color: var(--mainColor);
                    color: var(--mainColor);
                }
            }

            .user-btn {
                position: relative;
                display: inline-block;

                i {
                    cursor: pointer;
                    font-size: 18px;
                    color: var(--whiteColor);
                    position: relative;
                    display: inline-block;
                    top: -2px;
                    line-height: 35px;
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    border: 1px solid var(--whiteColor);
                    text-align: center;
                    transition: var(--transition);
                }

                &:hover i {
                    border-color: var(--mainColor);
                    color: var(--mainColor);
                }
            }
        }
    }
}

.sticky-nav {
    top: 0;
    position: fixed;
    animation: 900ms ease-in-out 5s normal none 1 running fadeInDown;
    transition: 0.9s;
    width: 100% !important;
    z-index: 999;

    .main-nav {
        top: 0;
        background-color: var(--titleColor);
        position: fixed;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }

    .nav-two {
        top: 0;
        background-color: var(--whiteColor);
        position: fixed;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }
}

/*=================================
Navbar Area
====================================*/

/*==================================
Search Overlay CSS
====================================*/
.search-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    .search-layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);

        &:nth-child(1) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
        }

        &:nth-child(2) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-transition: all 0.3s ease-in-out 0.3s;
            transition: all 0.3s ease-in-out 0.3s;
        }

        &:nth-child(3) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-transition: all 0.9s ease-in-out 0.6s;
            transition: all 0.9s ease-in-out 0.6s;
        }
    }

    .search-close {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 50px;
        z-index: 2;
        text-align: center;
        cursor: pointer;
        padding: 10px;
        transition: all 0.9s ease-in-out 1.5s;
        opacity: 0;
        visibility: hidden;

        .search-close-line {
            width: 100%;
            height: 3px;
            float: left;
            margin-bottom: 5px;
            background-color: var(--whiteColor);
            transition: all 500ms ease;

            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                margin-top: -7px;
                transform: rotate(-45deg);
            }
        }

        &:hover {
            .search-close-line {
                background: var(--mainColor);
                transform: rotate(180deg);
            }
        }
    }

    .search-form {
        transition: all 0.9s ease-in-out 1.4s;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 2;
        max-width: 500px;
        width: 500px;
        padding: 0 15px;

        form {
            position: relative;

            .input-search {
                display: block;
                width: 100%;
                height: 60px;
                border: none;
                border-radius: 30px;
                color: var(--titleColor);
                padding: 3px 0 0 25px;

                &::-webkit-input-placeholder {
                    transition: var(--transition);
                    letter-spacing: .5px;
                    color: var(--titleColor);
                }

                &:-ms-input-placeholder {
                    -webkit-transition: var(--transition);
                    transition: var(--transition);
                    letter-spacing: .5px;
                    color: var(--titleColor);
                }

                &::-ms-input-placeholder {
                    -webkit-transition: var(--transition);
                    transition: var(--transition);
                    letter-spacing: .5px;
                    color: var(--titleColor);
                }

                &::placeholder {
                    -webkit-transition: var(--transition);
                    transition: var(--transition);
                    letter-spacing: .5px;
                    color: var(--titleColor);
                }

                &:focus {
                    border: none;
                    outline: none;

                    &::-webkit-input-placeholder {
                        color: transparent;
                    }

                    &:-ms-input-placeholder {
                        color: transparent;
                    }

                    &::-ms-input-placeholder {
                        color: transparent;
                    }

                    &::placeholder {
                        color: transparent;
                    }
                }
            }

            button {
                position: absolute;
                right: 5px;
                top: 5px;
                width: 50px;
                color: var(--whiteColor);
                height: 50px;
                border-radius: 50%;
                background-color: var(--mainColor);
                -webkit-transition: var(--transition);
                transition: var(--transition);
                border: none;
                font-size: 20px;
                line-height: 45px;

                &:hover {
                    background-color: var(--titleColor);
                    color: var(--mainColor);
                }
            }

            &:hover {
                border: none;
                outline: none;
            }
        }
    }
}

.search-overlay.search-overlay-active.search-overlay {
    opacity: 1;
    visibility: visible;

    .search-layer {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    .search-close {
        opacity: 1;
        visibility: visible;
    }

    .search-form {
        opacity: 1;
        visibility: visible;
    }
}

/*==================================
Search Overlay CSS End
=================================*/

/*=================================
Home Banner Area
====================================*/
.slider-area {
    position: relative;

    .owl-nav {
        margin-top: 0;

        .owl-prev {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            left: 45px;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;

            &:hover {
                color: var(--whiteColor) !important;
                background-color: transparent !important;
            }
        }

        .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            right: 45px;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;

            &:hover {
                color: var(--whiteColor) !important;
                background-color: transparent !important;
            }
        }
    }
}

.slider-item {
    text-align: center;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.5;
    }

    .slider-content {
        padding-top: 285px;
        padding-bottom: 270px;
        position: relative;
        z-index: 1;

        h1 {
            font-size: 60px;
            color: var(--whiteColor);
            max-width: 790px;
            margin: 0 auto;

            b {
                font-weight: 300;
            }
        }
    }
}

.item-bg1 {
    background-image: url(assets/img/home-one/home1.jpg);
}

.item-bg2 {
    background-image: url(assets/img/home-one/home2.jpg);
}

.item-bg3 {
    background-image: url(assets/img/home-one/home3.jpg);
}

.item-bg4 {
    background-image: url(assets/img/home-one/home4.jpg);
}

.banner-area {
    background-image: url(assets/img/home2.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.banner-content {
    padding-top: 150px;
    padding-bottom: 190px;

    p {
        color: var(--mainColor);
    }

    h1 {
        font-size: 90px;
        color: var(--titleColor);
        font-weight: 700;
        max-width: 590px;
        margin-bottom: 20px;

        b {
            font-weight: 300;
        }
    }

    .form-list {
        margin-bottom: 35px;

        ul {
            li {
                color: var(--titleColor);
            }
        }
    }
}

.banner-content-ptb {
    padding-top: 160px !important;
    padding-bottom: 230px !important;
}

.banner-area-two {
    // background-image: url(assets/img/home-three/home-three1.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.banner-img {
    position: absolute;
    bottom: 0;
    left: 200px;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: -1;

    img {
        width: 780px;
    }
}

/*=================================
Home Banner Area End
====================================*/

/*=================================
Banner Form Area
====================================*/
.form-list {
    margin-bottom: 50px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin: 0 10px;
            color: var(--whiteColor);
            text-transform: uppercase;
            font-weight: 500;

            i {
                font-size: 18px;
                color: #e9272a;
                margin-right: 5px;
                opacity: 0;
                transform: var(--transition);
            }

            &:hover {
                color: var(--mainColor);

                i {
                    opacity: 1;
                }
            }

            &.active {
                color: var(--mainColor);

                i {
                    opacity: 1;
                }
            }
        }
    }
}

.banner-form {
    background-color: var(--whiteColor);
    padding: 15px 0 15px 40px;
    box-shadow: 0 0 15px #2a2a2a;

    .form-group {
        position: relative;
        margin-bottom: 0;

        i {
            position: absolute;
            top: 15px;
            left: 0;
            line-height: 1;
            font-size: 18px;
            color: var(--mainColor);
            z-index: 1;
        }

        .form-control {
            height: 50px;
            padding: 10px 20px 10px 25px;
            border: none;
            border-right: 1px solid #ebebeb;
            color: var(--paragraphColor);
            background-color: var(--whiteColor);
            font-weight: 400;
            border-radius: 0;

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        textarea.form-control {
            height: auto;
        }
    }

    .nice-select {
        height: 50px;
        width: 100%;
        line-height: 32px;
        font-size: 15px;
        margin-bottom: 0px;
        padding-left: 20px;
        border-radius: 50px;
        background-color: var(--mainColor);
        color: var(--whiteColor);

        .list {
            background-color: var(--whiteColor);
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            border-radius: 0;
            margin-top: 0;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;

            .option {
                -webkit-transition: .6s;
                transition: .6s;
                color: var(--paragraphColor);
                padding-left: 20px;
                padding-right: 20px;

                &:hover {
                    background-color: var(--mainColor) !important;
                    color: #ffffff;
                }

                .selected {
                    background-color: transparent;
                    font-weight: 600;
                }
            }
        }

        &::after {
            height: 8px;
            width: 8px;
            border-color: var(--paragraphColor);
            right: 23px;
        }
    }
}

.banner-form-mt {
    position: relative;
    margin-top: -120px;
    z-index: 55;
}

.banner-form .form-group .form-control::-webkit-input-placeholder {
    color: var(--paragraphColor);
}

.banner-form .form-group .form-control:-ms-input-placeholder {
    color: var(--paragraphColor);
}

.banner-form .form-group .form-control::-ms-input-placeholder {
    color: var(--paragraphColor);
}

.banner-form .form-group .form-control::placeholder {
    color: var(--paragraphColor);
}

/*=================================
Banner Form Area End
====================================*/

/*=================================
Inner Banner
====================================*/
.inner-banner {
    position: relative;
    background-position: center center;
    background-size: cover;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.7;
    }

    .inner-title {
        padding-top: 250px;
        padding-bottom: 200px;
        position: relative;

        h3 {
            font-size: 30px;
            color: var(--whiteColor);
            font-weight: 700;
            text-transform: capitalize;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-size: 18px;
                color: var(--whiteColor);
                display: inline-block;
                margin-right: 5px;
                text-transform: capitalize;

                &:last-child {
                    margin-right: 0;
                }

                i {
                    font-size: 26px;
                    color: var(--whiteColor);
                    position: relative;
                    top: 4px;
                }

                a {
                    color: var(--whiteColor);

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }

        p {
            color: var(--whiteColor);
            margin-bottom: 0;
        }
    }

    .inner-banner-title {
        padding-top: 250px;
        padding-bottom: 160px;
        position: relative;

        h3 {
            font-size: 50px;
            color: var(--whiteColor);
            font-weight: 700;
            text-transform: capitalize;
        }

        p {
            color: var(--whiteColor);
            margin-bottom: 0;
        }
    }
}

.inner-content {
    padding-top: 250px;
}

.inner-banner-text {
    padding-bottom: 37px;

    h3 {
        font-size: 50px;
        color: var(--whiteColor);
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 20px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            font-size: 16px;
            color: var(--whiteColor);
            margin-right: 25px;

            &:hover {
                color: var(--mainColor);
            }

            &:hover a {
                color: var(--mainColor);
            }

            &:hover i {
                color: var(--whiteColor);
            }

            i {
                color: var(--mainColor);
                margin-right: 5px;
            }

            a {
                color: var(--whiteColor);
                font-weight: 400;
            }
        }
    }
}

.banner-rating {
    float: right;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50px;

        li {
            display: inline-block;
            font-size: 14px;

            &.rating-star {
                position: relative;
                top: 10px;
                padding-left: 25px;
            }

            span {
                font-size: 14px;
                color: var(--whiteColor);
                display: block;
            }

            i {
                color: #ff8400;
            }

            a {
                width: 70px;
                height: 56px;
                line-height: 56px;
                font-size: 16px;
                font-weight: 700;
                color: var(--whiteColor);
                text-align: center;
                background-color: var(--mainColor);
                border-radius: 50px;
                position: relative;
                top: -1.5px;
            }
        }
    }
}

.banner-bottom {
    padding-top: 35px;
    border-top: 1px solid #ffffff9e;
    padding-bottom: 10px;
}

.banner-tag {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 15px;

            a {
                padding: 7px 18px;
                border-radius: 50px;
                background-color: rgba(255, 255, 255, 0.2);
                border: 1px dashed #ffffff47;
                color: var(--whiteColor);
            }
        }
    }
}

.banner-profile {
    float: right;

    img {
        display: inline-block;
        margin-right: 10px;
        width: 35px !important;
    }

    h3 {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: var(--whiteColor);
    }

    .banner-status {
        margin-left: 25px;
        display: inline-block;
        background-color: var(--mainColor);
        padding: 7px 25px 7px 25px;
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 13% 50%, 0% 0%);

        a {
            display: block;

            h3 {
                font-size: 15px;
                color: var(--whiteColor);
                font-weight: 500;
                margin-bottom: 0;
            }
        }
    }
}

.banner-list {
    background-color: var(--whiteColor);
    padding: 25px 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative;
    bottom: -40px;
    border-radius: 10px;
    margin-bottom: 40px;

    .list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            font-size: 16px;
            color: #8c8c8c;
            display: inline-block;
            margin-right: 5px;
            text-transform: capitalize;

            &:last-child {
                margin-right: 0;
            }

            i {
                font-size: 20px;
                color: #8c8c8c;
                position: relative;
                top: 4px;
            }

            a {
                color: #8c8c8c;

                &:hover {
                    color: var(--mainColor);
                }
            }

            &.active {
                color: var(--mainColor);
            }
        }
    }

    .social-link {
        float: right;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            margin-right: 5px;

            a {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                line-height: 37px;
                text-align: center;
                color: var(--whiteColor);
            }

            &:nth-child(1) a {
                background-color: #3b5998;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(2) a {
                background-color: #55acee;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(3) a {
                background-color: #3f729b;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(4) a {
                background-color: #ff0000;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(5) a {
                background-color: #c80000;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
        }
    }

    p {
        float: right;
        position: relative;
        top: 7px;

        a {
            color: var(--mainColor);

            &:hover {
                color: var(--titleColor);
            }
        }
    }
}

.inner-bg1 {
    background-image: url(assets/img/inner-banner/inner-banner1.jpg);
}

.inner-bg2 {
    background-image: url(assets/img/inner-banner/inner-banner2.jpg);
}

.inner-bg3 {
    background-image: url(assets/img/inner-banner/inner-banner3.jpg);
}

.inner-bg4 {
    background-image: url(assets/img/inner-banner/inner-banner4.jpg);
}

.inner-bg5 {
    background-image: url(assets/img/inner-banner/inner-banner5.jpg);
}

.inner-bg6 {
    background-image: url(assets/img/inner-banner/inner-banner6.jpg);
}

.inner-bg7 {
    background-image: url(assets/img/inner-banner/inner-banner7.jpg);
}

.inner-bg8 {
    background-image: url(assets/img/inner-banner/inner-banner8.jpg);
}

.inner-bg9 {
    background-image: url(assets/img/inner-banner/inner-banner9.jpg);
}

.inner-bg10 {
    background-image: url(assets/img/inner-banner/inner-banner10.jpg);
}

.inner-bg11 {
    background-image: url(assets/img/inner-banner/inner-banner11.jpg);
}

/*=================================
Inner Banner End
====================================*/

/*=================================
About Area
====================================*/
.about-list {
    margin-top: 30px;
    margin-bottom: 30px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            font-size: 16px;
            color: var(--paragraphColor);
            margin-bottom: 7px;

            i {
                color: var(--mainColor);
                margin-right: 5px;
            }
        }
    }
}

.about-content {
    margin-bottom: 30px;
}

.about-img {
    margin-bottom: 30px;
}

.choose-width {
    margin-top: 45px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
}

.border-radius-5 {
    border-radius: 5px 5px 0 0;
}

.choose-area-two {
    position: relative;
    top: -100px;
}

.choose-card {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;

    &:hover i::after {
        right: auto;
        left: -15px;
    }

    i {
        font-size: 55px;
        color: #6d6d6d;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 50px;
            background-color: var(--optionalColor);
            top: -5px;
            right: -15px;
            z-index: -1;
            transition: 0.9s;
        }
    }

    h3 {
        margin-bottom: 10px;
    }

    p {
        max-width: 290px;
        margin: 0 auto;
        font-size: 15px;
    }
}

/*=================================
About Area End
====================================*/

/*=================================
Pricing Area
====================================*/
.price-card {
    margin-bottom: 30px;
    text-align: center;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
    padding-bottom: 20px;

    .content {
        padding: 20px 20px 10px 20px;
        background-color: var(--mainColor);
        border-radius: 0 0 30px 30px;
        margin-bottom: 20px;

        i {
            font-size: 50px;
            background-color: var(--whiteColor);
            color: #11114b;
            width: 85px;
            height: 85px;
            line-height: 85px;
            border-radius: 50px;
            display: inline-block;
            text-align: center;
            margin-bottom: 15px;
        }

        h3 {
            color: var(--whiteColor);
            padding: 10px 0;
            background-color: #eceae957;
            font-weight: 500;
            margin-bottom: 10px;
        }

        h2 {
            font-size: 45px;
            color: var(--whiteColor);

            sup {
                font-size: 30px;
                font-weight: 400;
            }
        }

        span {
            font-size: 14px;
            color: var(--whiteColor);
            font-weight: 400;
            position: relative;
            top: -15px;
        }
    }

    ul {
        list-style: none;
        margin: 0 0 30px 0;
        padding: 0;

        li {
            display: block;
            color: var(--paragraphColor);
            padding: 10px 0;
            border-bottom: 1px solid #ededed;
        }
    }
}

/*=================================
Pricing Area End
====================================*/

/*=================================
Team Area
====================================*/
.team-area {
    background-color: #fdf8f5;
}

.team-card {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
    margin-bottom: 30px;
    transition: 0.7s;

    &:hover {
        transform: translateY(-10px);
    }

    a {
        display: block;

        img {
            max-width: 100%;
            border-radius: 5px 5px 0 0;
        }
    }

    .content {
        padding: 30px 25px;
        position: relative;

        &::before {
            content: '';
            top: 30px;
            left: 0;
            position: absolute;
            height: 45px;
            width: 7px;
            border-radius: 2px;
            background-color: var(--mainColor);
        }

        h3 {
            margin-bottom: 0;
            font-weight: 500;
            color: var(--titleColor);

            a {
                color: var(--titleColor);
                display: block;
            }
        }

        span {
            font-size: 15px;
            font-weight: 500;
            color: var(--titleColor);
        }

        p {
            margin-top: 10px;
        }

        .social-link {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 5px;

                a {
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    line-height: 37px;
                    text-align: center;
                    color: var(--whiteColor);
                }

                &:nth-child(1) a {
                    background-color: #3b5998;

                    &:hover {
                        background-color: var(--mainColor);
                        animation: tada 1s linear;
                    }
                }

                &:nth-child(2) a {
                    background-color: #55acee;

                    &:hover {
                        background-color: var(--mainColor);
                        animation: tada 1s linear;
                    }
                }

                &:nth-child(3) a {
                    background-color: #3f729b;

                    &:hover {
                        background-color: var(--mainColor);
                        animation: tada 1s linear;
                    }
                }

                &:nth-child(4) a {
                    background-color: #ff0000;

                    &:hover {
                        background-color: var(--mainColor);
                        animation: tada 1s linear;
                    }
                }
            }
        }
    }
}

/*=================================
Team Area End
====================================*/

/*=================================
Category Area
====================================*/
.category-bg {
    margin-top: 45px;
    background-color: var(--whiteColor);
    box-shadow: 0px -65px 50px 7px rgba(246, 246, 246, 0.7);
    padding: 70px 70px 0 70px;
}

.category-card {
    margin-bottom: 30px;
    text-align: center;

    &:hover {
        a i {
            color: var(--mainColor);
        }
    }

    a {
        display: block;

        i {
            font-size: 50px;
            color: #6a6a6a;
            margin-bottom: 15px;
            transition: var(--transition);
        }
    }

    a {

        h3 {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }

    p {
        margin: 0 auto;
        max-width: 320px;
    }
}

.category-box {
    margin-bottom: 30px;
    text-align: center;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 25px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 100%;
        opacity: 0;
        background-color: var(--mainColor);
        transition: 0.7s;
        z-index: -1;
    }

    &:hover {
        &::before {
            opacity: 1;
            width: 100%;
        }

        a {
            i {
                color: var(--whiteColor);
            }
        }

        a {
            h3 {
                color: var(--whiteColor);
            }
        }
    }

    a {
        display: block;

        i {
            font-size: 50px;
            color: var(--mainColor);
            margin-bottom: 15px;
            transition: var(--transition);
        }
    }

    a {
        display: block;

        h3 {
            margin-bottom: 0;
            color: var(--mainColor);
        }
    }
}

.category-box-area {
    background-color: var(--whiteColor);
    padding-top: 50px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ededed;
}

.category-box-card {
    margin-bottom: 30px;
    text-align: center;

    &:hover {
        a {
            i {
                &::after {
                    left: auto;
                    right: 10px;
                }
            }
        }

        h3 {
            a {
                color: var(--mainColor);
            }
        }
    }

    a {
        display: block;
        position: relative;
        z-index: 1;

        i {
            font-size: 50px;
            color: var(--titleColor);
            margin-bottom: 15px;
            transition: var(--transition);
            position: relative;
            z-index: 1;

            &::after {
                content: '';
                position: absolute;
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background-color: var(--optionalColor);
                top: 0;
                left: 10px;
                z-index: -1;
                transition: 0.9s;
            }
        }
    }

    h3 {
        margin-bottom: 0;

        a {
            display: block;
            color: var(--titleColor);
            transition: var(--transition);
        }
    }
}

/*=================================
Category Area End
====================================*/

/*=================================
Place List Area
====================================*/
.place-list-area {
    position: relative;

    .owl-dots {
        margin-top: 0px !important;
        margin-bottom: 30px;

        .owl-dot {
            span {
                background-color: var(--mainColor) !important;
                width: 7px !important;
                height: 7px !important;
                transition: 0.7s;
            }

            &.active span {
                background-color: var(--mainColor) !important;
                width: 12px !important;
                height: 12px !important;
            }

            &:hover span {
                background-color: var(--mainColor) !important;
                width: 12px !important;
                height: 12px !important;
            }
        }
    }
}

.place-list-two {
    position: relative;
    overflow: hidden;

    .owl-nav {
        margin-top: 0;

        .owl-prev {
            position: absolute;
            top: -50px;
            right: 15%;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;

            &:hover {
                color: var(--titleColor) !important;
                background-color: transparent !important;
            }
        }

        .owl-next {
            position: absolute;
            top: -50px;
            right: 12%;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;

            &:hover {
                color: var(--titleColor) !important;
                background-color: transparent !important;
            }
        }
    }
}

.place-list-three {
    position: relative;
    overflow: hidden;

    .section-title {
        max-width: 1240px;
        margin-left: auto;
    }

    .owl-nav {
        margin-top: 0;

        .owl-prev {
            position: absolute;
            top: -50px;
            right: 17%;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;
            ;

            &:hover {
                color: var(--titleColor) !important;
                background-color: transparent !important;
            }
        }

        .owl-next {
            position: absolute;
            top: -50px;
            right: 15%;
            color: var(--mainColor) !important;
            font-size: 24px !important;
            font-weight: 700;

            &:hover {
                color: var(--titleColor) !important;
                background-color: transparent !important;
            }
        }
    }

}

.place-slider-three {
    left: calc((100% - 1170px) /2);
    position: relative;
}

.place-list-item {
    margin-bottom: 30px;

    .images {
        position: relative;

        .images-list {
            display: block;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                width: 100%;
                height: 100%;
                background-color: #000000;
                opacity: 0.5;
            }

            img {
                width: 100%;
                border-radius: 5px 0 5px 0;
            }
        }

        .place-profile {
            position: absolute;
            top: 30px;
            left: 15px;

            img {
                display: inline-block;
                margin-right: 10px;
                width: 35px !important;
            }

            h3 {
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                color: var(--whiteColor);
            }
        }

        .place-status {
            position: absolute;
            top: 30px;
            right: -10px;
            padding: 5px 15px 5px 25px;
            clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 13% 50%, 0% 0%);

            a {
                display: block;

                h3 {
                    font-size: 13px;
                    color: var(--whiteColor);
                    font-weight: 500;
                    margin-bottom: 0;
                }
            }
        }

        .place-tag {
            position: absolute;
            bottom: 20px;
            left: 15px;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: inline-block;

                li {
                    display: inline-block;

                    a {
                        text-align: center;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 14px;
                        color: var(--mainColor);
                        background-color: var(--whiteColor);
                        border-radius: 50px;

                        &:hover {
                            background-color: var(--mainColor);
                            color: var(--whiteColor);
                        }
                    }
                }
            }

            span {
                a {
                    display: inline-block;
                    color: var(--whiteColor);
                }

                display: inline-block;
                margin-left: 25px;
            }

            h3 {
                a {
                    display: inline-block;
                    color: var(--whiteColor);
                }

                display: inline-block;
                margin-left: 25px;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .content {
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
        padding: 30px 30px 20px 30px;

        &:hover a {
            h3 {
                color: var(--mainColor);
            }
        }

        a {
            display: block;

            h3 {
                margin-bottom: 15px;
                transition: var(--transition);
            }
        }

        p {
            i {
                position: absolute;
                font-size: 18px;
                color: var(--mainColor);
                left: -30px;
            }

            margin-left: 30px;
            margin-bottom: 0;
            padding-bottom: 15px;
            border-bottom: 1px dashed #e8e8e8;
            position: relative;
        }

        .place-rating {
            padding: 15px 0;
            list-style: none;
            margin: 0;

            li {
                display: inline-block;
                font-size: 14px;
                margin-right: 15px;

                i {
                    color: var(--mainColor);
                }

                .icon-color {
                    color: var(--optionalColor) !important;
                }

                a {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    font-size: 14px;
                    color: var(--mainColor);
                    text-align: center;
                    border: 1px solid var(--mainColor);
                    border-radius: 50px;
                }
            }
        }
    }
}

.listing-section-right {
    margin-bottom: 35px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding-bottom: 30px;

    .title {
        font-size: 20px;
        color: var(--titleColor);
        margin-bottom: 30px;
        border-bottom: 1px solid var(--mainColor);
        padding-bottom: 15px;
        padding-top: 15px;
        font-weight: 400;
        padding-left: 35px;

        i {
            color: var(--mainColor);
            margin-right: 10px;
        }
    }

    .facilities-list {
        padding: 0 30px 30px;
        border-bottom: 1px solid #ececec;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin-right: 15px;

                .agree-label {
                    label {
                        font-size: 15px;
                        font-weight: 400;
                        color: #636363;
                        margin-left: 5px;
                    }
                }
            }
        }

        .facilities-title {
            font-size: 17px;
            text-align: center;
            border: 1px dashed var(--mainColor);
            padding: 4px 0;
            font-weight: 400;
            color: #7f7f7f;
            background-color: #fff6f2;
            margin-bottom: 20px;
        }
    }

    .default-btn {
        margin-top: 20px;
    }
}

.listing-right-form {
    margin-left: 30px;
    margin-right: 30px;

    .form-group {
        margin-bottom: 25px;
        position: relative;

        label {
            color: var(--paragraphColor);
            position: relative;
            top: 12px;
        }

        i {
            position: absolute;
            top: 18px;
            left: 15px;
            line-height: 1;
            font-size: 18px;
            color: var(--mainColor);
            z-index: 1;
        }

        .form-control {
            height: 50px;
            color: #7f7f7f;
            border: 1px solid #e8e8e8;
            background-color: #ffffff;
            border-radius: 0;
            font-size: 14px;
            padding: 10px 20px 10px 40px;
            width: 100%;

            &:focus {
                outline: none;
                border-color: #ffc0a7;
                box-shadow: none;
            }
        }
    }

    .nice-select {
        height: 50px;
        width: 100%;
        line-height: 32px;
        font-size: 15px;
        margin-bottom: 25px;
        padding-left: 20px;
        border-radius: 50px;
        background-color: var(--mainColor);
        color: var(--whiteColor);

        .list {
            background-color: var(--whiteColor);
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            border-radius: 0;
            margin-top: 0;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;

            .option {
                -webkit-transition: .6s;
                transition: .6s;
                color: var(--paragraphColor);
                padding-left: 20px;
                padding-right: 20px;

                &:hover {
                    background-color: var(--mainColor) !important;
                    color: #ffffff;
                }

                .selected {
                    background-color: transparent;
                    font-weight: 600;
                }
            }
        }

        &::after {
            height: 8px;
            width: 8px;
            border-color: var(--mainColor);
            right: 23px;
        }
    }
}

.listing-widget-into {
    .listing-widget-menu {
        list-style: none;
        margin-top: 7px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        padding: 0;
        float: right;

        li {
            display: inline-block;

            a {
                text-align: center;
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 16px;
                color: #a3a3a3;
                border: 1px solid #a3a3a3;
                border-radius: 50px;

                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                }

                &.active {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                }
            }
        }
    }

    .default-btn {
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

/*=================================
Place List Area End
====================================*/

/*=================================
Process Area
====================================*/
.process-bg {
    background-color: #f5f5f5;
}

.process-bg2 {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(assets/img/process-bg.jpg);
    margin-bottom: 50px;
    margin-top: -100px;
}

.process-into {
    bottom: -50px;
    position: relative;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    padding-top: 60px;
    padding-bottom: 30px;
}

.process-into-2 {
    background: none !important;
    box-shadow: none !important;
    bottom: 0 !important;
    margin-bottom: 40px;
    margin-top: 40px;
}

.process-line {
    position: absolute;
    top: 50%;
    left: 70px;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.process-line-2 {
    position: absolute;
    top: 28%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.process-card {
    text-align: center;
    position: relative;
    margin-bottom: 30px;

    &:hover i::after {
        right: 10px;
        left: auto;
    }

    &:hover .process-number {
        color: var(--mainColor);
    }

    i {
        font-size: 55px;
        color: var(--titleColor);
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        transition: 0.9s;

        &::after {
            content: '';
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background-color: var(--optionalColor);
            top: 25px;
            left: 10px;
            z-index: -1;
            transition: 0.9s;
        }

        &:hover::after {
            right: 10px;
            left: auto;
        }
    }

    .icon-bg {
        width: 95px;
        height: 95px;
        background-color: var(--whiteColor);
        border-radius: 50px;
        line-height: 95px;
        display: inline-block;
    }

    h3 {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 0;
    }

    .process-number {
        position: absolute;
        top: 10px;
        right: 0;
        font-size: 40px;
        color: var(--paragraphColor);
        transition: var(--transition);

        &.active {
            color: var(--mainColor);
        }
    }
}

.process-item {
    margin-bottom: 30px;
    position: relative;

    .process-item-number {
        text-align: center;
        position: absolute;
        font-size: 40px;
        color: var(--paragraphColor);
        transition: var(--transition);

        &.active {
            color: var(--mainColor);
        }
    }

    .number1 {
        position: absolute;
        left: 120px;
        top: -75px;
    }

    .number2 {
        position: absolute;
        left: 130px;
        top: -60px;
    }

    .number3 {
        position: absolute;
        left: 165px;
        top: -88px;
    }

    i {
        font-size: 55px;
        color: var(--titleColor);
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        transition: 0.9s;
        display: inline-block;
    }

    .content {
        display: inline-block;
        position: relative;
        margin-top: -80px;
        margin-left: 70px;

        h3 {
            margin-bottom: 15px;
            font-weight: 500;
            display: inline-block;
        }

        p {
            margin-bottom: 0;
            display: inline-block;
        }
    }
}

.process-into {
    .col-lg-4 {
        &:nth-child(2) {
            .process-item {
                margin-top: 50px;
            }
        }
    }
}

.process-into {
    .col-lg-4 {
        &:nth-child(3) {
            .process-item {
                margin-top: 20px;
            }
        }
    }
}

/*=================================
Process Area End
====================================*/

/*=================================
City Area
====================================*/
.city-item {
    position: relative;
    margin-bottom: 30px;

    .city-img {
        display: block;
        border-radius: 5px;

        img {
            width: 100%;
            height: 410px;
            border-radius: 5px;
        }
    }

    .city-content {
        position: absolute;
        top: 40px;
        left: 30px;

        i {
            font-size: 24px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50px;
            display: inline-block;
            background-color: var(--whiteColor);
            color: var(--mainColor);
            text-align: center;
            position: relative;
            top: -10px;
            margin-right: 10px;
        }

        .city-text {
            display: inline-block;

            a {
                h3 {
                    color: var(--whiteColor);
                    margin-bottom: 5px;
                    font-size: 18px;
                }
            }

            p {
                color: var(--whiteColor);
                margin-bottom: 0;
                font-size: 15px;
            }
        }
    }

    .city-location {
        position: absolute;
        bottom: 30px;
        right: 60px;
        font-size: 30px;
        color: var(--whiteColor);
    }
}

.city-btn {
    margin-top: 20px;
    float: right;
}

.city-card {
    margin-bottom: 30px;

    a {
        display: block;

        img {
            height: 360px;
            width: 100%;
            transition: 0.7s;
        }
    }

    &:hover a img {
        filter: brightness(0.5);
    }

    &:hover .content a h3 {
        color: var(--mainColor);
    }

    .content {
        padding-top: 25px;

        a {
            display: block;

            h3 {
                font-size: 22px;
                font-weight: 400;
                margin-bottom: 5px;
                transition: 0.7s;
            }
        }

        span {
            font-size: 25px;
            color: var(--mainColor);
        }
    }
}

/*=================================
City Area End
====================================*/

/*=================================
Video Area
====================================*/
.video-area-bg {
    background-image: url(assets/img/wall.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5;
    }
}

.video-ptb {
    padding-top: 124px !important;
    padding-bottom: 124px !important;
}

.video-content {
    padding-top: 100px;
    padding-bottom: 200px;
    text-align: center;
    position: relative;

    h2 {
        font-size: 38px;
        color: var(--whiteColor);
    }

    .play-btn {
        margin-top: 50px;
        width: 85px;
        height: 85px;
        line-height: 90px;
        border-radius: 50px;
        font-size: 35px;
        background-color: var(--mainColor);
        color: var(--whiteColor);
        position: relative;

        &:hover {
            background-color: var(--whiteColor);
            color: var(--mainColor);
        }

        &:hover::before {
            border-color: var(--whiteColor);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid var(--mainColor);
            animation: ripple 1s linear 2s infinite;
        }
    }
}

.video-area-two {
    background-image: url(assets/img/video-area-bg2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5;
    }
}

.video-option {
    padding-top: 100px;
    padding-bottom: 250px;
    position: relative;

    h2 {
        font-size: 38px;
        color: var(--whiteColor);
    }

    .video-btn {
        margin-left: 200px;

        .play-video {
            margin-top: 50px;
            width: 85px;
            text-align: center;
            height: 85px;
            line-height: 85px;
            border-radius: 50px;
            font-size: 35px;
            background-color: var(--whiteColor);
            color: var(--mainColor);
            position: relative;

            &:hover {
                background-color: var(--mainColor);
                color: var(--whiteColor);
            }

            &:hover::after {
                border-color: var(--mainColor);
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid var(--whiteColor);
                animation: ripple 1s linear 2s infinite;
            }
        }

        &:hover span {
            color: var(--mainColor);
        }

        span {
            font-size: 35px;
            color: var(--whiteColor);
            margin-left: 30px;

            i {
                font-weight: 500;
                font-size: 24px;
            }
        }
    }
}

/*=================================
Video Area End
====================================*/

/*=================================
Counter Area
====================================*/
.counter-area {
    position: relative;
    margin-top: -150px;
}

.counter-area-three {
    background-color: #150001a8;
    padding: 40px 0;
    position: relative;
    margin-top: -160px;
}

.counter-bg {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.3);
    padding: 40px 0;
}

.counter-bg2 {
    position: relative;
    padding: 40px 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(#f14300, #ababab);
        opacity: 0.2;
    }
}

.single-counter {
    text-align: center;
    position: relative;

    h3 {
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 400;
    }

    span {
        color: var(--paragraphColor);
        margin-bottom: 0;
        font-weight: 500;
    }
}

/*=================================
Counter Area End
====================================*/

/*=================================
Place Area
====================================*/
.place-btn {
    float: right;
    margin-bottom: 45px;
}

.place-card {
    position: relative;
    margin-bottom: 30px;
    transition: 0.7s;

    &:hover {
        transform: translateY(-10px);

        .status-tag {
            background-color: var(--whiteColor) !important;

            h3 {
                color: var(--mainColor);
            }
        }
    }

    .place-images {
        display: block;
        position: relative;
        border-radius: 5px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.5;
            border-radius: 5px;
        }

        img {
            max-width: 100%;
            border-radius: 5px;
        }
    }

    .rating {
        position: absolute;
        top: 40px;
        left: 35px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                font-size: 14px;
                margin-right: 15px;

                span {
                    font-size: 14px;
                    color: var(--whiteColor);
                    display: block;
                }

                i {
                    color: #ff8400;
                }

                .i-color {
                    color: #a85802 !important;
                }

                a {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    font-size: 14px;
                    color: var(--whiteColor);
                    text-align: center;
                    border: 1px solid var(--whiteColor);
                    border-radius: 50px;
                    position: relative;
                    top: -10px;
                }
            }
        }
    }

    .status-tag {
        position: absolute;
        top: 40px;
        right: -10px;
        padding: 5px 15px 5px 25px;
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 13% 50%, 0% 0%);
        transition: 0.7s;

        h3 {
            font-size: 13px;
            color: var(--whiteColor);
            font-weight: 500;
            margin-bottom: 0;
            transition: 0.7s;
        }
    }

    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 35px 20px 30px 20px;

        .content-profile {
            margin-bottom: 10px;

            img {
                display: inline-block;
                margin-right: 10px;
            }

            h3 {
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                color: var(--whiteColor);
            }
        }

        span {
            color: var(--whiteColor);
            font-size: 14px;
            margin-bottom: 10px;

            i {
                color: var(--mainColor);
                font-size: 18px;
                margin-right: 5px;
            }
        }

        h3 {
            color: var(--whiteColor);
            margin-bottom: 10px;
            margin-top: 10px;
            font-weight: 400;
            font-size: 18px;
        }

        p {
            color: var(--whiteColor);
            margin-bottom: 0;
            padding-bottom: 15px;
            border-bottom: 1px dashed #615649;
            font-size: 15px;
        }

        .content-tag {
            padding-top: 15px;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: inline-block;

                li {
                    display: inline-block;

                    a {
                        text-align: center;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 14px;
                        color: var(--whiteColor);
                        background-color: rgba(255, 255, 255, 0.31%);
                        border-radius: 50px;
                    }

                    &:hover a {
                        background-color: var(--whiteColor);
                        color: var(--mainColor);
                    }
                }
            }

            h3 {
                margin-left: 25px;
                display: inline-block;
                font-size: 16px;
                font-weight: 500;

                a {
                    display: inline-block;
                    color: var(--mainColor);
                }

                &:hover a {
                    color: var(--whiteColor);
                }
            }

            .price {
                float: right;
            }
        }
    }

    .content-bg {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: -1;
            height: 0;
            background-color: var(--whiteColor);
            transition: 0.7s;
        }
    }

    &:hover {
        .content-bg {
            z-index: 1;

            &::before {
                height: 100%;
            }

            .content-profile {
                h3 {
                    color: var(--titleColor);
                }
            }

            span {
                color: var(--titleColor);
            }

            h3 {
                color: var(--titleColor);
            }

            p {
                color: var(--titleColor);
            }

            .content-tag {
                padding-top: 15px;

                ul {
                    li {
                        display: inline-block;

                        a {
                            background-color: var(--whiteColor);
                            color: var(--mainColor);
                            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
                        }

                        &:hover a {
                            background-color: var(--titleColor);
                            color: var(--whiteColor);
                        }
                    }
                }

                h3 {
                    a {
                        display: inline-block;
                        color: var(--mainColor);
                    }

                    &:hover a {
                        color: var(--titleColor);
                    }
                }

                .price {
                    float: right;
                }
            }
        }
    }

    &.active {
        .content-bg {
            z-index: 1;

            &::before {
                height: 100%;
            }

            .content-profile {
                h3 {
                    color: var(--titleColor);
                }
            }

            span {
                color: var(--titleColor);
            }

            h3 {
                color: var(--titleColor);
            }

            p {
                color: var(--titleColor);
            }

            .content-tag {
                padding-top: 15px;

                ul {
                    li {
                        display: inline-block;

                        a {
                            background-color: var(--whiteColor);
                            color: var(--mainColor);
                            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
                        }

                        &:hover a {
                            background-color: var(--mainColor);
                            color: var(--whiteColor);
                        }
                    }
                }

                h3 {
                    a {
                        display: inline-block;
                        color: var(--mainColor);
                    }

                    &:hover a {
                        color: var(--titleColor);
                    }
                }

                .price {
                    float: right;
                }
            }
        }
    }
}

/*=================================
Place Area End
====================================*/

/*=================================
Listing Details Area
====================================*/
.listing-details-wrap {
    margin-bottom: 30px;
}

.listing-category {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 30px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 15px;

            a {
                padding: 7px 18px;
                border-radius: 50px;
                background-color: #fff6f2;
                border: 1px dashed var(--mainColor);
                color: var(--mainColor);
            }

            &:hover a {
                background-color: var(--mainColor);
                color: var(--whiteColor);
            }
        }
    }

    h3 {
        float: right;
        color: #555555;
        font-size: 16px;
        font-weight: 400;
        position: relative;
        margin-top: -30px;
        text-decoration: underline;

        i {
            color: var(--mainColor);
            margin-right: 7px;
        }
    }
}

.listing-widget {
    margin-bottom: 35px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    .title {
        font-size: 20px;
        color: var(--titleColor);
        margin-bottom: 0px;
        border-bottom: 1px solid #c8ccd0;
        padding-bottom: 15px;
        padding-top: 15px;
        font-weight: 400;
        padding-left: 35px;
    }

    &.listing-widget-desc {
        padding-bottom: 35px;

        p {
            margin-top: 20px;
            margin-bottom: 20px;
            font-weight: 400;
            padding: 0 35px;
            font-size: 15px;
        }

        .default-btn {
            margin-left: 35px;
            margin-top: 10px;
            margin-bottom: 40px;
        }
    }
}

.listing-features {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    margin: 0 35px;
    border: 1px dashed var(--mainColor);
    background-color: #fff6f2;

    .features-title {
        text-align: center;
        font-size: 17px;
        color: var(--mainColor);
        width: 185px;
        height: 45px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        line-height: 45px;
        border-left: 1px dashed var(--mainColor);
        border-right: 1px dashed var(--mainColor);
        border-bottom: 1px dashed var(--mainColor);
    }

    .features-card {
        text-align: center;
        margin-bottom: 30px;

        i {
            width: 50px;
            height: 50px;
            background-color: var(--whiteColor);
            border-radius: 50px;
            font-size: 24px;
            color: var(--mainColor);
            line-height: 50px;
            text-align: center;
            display: inline-block;
            margin-bottom: 10px;
        }

        h3 {
            font-size: 15px;
            color: var(--mainColor);
            margin-bottom: 0;
        }
    }
}

.gallery-slider-area {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    position: relative;

    .owl-nav {
        margin-top: 0;

        .owl-prev {
            position: absolute;
            top: 35%;
            transform: translateY(-16px);
            left: 5px;
            color: var(--whiteColor) !important;
            font-size: 24px !important;
            width: 70px !important;
            height: 55px !important;
            line-height: 55px !important;
            background-color: var(--mainColor) !important;
            border-radius: 50px;

            &:hover {
                color: var(--whiteColor) !important;
                background-color: var(--titleColor) !important;
            }
        }

        .owl-next {
            position: absolute;
            top: 35%;
            transform: translateY(-16px);
            right: 5px;
            color: var(--whiteColor) !important;
            font-size: 24px !important;
            width: 70px !important;
            height: 55px !important;
            line-height: 55px !important;
            background-color: var(--mainColor) !important;
            border-radius: 50px;

            &:hover {
                color: var(--whiteColor) !important;
                background-color: var(--titleColor) !important;
            }
        }
    }
}

.listing-map iframe {
    width: 100%;
    height: 380px;
    padding-left: 30px;
    border: none;
    padding-right: 30px;
    padding-bottom: 30px;
}

.listing-widget-review {
    padding: 0 30px 60px 30px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: -30px;
        margin: 0;
        left: 0;
        right: 0;
        background-color: #fef0eb;
    }

    ul {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #162c44;
        border-radius: 50px;
        width: 200px;

        li {
            display: inline-block;
            font-size: 14px;

            &.rating-star {
                position: relative;
                top: 10px;
                padding-left: 35px;
            }

            span {
                font-size: 14px;
                color: var(--whiteColor);
                display: block;
            }

            i {
                color: #ff8400;
            }

            a {
                width: 70px;
                height: 56px;
                line-height: 56px;
                font-size: 16px;
                font-weight: 700;
                color: var(--whiteColor);
                text-align: center;
                background-color: var(--mainColor);
                border-radius: 50px;
                position: relative;
                top: -1.5px;
            }
        }
    }
}

.progressbar-area {
    padding: 0 30px 10px 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid #ebebeb;

    .single-progressbar {
        margin-bottom: 30px;

        h3 {
            font-size: 18px;
            color: var(--titleColor);
            text-transform: uppercase;
            font-weight: 400;
            margin-bottom: 15px;
        }

        .progressbar-count {
            position: relative;
            width: 100%;
            height: 5px;
            border-radius: 0;
            background-color: var(--mainColor);

            .progressbar-number {
                position: absolute;
                font-size: 16px;
                font-weight: 400;
                color: var(--titleColor);
            }

            .progressbar-circle {
                width: 13px;
                height: 13px;
                background-color: var(--mainColor);
                border-radius: 50px;
                position: absolute;
            }

            .pn-1 {
                top: -40px;
                right: 35%;
            }

            .pc-1 {
                top: -5px;
                right: 36%;
            }

            .pn-2 {
                top: -40px;
                right: 25%;
            }

            .pc-2 {
                top: -5px;
                right: 26%;
            }

            .pn-3 {
                top: -40px;
                right: 35%;
            }

            .pc-3 {
                top: -5px;
                right: 35%;
            }

            .pn-4 {
                top: -40px;
                right: 38%;
            }

            .pc-4 {
                top: -5px;
                right: 38%;
            }
        }
    }
}

.listing-comment {
    padding-bottom: 40px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            padding: 30px;
            padding-left: 150px;
            border-bottom: 1px solid #eeeeee;

            &:last-child {
                border: none;
            }

            img {
                border-radius: 50%;
                position: absolute;
                top: 35px;
                left: 30px;
            }

            .content {
                background-color: #fff6f2;
                padding: 35px;
                border: 1px dashed var(--mainColor);

                h3 {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 500;
                }

                span {
                    margin-bottom: 10px;
                    display: block;
                }

                p {
                    margin-bottom: 0;
                    font-size: 15px;
                }

                .comment-rating {
                    position: absolute;
                    top: 60px;
                    right: 60px;

                    i {
                        color: #ff8400;
                        display: inline-block;
                    }

                    span {
                        margin-left: 10px;
                        display: inline-block;
                        width: 42px;
                        height: 35px;
                        line-height: 35px;
                        font-size: 12px;
                        font-weight: 700;
                        color: var(--whiteColor);
                        text-align: center;
                        background-color: var(--mainColor);
                        border-radius: 50px;
                        position: relative;
                        top: -3px;
                    }

                    &:hover {
                        color: var(--titleColor);
                    }
                }

                a {
                    color: var(--mainColor);
                    font-weight: 600;
                    margin-top: 15px;

                    &:hover {
                        color: var(--titleColor);
                    }
                }

                .view-list {
                    position: absolute;
                    right: 60px;
                    bottom: 60px;
                    font-size: 12px;
                    color: var(--mainColor);
                    padding: 4px 7px;
                    border-radius: 50px;
                    border: 1px dashed var(--mainColor);
                    width: 148px;
                    text-align: center;
                }
            }
        }
    }
}

.progressbar-area-title {
    .title {
        margin-bottom: 0 !important;
    }
}

.progressbar-bg {
    padding: 30px !important;
    position: relative;
    background-color: #fef8f5;
}

.listing-widget-contact {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.listing-contact-title {
    border-bottom: 1px solid #c8ccd0;
    padding-bottom: 15px;
    padding-top: 15px;
    font-weight: 400;
    padding-left: 35px;

    .listing-widget-mail {
        i {
            font-size: 50px;
            color: #9fa8b2;
            display: inline-block;
        }

        .content {
            display: inline-block;
            margin-left: 15px;

            h3 {
                font-size: 14px;
                color: #6a6a6a;
                margin-bottom: 0;
            }

            p {
                font-size: 14px;
                color: var(--titleColor);
            }
        }
    }
}

.listing-contact-review {
    float: right;
    padding-right: 30px;
    padding-top: 10px;

    ul {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #162c44;
        border-radius: 50px;
        width: 200px;

        li {
            display: inline-block;
            font-size: 14px;

            &.rating-star {
                position: relative;
                top: 10px;
                padding-left: 35px;
            }

            span {
                font-size: 14px;
                color: var(--whiteColor);
                display: block;
            }

            i {
                color: #ff8400;
            }

            a {
                width: 70px;
                height: 56px;
                line-height: 56px;
                font-size: 16px;
                font-weight: 700;
                color: var(--whiteColor);
                text-align: center;
                background-color: var(--mainColor);
                border-radius: 50px;
                position: relative;
                top: -1.5px;
            }
        }
    }
}

.listing-widget-side {
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding-bottom: 30px;
    margin-bottom: 30px;

    .title {
        font-size: 20px;
        color: var(--titleColor);
        margin-bottom: 30px;
        border-bottom: 1px solid #c8ccd0;
        padding-bottom: 15px;
        padding-top: 15px;
        font-weight: 400;
        padding-left: 30px;
    }

    .social-link {
        margin: 0;
        padding: 10px 30px 0 30px;
        list-style: none;

        li {
            display: inline-block;
            margin-right: 5px;

            a {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                line-height: 37px;
                text-align: center;
                color: var(--whiteColor);
            }

            &:nth-child(1) a {
                background-color: #3b5998;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(2) a {
                background-color: #55acee;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(3) a {
                background-color: #3f729b;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(4) a {
                background-color: #ff0000;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(5) a {
                background-color: #c80000;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
        }
    }

    .listing-side-tag {
        list-style: none;
        margin: 0 10px;
        padding: 0;

        li {
            display: inline-block;
            padding: 0 5px;
            transition: 0.7s;
            color: #777777;
            font-size: 18px;

            a {
                color: #777777;
            }

            &:hover a {
                color: var(--mainColor);
            }

            &.active a {
                color: var(--mainColor);
            }
        }
    }
}

.listing-Booking-form {
    padding: 0 30px;

    .form-group {
        margin-bottom: 25px;
        position: relative;

        i {
            position: absolute;
            top: 14px;
            left: 15px;
            line-height: 1;
            font-size: 18px;
            color: var(--mainColor);
            z-index: 1;
        }

        .form-control {
            height: 50px;
            color: #7f7f7f;
            border: 1px solid #e8e8e8;
            background-color: #ffffff;
            border-radius: 0;
            font-size: 14px;
            padding: 10px 20px 10px 40px;
            width: 100%;

            &:focus {
                outline: none;
                border-color: #ffc0a7;
                box-shadow: none;
            }
        }

        textarea.form-control {
            height: auto;
        }
    }

    .add-counter {
        padding: 10px 0;

        h3 {
            display: inline-block;
            font-size: 16px;
            color: var(--titleColor);
            padding-left: 20px;
        }
    }

    .form-input-counter {
        max-width: 60px;
        min-width: 60px;
        height: 60px;
        text-align: center;
        display: inline-block;
        position: relative;
        right: -85px;
        top: -15px;

        .plus-btn {
            position: absolute;
            top: 0;
            right: 0;
            background-color: transparent;
            cursor: pointer;
            color: var(--titleColor);
            width: 20px;
            height: 22px;
            border: 1px solid #eeeeee;
            transition: 0.5s;

            &:hover {
                color: var(--mainColor) !important;
            }
        }

        .minus-btn {
            position: absolute;
            top: 22px;
            right: 0;
            background-color: transparent;
            cursor: pointer;
            color: var(--titleColor);
            width: 20px;
            height: 22px;
            border: 1px solid #eeeeee;
            transition: 0.5s;

            &:hover {
                color: var(--mainColor) !important;
            }
        }

        input {
            height: 45px;
            color: var(--titleColor);
            outline: 0;
            display: block;
            border: none;
            background-color: #f8f8f8;
            text-align: center;
            width: 100%;
            font-size: 17px;
            font-weight: 600;
        }
    }

    .price-rate {
        font-size: 16px;
        padding: 15px 0;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        margin-bottom: 25px;

        span {
            color: var(--mainColor);
            float: right;
        }
    }
}

.listing-widget-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: block;
        padding: 10px;
        margin: 7px 30px;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        font-size: 15px;
        color: #162c44;

        span {
            float: right;
            color: var(--paragraphColor);

            a {
                color: var(--paragraphColor);

                &:hover {
                    color: var(--mainColor);
                }
            }
        }
    }
}

.price-title {
    font-size: 16px;
    padding: 0px 30px;

    span {
        color: var(--mainColor);
        float: right;
    }
}

.listing-side-host {
    text-align: center;
    margin-bottom: 30px;

    img {
        margin-bottom: 20px;
    }

    h3 {
        font-size: 17px;
        margin-bottom: 0px;
    }

    span {
        font-size: 12px;
        color: var(--mainColor);
    }
}

.side-host-list {
    list-style: none;
    margin: 0 30px;
    padding: 10px 0;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;

    li {
        display: inline-block;
        margin-right: 10px;

        a {
            width: 40px;
            height: 40px;
            line-height: 40px;
            background-color: var(--mainColor);
            color: var(--whiteColor);
            font-size: 20px;
            text-align: center;
            border-radius: 50px;

            i {
                color: var(--whiteColor);
            }
        }

        span {
            color: var(--mainColor);
            top: 0px;
            right: -100px;
            position: relative;
        }
    }
}

.listing-blog-post {
    .title {
        margin-bottom: 0 !important;
    }
}

.listing-widget-post {
    list-style: none;
    margin: 0 30px;
    padding: 0;

    li {
        display: inline-block;
        margin-bottom: 5px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            bottom: -22px;
            background-color: #ededed;
        }

        &:last-child::before {
            display: none;
        }

        .img {
            display: inline-block;
            margin-right: 15px;
        }

        .content {
            display: inline-block;
            position: relative;
            top: 36px;

            span {
                display: block;
                font-size: 14px;
                color: var(--mainColor);
            }

            h4 {
                display: block;
                font-size: 18px;
                color: var(--titleColor);
                position: relative;
                font-weight: 500;
            }

            p {
                font-size: 14px;
                max-width: 200px;
                color: var(--paragraphColor);

                i {
                    font-size: 14px;
                    color: var(--mainColor);
                }
            }
        }
    }
}

/*=================================
Listing Details Area End
====================================*/

/*=================================
Application Area
====================================*/
.application-area {
    background-color: #fef6f2;
    position: relative;
    margin-bottom: 150px;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        background-image: url(assets/img/shape/shape1.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        animation: flash 3s linear infinite;
    }
}

.application-area-two {
    background-color: #fef6f2;
    overflow: hidden;
}

.application-img {
    position: relative;
    margin-bottom: -150px;
}

.application-content {
    margin-left: 40px;

    .section-title {
        p {
            max-width: 580px;
            color: #737373;
        }
    }
}

.application-btn {
    margin-top: 30px;

    .application-play-btn {
        display: inline-block;
        width: 185px;
        height: 60px;
        list-style: 60px;
        background-color: var(--titleColor);
        padding: 0 15px;
        border-radius: 7px;
        margin-right: 20px;
        overflow: hidden;
        transition: var(--transition);

        &:hover {
            background-color: var(--mainColor);
        }

        img {
            width: 40px;
            position: relative;
            top: -5px;
        }

        .btn-content {
            display: inline-block;
            padding-top: 3px;

            span {
                font-size: 10px;
                color: var(--whiteColor);
            }

            h3 {
                font-size: 18px;
                color: var(--whiteColor);
                margin-bottom: 0;
                font-weight: 400;
                line-height: 1;
            }
        }
    }
}

.application-img-two {
    position: relative;
    z-index: 1;

    img {
        margin-left: 75px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        width: 565px;
        height: 565px;
        border-radius: 50%;
        margin: 0 auto;
        background-color: var(--mainColor);
        z-index: -1;
    }
}

.application-area-two {
    .application-content {
        margin-left: 0 !important;
    }
}

/*=================================
Application Area End
====================================*/

/*=================================
Blog Area End
====================================*/
.blog-card {
    margin-bottom: 30px;

    a {
        display: block;

        img {
            max-width: 100%;
        }
    }

    .content {
        padding: 30px;
        border: 1px dashed var(--optionalColor);

        span {
            font-size: 14px;
            font-weight: 500;

            a {
                display: inline-block;
                color: var(--mainColor);

                &:hover {
                    color: var(--titleColor)
                }
            }
        }

        h3 {
            margin-top: 10px;

            a {
                color: var(--titleColor);
            }
        }

        .read-more {
            font-size: 15px;
            color: var(--mainColor);
            font-weight: 500;
            text-decoration: underline;

            &:hover {
                color: var(--titleColor);
            }
        }
    }
}

.blog-article {
    margin-bottom: 30px;

    .article-comment-area {
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);

        .article-img {
            margin-bottom: 30px;
        }

        .article-comment {
            margin-top: 40px;
            padding-left: 30px;
            padding-right: 30px;
            list-style-type: none;
            padding-top: 0;
            margin-bottom: 0;
            padding-bottom: 15px;
            border-top: 1px solid #ededed;
            border-bottom: 1px solid #ededed;

            li {
                display: inline-block;
                text-align: left;
                position: relative;
                margin-right: 150px;
                padding-top: 10px;

                &:last-child {
                    margin-right: 0;
                    float: right;
                }

                .image {
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 10px;
                }

                .content {
                    display: block;
                    margin-left: 55px;
                    padding-top: 0;

                    h3 {
                        font-size: 22px;
                        color: var(--titleColor);
                        display: block;
                        line-height: 1;
                        font-weight: 500;
                    }

                    span {
                        font-size: 15px;
                        color: var(--mainColor);
                        display: block;
                    }
                }

                .content-list {
                    display: block;
                    margin-left: 15px;
                    padding-top: 10px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 20px;
                        left: -15px;
                        width: 1px;
                        height: 45px;
                        background-color: #ededed;
                    }

                    h3 {
                        font-size: 20px;
                        color: var(--titleColor);
                        display: block;
                        font-weight: 500;
                    }

                    span {
                        font-size: 15px;
                        color: var(--mainColor);
                        display: block;
                    }
                }
            }
        }
    }

    .article-content {
        padding: 35px 30px;
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);

        h3 {
            font-size: 25px;
            margin-bottom: 20px;
            font-weight: 500;
        }

        .content-text {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                font-size: 100px;
                color: var(--titleColor);
                top: -35px;
                left: 0;
                font-weight: 500;
            }

            p {
                margin-left: 75px;
                margin-bottom: 10px;
            }
        }

        .blockquote {
            position: relative;
            margin-bottom: 20px;
            margin-top: 30px;
            background-color: #fff6f2;
            padding: 20px;
            border: 1px dashed var(--mainColor);

            &::before {
                content: '\f114';
                position: absolute;
                top: -20px;
                right: 20px;
                font-size: 30px;
                font-weight: 400;
                color: var(--mainColor);
                font-family: Flaticon;
            }

            p {
                font-size: 19px;
                color: var(--titleColor);
                font-weight: 400;
                margin-top: 20px;
                margin-right: 15px;
            }
        }
    }

    .blog-tag {
        margin-top: 30px;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        padding: 12px 0;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                padding: 5px 8px;
                transition: 0.7s;
                color: var(--titleColor);

                a {
                    color: var(--mainColor);
                }

                &:hover a {
                    color: var(--titleColor);
                }
            }
        }
    }

    .article-post {
        position: relative;
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
        margin-bottom: 40px;
        margin-top: 40px;
        padding: 30px;

        &::before {
            content: '';
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 1px;
            height: 100px;
            background-color: #eeeeee;
        }

        .article-post-share {
            padding: 15px 30px;

            span {
                font-size: 14px;
                color: var(--mainColor);
                position: relative;
                margin-left: 40px;
                display: inline-block;

                &::before {
                    content: '';
                    position: absolute;
                    left: -35px;
                    width: 30px;
                    height: 1px;
                    bottom: 0;
                    background-color: var(--mainColor);
                }

                a {
                    color: var(--paragraphColor);
                    display: inline-block;
                    margin-bottom: 0;
                    color: var(--mainColor);

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }

            a {
                display: block;

                h3 {
                    font-size: 18px;
                    margin-bottom: 0;
                    margin-top: 15px;
                    transition: 0.7s;
                    max-width: 600px;
                    color: #6a8096;
                    font-weight: 400;

                    &:hover {
                        color: var(--mainColor);
                        text-decoration: underline;
                    }

                    &.active {
                        color: var(--titleColor);
                        text-decoration: underline;

                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                }
            }

            &.text-align {
                float: right;
            }

            &.active {
                margin-left: 20px;
                background-color: #fff6f2;
                border: 1px dashed var(--mainColor);
            }
        }
    }

    .comments-wrap {
        padding-bottom: 20px;
        margin-top: 20px;
        padding-top: 40px;
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);

        .title {
            font-size: 20px;
            margin-bottom: 30px;
            font-weight: 400;
            margin-left: 30px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding: 30px;
                padding-left: 150px;
                margin-bottom: 30px;
                border-bottom: 1px solid #eeeeee;

                &:last-child {
                    margin-bottom: 0;
                    border: none;
                }

                img {
                    border-radius: 50%;
                    position: absolute;
                    top: 35px;
                    left: 30px;
                }

                h3 {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 500;
                }

                span {
                    margin-bottom: 10px;
                    display: block;
                }

                p {
                    margin-bottom: 0;
                }

                a {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    color: var(--mainColor);
                    font-weight: 500;

                    &:hover {
                        color: var(--titleColor);
                    }
                }

                &.ml-30 {
                    margin-left: 30px;
                }
            }
        }
    }

    .comments-form {
        margin-top: 40px;
        background-attachment: var(--whiteColor);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);

        .contact-form {
            .agree-label {
                label {
                    font-weight: 400;
                    color: #636363;
                    margin-left: 5px;
                    font-size: 15px;
                }

                margin-bottom: 20px;
            }
        }
    }
}

.blog-widget {
    margin-bottom: 35px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);

    .title {
        font-size: 20px;
        color: var(--titleColor);
        border-bottom: 1px solid #ededed;
        padding: 15px 30px;
        font-weight: 400;
    }

    .search-widget {
        padding: 20px 30px 30px;

        .search-form {
            position: relative;

            .form-control {
                height: 50px;
                border: 1px solid #ededed;
                background-color: #f4f4f4;
                box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
                padding: 10px 20px;
                width: 100%;
                border-radius: 0;

                &:focus {
                    border-color: var(--optionalColor);
                }
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                height: 50px;
                width: 50px;
                background-color: var(--mainColor);
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
                border: none;
                outline: none;

                i {
                    color: var(--whiteColor);
                }

                &:hover {
                    background-color: var(--titleColor);
                }
            }
        }
    }

    .widget-popular-post {
        position: relative;
        overflow: hidden;
        padding: 20px 30px 30px;

        .item {
            overflow: hidden;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #efefef;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }

            .thumb {
                float: left;
                overflow: hidden;
                position: relative;
                margin-right: 15px;

                .full-image {
                    width: 75px;
                    height: 105px;
                    display: inline-block;
                    background-size: cover !important;
                    background-repeat: no-repeat;
                    background-position: center center !important;
                    position: relative;
                    background-color: var(--titleColor);

                    &.bg1 {
                        background-image: url(assets/img/blog/blog-post1.png);
                    }

                    &.bg2 {
                        background-image: url(assets/img/blog/blog-post2.png);
                    }

                    &.bg3 {
                        background-image: url(assets/img/blog/blog-post3.png);
                    }

                }
            }

            .info {
                overflow: hidden;

                span {
                    display: block;
                    color: var(--mainColor);
                    text-transform: uppercase;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: 500;
                }

                .title-text {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font-size: 15px;
                    font-weight: 600;

                    a {
                        display: inline-block;
                        color: var(--titleColor);

                        &:hover {
                            color: var(--titleColor);
                        }
                    }
                }

                p {
                    font-size: 14px;
                    margin-bottom: 0;
                    max-width: 180px;
                }
            }
        }
    }

    .blog-widget-tag {
        list-style: none;
        margin: 0;
        padding: 10px 20px 30px;

        li {
            display: inline-block;
            padding: 0 5px;
            transition: 0.7s;
            color: #777777;
            font-size: 16px;

            a {
                color: #777777;
            }

            &:hover a {
                color: var(--mainColor);
            }
        }
    }

    .widget_categories {
        padding: 20px 30px;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 600;
                border-bottom: 1px solid #ededed;

                a {
                    display: block;
                    color: var(--paragraphColor);
                    font-weight: normal;

                    span {
                        float: right;
                        font-size: 13px;
                        color: var(--mainColor);
                        font-weight: 600;
                    }

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }

    .widget-post-into {
        padding: 20px 30px 30px;

        .blog-widget-post {
            span {
                color: var(--mainColor);
                font-size: 14px;
                font-weight: 600;
            }

            a {
                display: block;

                h3 {
                    font-size: 16px;
                    color: #777777;
                    position: relative;
                    margin-left: 40px;
                    margin-top: 10px;
                    max-width: 280px;
                    font-weight: 500;

                    &::before {
                        content: '';
                        position: absolute;
                        left: -35px;
                        width: 30px;
                        height: 1px;
                        top: 15px;
                        background-color: #777777;
                    }
                }
            }
        }
    }
}

.blog-widget-profile {
    padding: 30px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
    text-align: center;
    margin-bottom: 35px;

    .images {
        background-color: #fff6f2;
        padding: 15px 30px;
        border: 1px dashed var(--mainColor);
    }

    .content {
        border-bottom: 1px solid #ededed;
        padding-bottom: 15px;

        h3 {
            font-size: 16px;
            color: var(--titleColor);
            font-weight: 500;
            margin-top: 30px;
            margin-bottom: 7px;
        }

        span {
            font-size: 14px;
            color: var(--mainColor);
        }
    }

    p {
        padding-top: 20px;
        font-size: 15px;
        text-align: center;
        margin-bottom: 15px;
    }

    .social-link {
        margin: 20px 0 0 0;
        padding: 0;
        list-style: 0;

        li {
            display: inline-block;
            margin-right: 5px;

            a {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                line-height: 37px;
                text-align: center;
                color: var(--whiteColor);
            }

            &:nth-child(1) a {
                background-color: #3b5998;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(2) a {
                background-color: #55acee;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(3) a {
                background-color: #3f729b;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(4) a {
                background-color: #ff0000;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(5) a {
                background-color: #c80000;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
        }
    }
}

/*=================================
Blog Area End
====================================*/

/*=================================
Testimonial Area
====================================*/
.testimonial-area {
    position: relative;
    overflow: hidden;

    .owl-dots {
        margin-top: 0px !important;
        margin-bottom: 30px;

        .owl-dot {
            span {
                background-color: var(--mainColor) !important;
                width: 5px !important;
                height: 5px !important;
                transition: 0.7s;
            }

            &.active span {
                background-color: var(--mainColor) !important;
                width: 10px !important;
                height: 10px !important;
            }

            &:hover span {
                background-color: var(--mainColor) !important;
                width: 10px !important;
                height: 10px !important;
            }
        }
    }

    .testimonial-slider {
        .center {
            .testimonial-item {
                background-color: var(--whiteColor);
                box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
            }
        }
    }
}

.testimonial-area-two {
    background-image: url(assets/img/testimonial/testimonial-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .owl-nav {
        margin-top: 0;
        margin-bottom: 15px;

        .owl-prev {
            color: #bbbbbb !important;
            font-size: 24px !important;
            font-weight: 700;

            &:hover {
                color: var(--mainColor) !important;
                background-color: transparent !important;
            }
        }

        .owl-next {
            color: #bbbbbb !important;
            font-size: 24px !important;
            font-weight: 700;

            &:hover {
                color: var(--mainColor) !important;
                background-color: transparent !important;
            }
        }
    }
}

.testimonial-area-three {
    background-image: url(assets/img/testimonial/testimonial-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .testimonial-item {
        margin-top: 0 !important;
    }
}

.testimonial-item-bg {
    background-color: #f7f7f7;
}

.testimonial-item-bg2 {
    background-color: var(--whiteColor);
}

.testimonial-item {
    position: relative;
    padding: 30px;
    margin-top: 45px;
    margin-bottom: 30px;

    h3 {
        font: 18px;
        margin-bottom: 5px;
    }

    span {
        font-size: 14px;
        color: var(--mainColor);
    }

    p {
        margin-bottom: 15px;
        margin-top: 10px;
        font-size: 15px;
    }

    .rating {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            color: var(--mainColor);
        }
    }

    .testimonial-top {
        position: absolute;
        top: 0;
        right: 0;

        i {
            font-size: 60px;
            color: var(--optionalColor);
            padding-right: 30px;
            padding-top: 30px;
        }
    }
}

/*=================================
Testimonial Area End
====================================*/

/*=================================
Client Area
====================================*/
.client-area {
    position: relative;

    .owl-nav {
        position: absolute;
        bottom: 40px;
        margin-top: 0;
        margin-bottom: 15px;
        left: 110px;
        right: 0;

        .owl-prev {
            color: #bbbbbb !important;
            font-size: 24px !important;
            font-weight: 700;

            &:hover {
                color: var(--mainColor) !important;
                background-color: transparent !important;
            }
        }

        .owl-next {
            color: #bbbbbb !important;
            font-size: 24px !important;
            font-weight: 700;

            &:hover {
                color: var(--mainColor) !important;
                background-color: transparent !important;
            }
        }
    }
}

.client-bg {
    position: relative;
    width: 100%;
    margin-bottom: 110px;

    &::before {
        content: '';
        position: absolute;
        bottom: -10px;
        height: 360px;
        width: 100%;
        left: 0;
        right: 0;
        background-image: url(assets/img/testimonial/testimonial-bg3.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.client-img {
    position: relative;

    img {
        width: 520px !important;
    }
}

.client-content {
    margin-top: 50px;

    h3 {
        font-weight: 500;
        margin-bottom: 10px;
    }

    span {
        color: var(--mainColor);
        font-weight: 400;
    }

    p {
        margin-top: 20px;
        color: #555555;
        font-size: 18px;
        margin-bottom: 0;
        max-width: 480px;
    }
}

/*=================================
Client Area End
====================================*/

/*=================================
Product Area
====================================*/
.product-card {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    border: 1px dashed #eeeeee;
    margin-bottom: 30px;

    .product-content {
        padding: 30px 20px;

        ul {
            list-style: none;
            padding: 0;
            line-height: 1;
            margin-bottom: 15px;

            li {
                display: inline-block;
                color: var(--mainColor);
                padding: 0 5px;

                del {
                    color: var(--titleColor);
                }
            }
        }

        .rating {
            list-style: none;
            margin: 0 0 15px 0;
            padding: 0;
            float: right;

            li {
                color: var(--mainColor);
                font-size: 14px;
                display: inline-block;
            }
        }

        a {
            display: block;

            h3 {
                text-transform: capitalize;
                margin-bottom: 0;
                transition: all 0.5s;

                &:hover {
                    color: var(--mainColor);
                }
            }
        }

        .default-btn {
            &.product-btn {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 180px;
                bottom: 200px;
                padding: 10px 20px;
                transform: scale(0);

                i {
                    font-size: 20px;
                    position: relative;
                    top: 3px;
                    margin-right: 5px;
                }
            }
        }
    }

    &:hover {
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

        .default-btn {
            &.product-btn {
                transform: scale(1);
            }
        }
    }
}

/*=================================
Product Area End
====================================*/
/*=================================
Cart Wraps Area
===================================*/
.cart-wraps-area {
    .cart-table {
        table {
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        border-bottom-width: 0px;
                        vertical-align: middle;
                        padding: 0 0 15px;
                        text-transform: uppercase;
                        border: none;
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: var(--titleColor);
                        padding-left: 0;
                        padding-right: 0;
                        font-size: 15px;
                        border-color: #eeeeee;
                        border-left: none;
                        border-right: none;

                        &.product-thumbnail {
                            a {
                                display: block;

                                img {
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                        }

                        &.product-name {
                            a {
                                color: var(--titleColor);
                                font-weight: 600;
                                display: inline-block;
                                font-size: 16px;

                                &:hover {
                                    color: var(--mainColor) !important;
                                }
                            }
                        }

                        &.product-subtotal {
                            .remove {
                                color: var(--titleColor);
                                float: right;
                                position: relative;
                                top: 1px;
                                -webkit-transition: all 0.5s;
                                transition: all 0.5s;

                                i {
                                    font-size: 24px;
                                }

                                &:hover {
                                    color: var(--mainColor);
                                }
                            }

                            span {
                                font-weight: 600;
                            }
                        }

                        &.product-quantity {
                            .input-counter {
                                max-width: 130px;
                                min-width: 130px;
                                text-align: center;
                                display: inline-block;
                                position: relative;

                                span {
                                    position: absolute;
                                    top: 0;
                                    background-color: transparent;
                                    cursor: pointer;
                                    color: var(--titleColor);
                                    width: 40px;
                                    height: 100%;
                                    line-height: 48px;
                                    transition: 0.5s;

                                    &:hover {
                                        color: var(--paragraphColor) !important;
                                    }
                                }

                                .minus-btn {
                                    left: 0;

                                    &:hover {
                                        color: var(--paragraphColor) !important;
                                    }
                                }

                                .plus-btn {
                                    right: 0;

                                    &:hover {
                                        color: var(--paragraphColor) !important;
                                    }
                                }

                                input {
                                    height: 45px;
                                    color: var(--titleColor);
                                    outline: 0;
                                    display: block;
                                    border: none;
                                    background-color: #f8f8f8;
                                    text-align: center;
                                    width: 100%;
                                    font-size: 17px;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cart-buttons {
        margin-top: 30px;
    }

    .cart-totals {
        background: #ffffff;
        padding: 46px;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        border-radius: 0;
        margin: auto;
        margin-top: 50px;

        h3 {
            font-size: 24px;
            margin-bottom: 25px;
        }

        ul {
            padding: 0;
            margin: 0 0 25px;
            list-style-type: none;

            li {
                border: 1px solid var(--optionalColor);
                padding: 10px 15px;
                color: var(--titleColor);
                overflow: hidden;
                font-weight: 500;

                &:first-child {
                    border-bottom: none;
                }

                &:nth-child(3) {
                    border-top: none;
                }

                &:last-child {
                    border-top: none;
                }

                span {
                    float: right;
                    color: var(--paragraphColor);
                    font-weight: normal;
                }
            }
        }
    }

    .cart-calc {
        margin-top: 50px;
        background-color: #ffffff;
        padding: 40px;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

        .cart-wraps-form {
            h3 {
                font-size: 24px;
                color: var(--titleColor);
                padding-bottom: 20px;
            }

            .form-group {
                margin-bottom: 20px;

                select {
                    height: 50px;
                    padding: 7px 18px;
                    color: #6c777d;
                    border: 1px solid var(--optionalColor);

                    &:focus,
                    :hover {
                        outline: 0;
                        box-shadow: none;
                        border: 1px solid var(--titleColor);
                        background-color: var(--mainColor) !important;
                    }

                    option {
                        padding: 10px;

                        &:hover {
                            background-color: var(--mainColor) !important;
                        }
                    }
                }

                .form-control {
                    font-size: 16px;
                    border: 1px solid var(--optionalColor);
                    color: #6c777d;
                    padding: 25px 18px;
                    font-weight: 400;

                    &:focus,
                    :hover {
                        outline: 0;
                        box-shadow: none;
                        border: 1px solid var(--titleColor);
                    }
                }
            }
        }

        .nice-select {
            height: 50px;
            width: 100%;
            margin-bottom: 19px;
            border: 1px solid var(--optionalColor);

            .list {
                width: 100%;
            }

            .option {
                color: var(--titleColor) !important;

                :hover {
                    color: #ffffff !important;
                    background-color: var(--mainColor) !important;
                }
            }

            .current {
                position: relative;
                top: 4px;
            }
        }
    }
}

.cart-wraps-area .nice-select .option.focus,
.cart-wraps-area .nice-select .option.selected.focus,
.cart-wraps-area .nice-select .option:hover {
    background-color: var(--mainColor) !important;
    color: var(--whiteColor) !important;
    font-weight: 600;
}

.cart-wraps-area .cart-calc select .option.focus,
.cart-wraps-area .cart-calc .nice-select .option.selected.focus {
    background-color: var(--mainColor) !important;
    color: #ffffff !important;
}

.cart-wraps-area .cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
    color: var(--titleColor);
}

.cart-wraps-area .cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
    color: var(--titleColor);
}

.cart-wraps-area.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
    color: var(--titleColor);
}

.cart-wraps-area .cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
    color: var(--titleColor);
}

.cart-wraps-area .cart-calc .cart-wraps-area form .form-control input::-webkit-input-placeholder {
    color: #6c777d;
}

.cart-area .cart-calc .cart-wraps-area form .form-control input:-ms-input-placeholder {
    color: #6c777d;
}

.cart-area .cart-area .cart-calc .cart-wraps-area .form-control input::-ms-input-placeholder {
    color: #6c777d;
}

.cart-area .cart-area .cart-calc .cart-wraps-area .form-control input::placeholder {
    color: #495057;
}

/*=================================
Cart Wraps Area End
===================================*/

/*=================================
Checkout Area
===================================*/
.checkout-area {
    .checkout-user {
        box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.08);
        background: var(--whiteColor);
        padding: 20px 15px;
        margin-bottom: 65px;
        border-top: 3px solid var(--mainColor);
        color: var(--mainColor);

        span {
            color: var(--titleColor);
            font-size: 20px;

            a {
                color: var(--mainColor);

                &:hover {
                    color: var(--titleColor);
                }
            }
        }
    }
}

.billing-details {
    margin-bottom: 30px;
    background-color: var(--whiteColor);
    padding: 50px 30px 25px 30px;
    box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);

    h3 {
        font-size: 24px;
        color: var(--titleColor);
        margin-bottom: 15px;
        font-weight: 600;
    }

    .form-group {
        margin-bottom: 25px;

        label {
            color: var(--titleColor);
            display: block;
            margin-bottom: 10px;
            font-weight: 500;

            span {
                color: var(--mainColor);
            }
        }

        .form-control {
            height: 50px;
            color: #2d3652;
            border: 1px solid #e8e8e8;
            background-color: #fcfcff;
            border-radius: 0;
            padding: 10px 20px;
            width: 100%;

            &:focus,
            :hover {
                outline: 0;
                box-shadow: none;
                border: 1px solid var(--paragraphColor);
            }
        }

        .form-message {
            font-size: 16px;
            border: 1px solid #e8e8e8;
            background-color: #fcfcff;
            padding: 18px 18px;
            font-weight: 400;
            width: 100%;

            &:focus,
            :hover {
                outline: 0;
                box-shadow: none;
                border: 1px solid var(--paragraphColor);
            }
        }

        .nice-select {
            float: unset;
            line-height: 45px;
            color: var(--titleColor);
            padding-top: 0;
            padding-bottom: 0;

            .list {
                background-color: #ffffff;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;

                .option {
                    transition: 0.5s;
                    padding-left: 20px;
                    padding-right: 20px;

                    &:hover {
                        background-color: var(--mainColor) !important;
                        color: #ffffff;
                    }

                    &:focus {
                        border: none;
                        outline: none;
                    }

                    .selected {
                        background-color: transparent;
                    }
                }
            }

            &:after {
                right: 20px;
            }
        }
    }

    .form-check {
        margin-bottom: 15px;

        .form-check-input {
            width: 15px;
            height: 15px;
        }

        .form-check-label {
            color: var(--titleColor);
            margin-left: 5px;
            font-weight: 500;
        }
    }
}

.checkout-area .billing-details .form-group .nice-select .option:hover,
.checkout-area .billing-details .form-group .nice-select .option.focus,
.checkout-area .billing-details .form-group .nice-select .option.selected.focus {
    background-color: var(--mainColor) !important;
    color: #ffffff !important;
    border: none;
    outline: none;
}

.order-details {
    .order-table {
        background-color: var(--whiteColor);
        padding: 50px 30px;
        box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);

        h3 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        table {
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        padding-left: 20px;
                        padding-top: 15px;
                        padding-right: 20px;
                        padding-bottom: 15px;
                        font-weight: 600;
                        color: var(--titleColor);
                        font-size: 18px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: var(--titleColor);
                        border-color: #eaedff;
                        font-size: 14px;
                        padding-left: 20px;
                        padding-right: 20px;
                        font-weight: 600;

                        &.product-name {
                            a {
                                color: #6e768f;
                                display: inline-block;
                                font-weight: 600;

                                &:hover {
                                    color: var(--titleColor);
                                }
                            }
                        }

                        &.order-subtotal {
                            span {
                                color: var(--titleColor);
                                font-weight: 600;
                            }
                        }

                        &.order-shipping {
                            span {
                                color: var(--titleColor);
                                font-weight: 700;
                            }
                        }

                        &.total-price {
                            span {
                                color: var(--titleColor);
                                font-weight: 700;
                            }
                        }

                        &.shipping-price {
                            font-weight: 700;
                        }

                        &.order-subtotal-price {
                            font-weight: 700;
                        }

                        &.product-subtotal {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .payment-box {
        background-color: var(--whiteColor);
        box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 50px 30px;

        .payment-method {
            p [type="radio"]:checked {
                display: none;
            }

            p [type="radio"]:checked+label {
                padding-left: 27px;
                cursor: pointer;
                display: block;
                font-weight: 600;
                color: var(--titleColor);
                position: relative;
                margin-bottom: 8px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #dddddd;
                    border-radius: 50%;
                    background: #ffffff;
                }

                &::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: var(--mainColor);
                    position: absolute;
                    top: 6px;
                    left: 3px;
                    border-radius: 50%;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }

            p [type="radio"]:not(:checked) {
                display: none;
            }

            p [type="radio"]:not(:checked)+label {
                padding-left: 27px;
                cursor: pointer;
                display: block;
                font-weight: 600;
                color: #172541;
                position: relative;
                margin-bottom: 8px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #dddddd;
                    border-radius: 50%;
                    background: #ffffff;
                }

                &::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: var(--mainColor);
                    position: absolute;
                    top: 6px;
                    left: 3px;
                    border-radius: 50%;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    opacity: 0;
                    visibility: hidden;
                    transform: scale(0);
                }
            }
        }

        .order-btn {
            margin-top: 20px;
            display: block;
            text-align: center;
            width: 100%;
            padding: 12px 27px;
            color: var(--whiteColor);
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 1;
            background-color: var(--titleColor);

            &::before {
                content: "";
                position: absolute;
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: var(--mainColor);
                z-index: -1;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 100%;
                background-color: var(--mainColor);
                z-index: -1;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
            }

            &:hover {
                color: var(--whiteColor);
                border: none;
            }

            &:hover::before {
                left: auto;
                right: 0;
                width: 50%;
            }

            &:hover::after {
                left: 0;
                right: 0;
                width: 50%;
            }
        }
    }
}

.billing-details .form-group .nice-select .option:hover,
.billing-details .form-group .nice-select .option.focus,
.billing-details .form-group .nice-select .option.selected.focus {
    background-color: var(--mainColor) !important;
    color: #ffffff !important;
}

/*=================================
Checkout Area End
===================================*/

/*==================================
Shop Details
====================================*/
.shop-detls-image {
    margin-bottom: 30px;
    border: 1px dashed #eeeeee;

    &:hover {
        border-color: var(--mainColor);
    }
}

.shop-desc {
    h3 {
        margin-bottom: 12px;
        font-size: 24px;
        font-weight: 600;
    }

    .price {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 600;

        .old-price {
            text-decoration: line-through;
            color: var(--titleColor);
            margin-left: 10px;
        }
    }

    .shop-review {
        margin-bottom: 15px;

        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 14px;

            i {
                color: var(--mainColor);
            }
        }

        .rating-count {
            margin-left: 5px;
            display: inline-block;
            color: var(--titleColor);
            border-bottom: 1px solid var(--titleColor);
            line-height: initial;

            &:hover {
                color: var(--mainColor);
                border-color: var(--mainColor);
            }
        }
    }

    p {
        margin-bottom: 0;
    }

    .input-count-area {
        h3 {
            font-size: 16px;
            display: inline-block;
            font-weight: 500;
            margin-right: 15px;
        }

        .input-counter {
            margin-top: 10px;
            max-width: 130px;
            min-width: 130px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;
            margin-bottom: 15px;

            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: var(--mainColor);
                width: 50px;
                height: 100%;
                line-height: 45px;
                -webkit-transition: 0.5s;
                transition: 0.5s;

                &:hover {
                    color: var(--mainColor);
                }
            }

            .minus-btn {
                left: 0;
            }

            .plus-btn {
                right: 0;
            }

            input {
                height: 45px;
                color: var(--titleColor);
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }

    .shop-add {
        margin-top: 20px;

        .default-btn {
            border: none;
            margin-right: 20px;
        }
    }

    .shop-share {
        margin-top: 30px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin: 0 3px;

                span {
                    color: var(--paragraphColor);
                    margin-right: 5px;
                    font-size: 16px;
                }

                a {
                    width: 35px;
                    height: 35px;
                    background-color: var(--mainColor);
                    font-size: 16px;
                    color: var(--whiteColor);
                    line-height: 35px;
                    border-radius: 50px;
                    text-align: center;

                    &:hover {
                        background-color: var(--titleColor);
                    }
                }
            }
        }
    }

}

.shop .input-count-area .input-counter input::-webkit-input-placeholder {
    color: var(--titleColor);
}

.shop .input-count-area .input-counter input:-ms-input-placeholder {
    color: var(--titleColor);
}

.shop .input-count-area .input-counter input::-ms-input-placeholder {
    color: var(--titleColor);
}

.shop .input-count-area .input-counter input::placeholder {
    color: var(--titleColor);
}

.tab {
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}

.shop-detls-tab {
    margin-top: 30px;

    .tabs {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            line-height: initial;
            margin-right: 5px;

            a {
                display: inline-block;
                position: relative;
                text-transform: uppercase;
                color: var(--titleColor);
                border: 1px dashed #eeeeee;
                padding: 17px 30px 13px 30px;

                &:focus {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                }
            }

            &.active a {
                color: var(--whiteColor);
                background-color: var(--mainColor);
                border-color: var(--mainColor);
            }

            &.current a {
                color: var(--whiteColor);
                background-color: var(--mainColor);
                border-color: var(--mainColor);
            }
        }
    }

    .tab_content {
        border: 1px dashed #eeeeee;
        padding: 30px;

        .tabs_item {
            .shop-detls-tab-content {
                p {
                    color: var(--titleColor);
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .additional-information {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;
                    text-align: left;

                    li {
                        border: 1px solid #eeeeee;
                        border-bottom: none;
                        padding: 10px 15px 7px;
                        color: #737b9a;

                        &:last-child {
                            border-bottom: 1px solid #eeeeee;
                        }

                        span {
                            display: inline-block;
                            width: 40%;
                            color: #212529;
                            font-weight: 600;
                        }
                    }
                }

                h3 {
                    margin-bottom: 15px;
                    font-size: 24px;
                    color: var(--titleColor);
                }

                .shop-review-form {
                    .review-title {
                        position: relative;

                        .rating {
                            display: inline-block;

                            i {
                                color: var(--mainColor);
                                font-size: 14px;
                            }

                            p {
                                margin-bottom: 0;
                                display: inline-block;
                                padding-left: 5px;
                                line-height: initial;
                            }
                        }

                        .btn-right {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            font-size: 14px;
                            color: var(--whiteColor);
                            background-color: var(--titleColor);
                            padding: 7px 15px;
                            border-radius: 5px;

                            &:hover {
                                background-color: var(--mainColor);
                                color: var(--whiteColor);
                            }
                        }
                    }

                    .review-comments {
                        margin-top: 35px;

                        .review-item {
                            margin-top: 30px;
                            position: relative;
                            padding-right: 200px;
                            border-top: 1px dashed #eeeeee;
                            padding-top: 30px;

                            .rating {
                                i {
                                    color: var(--mainColor);
                                    font-size: 14px;
                                }
                            }

                            h3 {
                                font-size: 24px;
                                margin-top: 10px;
                                margin-bottom: 10px;
                                color: var(--titleColor);
                            }

                            span {
                                margin-bottom: 10px;
                                font-size: 16px;
                                display: block;
                                font-weight: 500;

                                strong {
                                    font-weight: 600;
                                }
                            }

                            p {
                                margin-bottom: 0;
                            }

                            .review-report-link {
                                position: absolute;
                                right: 0;
                                color: #737b9a;
                                top: 40px;
                                text-decoration: underline;
                                font-weight: 600;
                                font-size: 15px;

                                &:hover {
                                    color: var(--mainColor);
                                }
                            }
                        }
                    }

                    .review-form {
                        margin-top: 30px;

                        h3 {
                            margin-bottom: 20px;
                        }

                        .contact-wrap-form {
                            box-shadow: none;
                            padding: 0;

                            .contact-form {
                                padding: 50px;
                                max-width: 100%;
                                margin: 0 auto 0;
                                background-color: #f4f4f4;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import '/node_modules/alertifyjs/build/css/alertify.css';
@import '/node_modules/alertifyjs/build/css/themes/default.css';
/*==================================
Shop Details End
====================================*/
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

/*=================================
Footer Area
====================================*/
.footer-bg {
    background-color: #2a2a2a;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        background-image: url(assets/img/shape/footer-dots.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.footer-bg2 {
    background-color: #2a2a2a;
}

.footer-top {
    padding-top: 50px;
    padding-bottom: 40px;
    border-bottom: 1px dashed #393939;
}

.newsletter-title {
    i {
        font-size: 50px;
        color: var(--whiteColor);
        display: inline-block;
    }

    h2 {
        font-size: 30px;
        color: var(--whiteColor);
        font-weight: 500;
        margin-left: 20px;
        display: inline-block;
        margin-bottom: 0;
    }
}

.newsletter-form {
    position: relative;
    max-width: 550px;
    border-radius: 50px;

    .form-control {
        background: var(--whiteColor);
        height: 55px;
        line-height: 55px;
        margin: 0;
        border-radius: 50px;
        border: none;
        padding: 0 25px;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }
    }

    .default-btn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        line-height: 55px;
        padding: 0 30px;
        height: 55px;
        border: 0;
    }

    .validation-danger {
        font-size: 18px;
        margin-top: 15px;
        color: red;
    }
}

.footer-widget {
    margin-bottom: 30px;

    .logo {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 15px;
        color: #a6a6a6;
        max-width: 290px;
        font-weight: 500;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h3 {
        margin-top: 30px;
        color: var(--whiteColor);
        margin-bottom: 15px;
        font-weight: 500;
    }

    .footer-contact-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            font-size: 15px;
            margin-top: 5px;
            color: #a6a6a6;

            span {
                color: #a6a6a6;
                font-weight: 500;
            }

            a {
                color: #a6a6a6;
            }
        }
    }

    .social-link {
        margin: 20px 0 0 0;
        padding: 0;
        list-style: 0;

        li {
            display: inline-block;
            margin-right: 5px;

            a {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                line-height: 37px;
                text-align: center;
                color: var(--whiteColor);
            }

            &:nth-child(1) a {
                background-color: #3b5998;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(2) a {
                background-color: #55acee;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(3) a {
                background-color: #3f729b;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(4) a {
                background-color: #ff0000;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }

            &:nth-child(5) a {
                background-color: #c80000;

                &:hover {
                    background-color: var(--mainColor);
                    animation: tada 1s linear;
                }
            }
        }
    }

    .footer-news {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-bottom: 5px;

            .img {
                display: inline-block;
                margin-right: 15px;

                a {
                    display: inline-block;
                }
            }

            .content {
                display: inline-block;
                position: relative;
                top: 10px;

                h4 {
                    display: block;
                    font-size: 14px;
                    color: #d7d7d7;
                    position: relative;
                    margin-left: 30px;
                    font-weight: 500;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: -30px;
                        width: 30px;
                        height: 1px;
                        background-color: #d7d7d7;
                    }
                }

                span {
                    display: block;
                    font-size: 15px;
                    color: #d7d7d7;

                    a {
                        display: block;
                        color: #d7d7d7;
                    }

                    &:hover a {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }

    .footer-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: #bdcbd5;
            }

            &:hover a {
                color: var(--mainColor);
                letter-spacing: 0.25px;
            }
        }
    }

    .footer-form {
        .form-group {
            margin-bottom: 15px;

            .form-control {
                border: 0;
                border-radius: 0;
                height: 45px;
                line-height: 45px;
                padding: 0 20px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border: none;
                }

                &:hover {
                    outline: none;
                    box-shadow: none;
                    border: none;
                }
            }
        }

        .default-btn {
            width: 100%;
        }
    }
}

.copy-right-area {
    background-color: #000000;
    padding: 25px 0;
}

.copy-right-text {
    p {
        padding: 0;
        margin: 0;
        font-size: 15px;
        color: #a6a6a6;
        ;

        a {
            color: var(--whiteColor);
            font-weight: 500;
            font-size: 15px;
            text-decoration: underline;

            &:hover {
                color: var(--mainColor);
            }
        }
    }
}

.copy-right-list {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 15px;

            a {
                color: #a3a3a3;

                &:hover {
                    color: var(--mainColor);
                }
            }
        }
    }
}

/*=================================
Footer Area End
====================================*/
/*=================================
Page-navigation Area Style
====================================*/
.pagination-area {
    margin-bottom: 20px;

    .page-numbers {
        width: 38px;
        height: 38px;
        color: var(--titleColor);
        text-align: center;
        display: inline-block;
        border-radius: 50px;
        line-height: 38px;
        position: relative;
        margin-left: 3px;
        margin-right: 3px;
        font-size: 20px;
        font-weight: 500;

        &:hover {
            color: var(--mainColor);
        }

        i {
            position: relative;
            font-size: 30px;
            top: 5px;

        }

        &.current {
            color: var(--mainColor);
        }
    }
}

/*=================================
Page-navigation Area Style
====================================*/

/*=================================
Faq Area Style
====================================*/
.faq-area {
    background-color: #f5f5f5;
}

.faq-item {
    margin-bottom: 30px;

    h3 {
        font-size: 22px;
        margin-bottom: 15px;
        color: var(--titleColor);
        font-weight: 500;
    }

    p {
        margin-bottom: 0;
    }
}

.faq-section {
    .contact-card {
        background-color: none !important;

        &::before {
            display: none;
        }
    }
}

.faq-contact {
    .contact-form {
        background-color: #f5f5f5 !important;
        margin-bottom: 30px;
    }
}

/*=================================
Faq Area Style End
====================================*/

/*=================================
Contact Area
====================================*/
.contact-area {
    padding-top: 84px;
    padding-bottom: 70px;
}

.contact-card {
    margin-bottom: 30px;
    padding: 0 30px;
    background-color: var(--whiteColor);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        right: -15px;
        height: 180px;
        width: 2px;
        top: -5px;
        background-color: #ededed;
    }

    i {
        font-size: 60px;
        color: var(--mainColor);
        margin-bottom: 10px;
    }

    h3 {
        font-size: 16px;
        color: #727272;

        a {
            color: #727272;

            &:hover {
                color: var(--mainColor);
            }
        }
    }
}

.contact-area {
    .col-lg-4 {
        &:nth-child(3) {
            .contact-card {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.contact-map {
    position: relative;
}

.contact-map iframe {
    width: 100%;
    border: none;
    height: 820px;
}

.contact-wrap {
    position: absolute;
    top: 100px;
    left: 5%;
}

.contact-form {
    padding: 50px;
    max-width: 800px;
    margin: 0 auto 0;
    background-color: var(--whiteColor);

    span {
        color: var(--mainColor);
        line-height: 0;
        font-size: 14px;
        text-transform: uppercase;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 20px;
        color: #234467;
        font-weight: 400;
    }

    .form-group {
        margin-bottom: 25px;
        position: relative;

        i {
            position: absolute;
            top: 14px;
            left: 15px;
            line-height: 1;
            font-size: 18px;
            color: #7f7f7f;
            z-index: 1;
        }

        .form-control {
            height: 50px;
            color: #7f7f7f;
            border: 1px solid #e8e8e8;
            background-color: #ffffff;
            border-radius: 0;
            font-size: 14px;
            padding: 10px 20px 10px 40px;
            width: 100%;

            &:focus {
                outline: none;
                border-color: #ffc0a7;
                box-shadow: none;
            }
        }

        textarea.form-control {
            height: auto;
        }
    }

    .with-errors {
        float: left;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 0;
        color: #f00;
        font-weight: 400;
        display: block;
    }

    .text-danger {
        font-size: 18px;
        margin-top: 15px;
    }

    .default-btn {
        border: 0;
        outline: none;
    }
}

.contact-form .form-group .form-control::-webkit-input-placeholder {
    color: #7f7f7f;
}

.contact-form .form-group .form-control:-ms-input-placeholder {
    color: #7f7f7f;
}

.contact-form .form-group .form-control::-ms-input-placeholder {
    color: #7f7f7f;
}

.contact-form .form-group .form-control::placeholder {
    color: #7f7f7f;
}

/*=================================
Contact Area End
====================================*/

/*=================================
User Area
====================================*/
.user-section {
    .user-content {
        margin-bottom: 40px;

        img {
            margin-bottom: 25px;
        }

        h2 {
            color: var(--titleColor);
            font-size: 30px;

            b {
                font-weight: 300;
            }
        }
    }

    .tabs {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            line-height: initial;
            padding: 13px 50px 13px 50px;
            border-bottom: 1px solid #ededed;

            a {
                font-size: 16px;
                color: var(--titleColor);

                i {
                    color: var(--mainColor);
                    font-size: 18px;
                }
            }

            &.active {
                border-bottom: 1px solid var(--mainColor);
            }

            &.current {
                border-bottom: 1px solid var(--mainColor);
            }
        }
    }

    .user-all-form {
        margin-bottom: 30px;

        .contact-form {
            padding: 35px 50px !important;

            .agree-label {
                margin-top: 30px;
                float: left;

                label {
                    font-weight: 400;
                    color: #828282;
                    margin-left: 10px;
                }
            }

            .form-group {
                .form-control {
                    background-color: #f7f7f7 !important;
                }
            }

            .forget {
                margin-top: 30px;
                float: right;
                color: var(--mainColor);
                border-bottom: 1px solid var(--mainColor);
                font-weight: 400;

                &:hover {
                    color: var(--titleColor);
                    border-color: var(--titleColor);
                }
            }

            .account-desc {
                margin-top: 30px;
                font-weight: 400;
                text-align: center;
                margin-bottom: 0;

                a {
                    color: #828282;

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }

            .user-all-btn {
                width: 100%;
            }

            .social-option {
                margin-top: 50px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 12px;
                    left: 0;
                    width: 37%;
                    height: 1px;
                    background-color: #d5d5d5;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 12px;
                    right: 0;
                    width: 37%;
                    height: 1px;
                    background-color: #d5d5d5;
                }

                h3 {
                    font-size: 16px;
                    color: #828282;
                    font-weight: 400;
                    margin-bottom: 30px;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        display: inline-block;

                        a {
                            width: 140px;
                            height: 50px;
                            line-height: 50px;
                            color: var(--whiteColor);
                            border-radius: 50px;
                        }

                        &:nth-child(1) a {
                            background-color: #3b5998;

                            &:hover {
                                background-color: var(--mainColor);
                                animation: tada 1s linear;
                            }
                        }

                        &:nth-child(2) a {
                            background-color: #55acee;

                            &:hover {
                                background-color: var(--mainColor);
                                animation: tada 1s linear;
                            }
                        }

                        &:nth-child(3) a {
                            background-color: #3f729b;

                            &:hover {
                                background-color: var(--mainColor);
                                animation: tada 1s linear;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=================================
User Area End
====================================*/

/*=================================
404 Error Area
===================================*/
.error-area {
    height: 100vh;
    background-image: url(assets/img/inner-banner/inner-banner1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.8;
    }

    .error-content {
        max-width: 700px;
        background: var(--whiteColor);
        border-radius: 5px;
        margin: 0 auto;
        padding: 80px 50px 50px;
        text-align: center;
        position: relative;

        img {
            margin-bottom: 70px;
        }

        h1 {
            font-size: 150px;
            line-height: 0.5;
            font-weight: 600;
            color: var(--titleColor);

            span {
                color: var(--mainColor);
            }
        }

        h3 {
            margin: 50px 0 0;
            position: relative;
            color: var(--mainColor);
            font-size: 24px;
        }

        p {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 18px;
            max-width: 520px;
            margin-left: auto;
            margin-right: auto;
            color: var(--titleColor);
        }
    }
}

/*=================================
404 Error Area End
===================================*/

/*==================================
Coming Soon Area
====================================*/
.coming-soon-area {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-image: url(assets/img/inner-banner/inner-banner6.jpg);
    background-size: cover;
    background-position: center center;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.8;
    }

    .coming-soon-content {
        text-align: center;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 2;
        background-color: #ffffff;
        padding: 50px 30px;
        border: 5px solid #aec5df;

        img {
            margin-bottom: 30px;
        }

        h1 {
            margin-bottom: 0;
            color: var(--titleColor);
            font-size: 60px;
            margin-top: -18px;
        }

        p {
            font-size: 16px;
            max-width: 600px;
            margin-top: 15px;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
        }

        #timer {
            margin-top: 20px;

            div {
                display: inline-block;
                color: var(--titleColor);
                position: relative;
                margin-left: 35px;
                margin-right: 35px;
                font-size: 45px;
                font-weight: 700;

                span {
                    display: block;
                    text-transform: capitalize;
                    margin-top: -15px;
                    font-size: 16px;
                    font-weight: normal;
                }

                &:last-child {
                    margin-right: 0;

                    &::before {
                        display: none;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    right: -50px;
                    top: -10px;
                    font-size: 70px;
                    color: #ffffff;
                }
            }
        }

        .newsletter-form {
            position: relative;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;

            .input-newsletter {
                display: block;
                width: 100%;
                height: 60px;
                border: none;
                background-color: rgba(255, 255, 255, 0.3);
                padding-left: 15px;
                color: #ffffff;
                outline: 0;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                border-radius: 0;
                border: 1px solid var(--optionalColor);
                color: #5d5d5d;

                &:focus {
                    border-color: var(--mainColor);
                }
            }

            .default-btn {
                border: 0;
                outline: 0;
            }

            button {
                position: absolute;
                right: 0;
                top: 0;
                height: 60px;
                padding: 0 30px;
                text-transform: uppercase;
                outline: 0;
                color: #ffffff;
                transition: 0.5s;
                font-weight: 500;
                font-size: 15px;
                cursor: pointer;

                &::after {
                    border-radius: 0;
                }

                &::before {
                    border-radius: 0;
                }

                &:hover {
                    color: #ffffff;
                    background-color: #190f3c;
                }
            }
        }

        ul {
            list-style: none;
            margin-top: 30px;
            padding: 0;

            li {
                display: inline-block;
                width: 45px;
                height: 45px;
                line-height: 50px;
                font-size: 18px;
                background-color: var(--mainColor);
                color: var(--whiteColor);
                border-radius: 50px;
                margin-right: 10px;

                a {
                    color: var(--whiteColor);
                }

                &:hover {
                    background-color: var(--titleColor);
                }
            }
        }
    }

    #validator-newsletter {
        text-align: left;
        color: #dc3545 !important;
    }
}

/*==================================
Coming Soon Area End
====================================*/
.single-content {
    margin-bottom: 30px;

    h3 {
        font-size: 24px;
        color: var(--titleColor);
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

/*==================================
Preloader CSS
=====================================*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: var(--mainColor);
}

.spinner {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: var(--whiteColor);
    margin: 100px auto;
    animation: rotate-in 1.2s infinite ease-in-out;
}

/*==================================
Preloader CSS End
=====================================*/

/*==================================
Back To Top Button
=====================================*/
#toTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    display: none;
    z-index: 99;
}

.top-btn {
    background-color: var(--mainColor);
    color: var(--whiteColor);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 0 15px var(--mainColor);
    font-size: 20px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    transition: .9s;

    &:hover {
        background: var(--titleColor);
        box-shadow: 0 0 15px var(--titleColor);
        color: #fff;
    }
}

/*==============================
Back To Top Button
=================================*/

/*==============================
Animation  CSS
=================================*/
@-webkit-keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    75% {
        -webkit-transform: scale(1.75);
        transform: scale(1.75);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    75% {
        -webkit-transform: scale(1.75);
        transform: scale(1.75);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0;
    }
}

@-webkit-keyframes rotate-in {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@keyframes rotate-in {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/*==============================
Animation  CSS End
=================================*/
/*------------------------------------------------------------------
 [Master Stylesheet]

  Project:	Star Admin Bootstrap Template [Free Version]
  Version:	2.0
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap functions
  * Template variables
  * SCSS Compass Functions
  * Boostrap Main SCSS
  * Template mixins
    + Animation Mixins
    + Background Mixins
    + BlockQuote Mixins
    + Badges Mixins
    + Buttons Mixins
    + Cards Mixins
    + Miscellaneous Mixins
    + Text Mixins
  * Core Styles
    + Reset Styles
    + Fonts
    + Functions
    + Sidebar
    + Navbar
    + Typography
    + Miscellaneous
    + Footer
    + Layouts
    + Utilities
    + Demo styles
    + Dashboard
  * Components
    + Badges
    + Buttons
    + Cards
    + Checkboxes and Radios
    + Dropdowns
    + Forms
    + Icons
    + Preview
    + Tables
  * Landing screens
    + Auth
    + Error
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------*/
/* === Import Bootstrap functions and variables === */
/*-------------------------------------------------------------------*/
/* === Import template variables === */
/*-------------------------------------------------------------------*/
/* === SCSS Compass Functions === */
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");
@import '~bootstrap/dist/css/bootstrap.min.css';

/*-------------------------------------------------------------------*/
/* === Boostrap Main SCSS === */
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
    --blue: #1991EB;
    --indigo: #6610f2;
    --purple: #745af2;
    --pink: #e83e8c;
    --red: #FF0017;
    --orange: #FFC105;
    --yellow: #ffc107;
    --green: #08D419;
    --teal: #58d8a3;
    --cyan: #17a2b8;
    --white: #ffffff;
    --gray: #6c757d;
    --gray-dark: #292b2c;
    --blue: #1991EB;
    --indigo: #6610f2;
    --purple: #745af2;
    --pink: #e83e8c;
    --red: #FF0017;
    --orange: #FFC105;
    --yellow: #ffc107;
    --green: #08D419;
    --teal: #58d8a3;
    --cyan: #17a2b8;
    --white: #ffffff;
    --white-smoke: #f2f7f8;
    --gray: #6c757d;
    --gray-light: #636c72;
    --gray-lightest: #f7f7f9;
    --primary: #1991EB;
    --secondary: #dee2e6;
    --success: #08D419;
    --info: #745af2;
    --warning: #FFC105;
    --danger: #FF0017;
    --light: #f8f9fa;
    --dark: #424964;
    --teal: #58d8a3;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
    width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

#main {
    margin-top: 10px;
    padding: 20px 30px;
    transition: all 0.3s;
}

#sectionToPrint {

    font-size: 18px;
}







[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

dfn {
    font-style: italic;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    position: relative;
}

.link {
    color: #007bff !important;
}

.link:hover {
    color: #0056b3 !important;
    text-decoration: underline !important;
}

a:hover {
    color: #0056b3;
    text-decoration: none !important;
}

a.m-link:hover:after {
    border-bottom: 1px solid #0056b3;
    opacity: 0.3;
    filter: alpha(opacity=30);
}

a.m-link:hover:after {
    width: 100%;
}

a.m-link:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

pre,
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.8375rem;
    padding-bottom: 0.8375rem;
    color: #c2c2c2;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: "\2014 \00A0";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-sm-1 {
        /* flex: 0 0 8.33333%;
        max-width: 8.33333%; */
        flex: 0 0 8.11111%;
        max-width: 8.11111%;
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 10.66667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333%;
    }

    .offset-sm-2 {
        margin-left: 16.66667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333%;
    }

    .offset-sm-5 {
        margin-left: 41.66667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333%;
    }

    .offset-sm-8 {
        margin-left: 66.66667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333%;
    }

    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col-md-splpro {
        flex: 0 0 33.33333%;
        max-width: 25%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333%;
    }

    .offset-md-2 {
        margin-left: 16.66667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333%;
    }

    .offset-md-5 {
        margin-left: 41.66667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333%;
    }

    .offset-md-8 {
        margin-left: 66.66667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333%;
    }

    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table th,
.table td {
    padding: 0.8375rem;
    vertical-align: top;
    border-top: 1px solid #f3f3f3;
}

.table thead th {
    vertical-align: top;
    border-bottom: 2px solid #f3f3f3;
}

.table tbody+tbody {
    border-top: 2px solid #f3f3f3;
}

.table .table {
    background-color: #fff;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #f3f3f3 !important;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #f3f3f3;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F6F8FA;
}


/********************************2022*************************************/
.tablebelow {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.tablegrid {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-bottom: 1rem;
    background-color: transparent;
}

.tablegrid td:nth-child(2) {
    text-align: left;
}

.tablegrid td:nth-child(7) {
    width: 25px;
}

.tablegrid-font tbody tr:nth-of-type(odd) {
    font-size: larger;
}

.tablegrid-font th,
.tablegrid-font td {
    border: 1px solid #ddd !important;
    font-size: 20px;
    padding: 10px;

}

/********************************2022*************************************/

/*.table-hover tbody tr:hover {background-color: #F6F8FA;}*/
.table-hover tbody tr:hover,
.table tbody tr.active {
    background-color: rgba(218, 244, 255, 0.3) !important;
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #bfe0f9;
}

.table-hover .table-primary:hover {
    background-color: #a8d5f7;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #a8d5f7;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #f6f7f8;
}

.table-hover .table-secondary:hover {
    background-color: #e8eaed;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #e8eaed;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #baf3bf;
}

.table-hover .table-success:hover {
    background-color: #a4efab;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #a4efab;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #d8d1fb;
}

.table-hover .table-info:hover {
    background-color: #c4baf9;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #c4baf9;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #ffeeb9;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a0;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a0;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #ffb8be;
}

.table-hover .table-danger:hover {
    background-color: #ff9fa7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #ff9fa7;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #caccd4;
}

.table-hover .table-dark:hover {
    background-color: #bcbec9;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #bcbec9;
}

.table-teal,
.table-teal>th,
.table-teal>td {
    background-color: #d0f4e5;
}

.table-hover .table-teal:hover {
    background-color: #bbefda;
}

.table-hover .table-teal:hover>td,
.table-hover .table-teal:hover>th {
    background-color: #bbefda;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #f3f3f3;
}

.table-dark {
    color: #fff;
    background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #32383e;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.form-control {
    display: block;
    width: 100%;
    padding: 8px 12px;
    font-size: 1rem;
    line-height: 16px;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
    color: #c9c8c8;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    border-color: #eeeff0;
}

.ng2-flatpickr-input-container.form-control[readonly] {
    background-color: #f7f7f7;
}

/*select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
}*/

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #ffffff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.56rem + 1px);
    padding-bottom: calc(0.56rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1;
}

.col-form-label-lg {
    padding-top: calc(0.94rem + 1px);
    padding-bottom: calc(0.94rem + 1px);
    font-size: 1.25rem;
    line-height: 1;
}

.col-form-label-sm {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 0.875rem;
    line-height: 1;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.56rem;
    padding-bottom: 0.56rem;
    margin-bottom: 0;
    line-height: 1;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    padding: 0.5rem 0.81rem;
    font-size: 0.875rem;
    line-height: 1;
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px);
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
    padding: 0.94rem 1.94rem;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 0px;
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]) {
    height: calc(2.875rem + 2px);
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
    color: #c2c2c2;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.8);
    border-radius: 0.2rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #28a745;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #28a745;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: #28a745;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    background-color: #71dd8a;
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid~.custom-file-label::before,
.custom-file-input.is-valid~.custom-file-label::before {
    border-color: inherit;
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.8);
    border-radius: .2rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #dc3545;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
    color: #dc3545;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
    background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
    background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid~.custom-file-label::before,
.custom-file-input.is-invalid~.custom-file-label::before {
    border-color: inherit;
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group {
        width: auto;
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.56rem 1.375rem;
    font-size: 1rem;
    line-height: 1;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
}

.btn:hover,
.btn:focus {
    text-decoration: none;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
    background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #1991EB;
    border-color: #1991EB;
}

.btn-primary:hover,
.btn-primary:focus {
    color: #fff;
    background-color: #127ccc;
    border-color: #1175c0;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 145, 235, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #1991EB;
    border-color: #1991EB;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1175c0;
    border-color: #106eb5;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 145, 235, 0.5);
}

.btn-secondary {
    color: #212529;
    background-color: #dcdcdc;
    border-color: #a5a2a282;
}

.btn-secondary:hover,
.btn-secondary:focus {
    color: #212529;
    background-color: #c8cfd6;
    border-color: #c1c9d0;
}

.btn-secondary:focus,
.btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #c1c9d0;
    border-color: #bac2cb;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-success {
    color: #fff;
    background-color: #08D419;
    border-color: #08D419;
}

.btn-success:hover,
.btn-success:focus {
    color: #fff;
    background-color: #07af15;
    border-color: #06a313;
}

.btn-success:focus,
.btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(8, 212, 25, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #08D419;
    border-color: #08D419;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #06a313;
    border-color: #069712;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(8, 212, 25, 0.5);
}

.btn-info {
    color: #fff;
    background-color: #745af2;
    border-color: #745af2;
}

.btn-info:hover,
.btn-info:focus {
    color: #fff;
    background-color: #5637ef;
    border-color: #4c2bee;
}

.btn-info:focus,
.btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 90, 242, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #745af2;
    border-color: #745af2;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #4c2bee;
    border-color: #421fed;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 90, 242, 0.5);
}

.btn-warning {
    color: #212529;
    background-color: #FFC105;
    border-color: #FFC105;
}

.btn-warning:hover,
.btn-warning:focus {
    color: #212529;
    background-color: #dea700;
    border-color: #d19d00;
}

.btn-warning:focus,
.btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 5, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #FFC105;
    border-color: #FFC105;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d19d00;
    border-color: #c49400;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 5, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #FF0017;
    border-color: #FF0017;
}

.btn-danger:hover,
.btn-danger:focus {
    color: #fff;
    background-color: #d90014;
    border-color: #cc0012;
}

.btn-danger:focus,
.btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 23, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #FF0017;
    border-color: #FF0017;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #cc0012;
    border-color: #bf0011;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 23, 0.5);
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover,
.btn-light:focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #424964;
    border-color: #424964;
}

.btn-dark:hover,
.btn-dark:focus {
    color: #fff;
    background-color: #33384d;
    border-color: #2e3345;
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 73, 100, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #424964;
    border-color: #424964;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #2e3345;
    border-color: #292d3e;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 73, 100, 0.5);
}

.btn-teal {
    color: #212529;
    background-color: #58d8a3;
    border-color: #58d8a3;
}

.btn-teal:hover,
.btn-teal:focus {
    color: #212529;
    background-color: #39d192;
    border-color: #30cd8c;
}

.btn-teal:focus,
.btn-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 216, 163, 0.5);
}

.btn-teal.disabled,
.btn-teal:disabled {
    color: #212529;
    background-color: #58d8a3;
    border-color: #58d8a3;
}

.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active,
.show>.btn-teal.dropdown-toggle {
    color: #212529;
    background-color: #30cd8c;
    border-color: #2dc385;
}

.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 216, 163, 0.5);
}

.btn-outline-primary {
    color: #1991EB;
    background-color: transparent;
    background-image: none;
    border-color: #1991EB;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
    color: #fff;
    background-color: #1991EB;
    border-color: #1991EB;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 145, 235, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #1991EB;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #1991EB;
    border-color: #1991EB;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 145, 235, 0.5);
}

.btn-outline-secondary {
    color: #dee2e6;
    background-color: transparent;
    background-image: none;
    border-color: #dee2e6;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #dee2e6;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-success {
    color: #08D419;
    background-color: transparent;
    background-image: none;
    border-color: #08D419;
}

.btn-outline-success:hover,
.btn-outline-success:focus {
    color: #fff;
    background-color: #08D419;
    border-color: #08D419;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(8, 212, 25, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #08D419;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #08D419;
    border-color: #08D419;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(8, 212, 25, 0.5);
}

.btn-outline-info {
    color: #745af2;
    background-color: transparent;
    background-image: none;
    border-color: #745af2;
}

.btn-outline-info:hover,
.btn-outline-info:focus {
    color: #fff;
    background-color: #745af2;
    border-color: #745af2;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 90, 242, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #745af2;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #745af2;
    border-color: #745af2;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 90, 242, 0.5);
}

.btn-outline-warning {
    color: #FFC105;
    background-color: transparent;
    background-image: none;
    border-color: #FFC105;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus {
    color: #212529;
    background-color: #FFC105;
    border-color: #FFC105;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 5, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #FFC105;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #FFC105;
    border-color: #FFC105;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 5, 0.5);
}

.btn-outline-danger {
    color: #FF0017;
    background-color: transparent;
    background-image: none;
    border-color: #FF0017;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus {
    color: #fff;
    background-color: #FF0017;
    border-color: #FF0017;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 23, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #FF0017;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #FF0017;
    border-color: #FF0017;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 23, 0.5);
}

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
}

.btn-outline-light:hover,
.btn-outline-light:focus {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
    color: #424964;
    background-color: transparent;
    background-image: none;
    border-color: #424964;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus {
    color: #fff;
    background-color: #424964;
    border-color: #424964;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 73, 100, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #424964;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #424964;
    border-color: #424964;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 73, 100, 0.5);
}

.btn-outline-teal {
    color: #58d8a3;
    background-color: transparent;
    background-image: none;
    border-color: #58d8a3;
}

.btn-outline-teal:hover,
.btn-outline-teal:focus {
    color: #212529;
    background-color: #58d8a3;
    border-color: #58d8a3;
}

.btn-outline-teal:focus,
.btn-outline-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 216, 163, 0.5);
}

.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
    color: #58d8a3;
    background-color: transparent;
}

.btn-outline-teal:not(:disabled):not(.disabled):active,
.btn-outline-teal:not(:disabled):not(.disabled).active,
.show>.btn-outline-teal.dropdown-toggle {
    color: #212529;
    background-color: #58d8a3;
    border-color: #58d8a3;
}

.btn-outline-teal:not(:disabled):not(.disabled):active:focus,
.btn-outline-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 216, 163, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
}

.btn-link:hover,
.btn-link:focus {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.94rem 1.94rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.5rem 0.65rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.fade {
    opacity: 0;
    transition: opacity 0.15s linear;
}

.fade.show {
    opacity: 1;
}

.collapse {
    display: none;
}

.collapse.show {
    display: block;
}

tr.collapse.show {
    display: table-row;
}

tbody.collapse.show {
    display: table-row-group;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.dropup,
.dropdown {
    position: relative;
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    vertical-align: middle;
}

.dropdown.open .dropdown-toggle::after {
    transform: rotate(180deg);
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f3f3f3;
    border-radius: 0px;
}

.dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    margin-top: 0;
    margin-right: 0.125rem;
    right: 0;
    left: auto;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}

.dropleft .dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #eeeff0;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.35rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #212529;
    text-decoration: none;
    background-color: #f3f3f3 !important;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #212529;
    white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    flex: 0 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
    z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1;
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
    margin-left: -1px;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:first-child {
    margin-left: 0;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    /*padding-right: 1.03125rem;
    padding-left: 1.03125rem;*/
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.dropdown-toggle-split::after {
    margin-left: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.6075rem;
    padding-left: 0.6075rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 1.455rem;
    padding-left: 1.455rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
    width: 100%;
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.input-group input.form-control,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group input.form-control.ng2-flatpickr-input {
    width: 100%;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file:focus {
    z-index: 3;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    /*border-top-right-radius: 0;
            border-bottom-right-radius: 0;*/
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    /*border-top-left-radius: 0;
            border-bottom-left-radius: 0;*/
}

.input-group>.custom-file {
    display: flex;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label,
.input-group>.custom-file:not(:first-child) .custom-file-label::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.56rem 1.375rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
    margin-top: 0;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #007bff;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    margin-bottom: 0;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;

    /* left: 0; 6162022 */
    left: -25;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6;
    border-radius: 3px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    /* left: 0; 6162022 */
    left: -25;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat;
    background-position: right 0.55rem center;
    background-size: 8px 10px;
    appearance: none;
    line-height: 16px;
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #ffffff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    opacity: 0;
}

.custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
}

.custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus~.custom-file-control::before {
    border-color: #80bdff;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0px;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0px;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background-color: #6f88fa;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}


.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }

    .navbar-expand-sm .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}

@media (max-width: 767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }

    .navbar-expand-md .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}

@media (max-width: 991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }

    .navbar-expand-lg .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}

@media (max-width: 1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }

    .navbar-expand-xl .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: #202339;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: #202339;
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
    color: #202339;
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: 0px;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0px;
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
    display: flex;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: flex;
    flex-direction: column;
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group>.card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group>.card:first-child .card-img-top,
    .card-group>.card:first-child .card-header {
        border-top-right-radius: 0;
    }

    .card-group>.card:first-child .card-img-bottom,
    .card-group>.card:first-child .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group>.card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group>.card:last-child .card-img-top,
    .card-group>.card:last-child .card-header {
        border-top-left-radius: 0;
    }

    .card-group>.card:last-child .card-img-bottom,
    .card-group>.card:last-child .card-footer {
        border-bottom-left-radius: 0;
    }

    .card-group>.card:only-child {
        border-radius: 0px;
    }

    .card-group>.card:only-child .card-img-top,
    .card-group>.card:only-child .card-header {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    .card-group>.card:only-child .card-img-bottom,
    .card-group>.card:only-child .card-footer {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
    }

    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
        border-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.56rem 1.13rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: transparent;
    border-radius: 0px;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #495057;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0px;
}

.page-link {
    /*position: relative;
    display: block;
    padding: 0.45rem 0.85rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;*/
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    height: 1.90rem;
    min-width: 1.90rem;
    vertical-align: middle;
    padding: 0.5rem;
    text-align: center;
    position: relative;
    font-size: 0.80rem;
    /*line-height: 1rem;*/
    font-weight: 400;
}

.page-link:hover {
    /*color: #fff;
        text-decoration: none;
        background-color: #e9ecef;
        border-color: #dee2e6;*/
    z-index: 2;
    color: #2739c1;
    text-decoration: none;
    background-color: #2196f3;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

/*.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}*/
.pagination .page-item.previous>.page-link,
.pagination .page-item.next>.page-link,
.pagination .page-item.last>.page-link,
.pagination .page-item.first>.page-link {
    background: #ebe9f2;
}

.pagination .page-item.previous>.page-link:hover,
.pagination .page-item.next>.page-link:hover,
.pagination .page-item.last>.page-link:hover,
.pagination .page-item.first>.page-link:hover {
    background: #2196f3;
    color: #fff;
}

.pagination .page-item>.page-link {
    color: #898b96;
}

.pagination .page-item {
    margin-left: 0.4rem;
}

/*.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}*/

.page-item.active .page-link {
    /*z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;*/
    background: #2196f3;
    color: #fff;
}

.page-item.disabled .page-link {
    /*color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;*/
    opacity: 0.6;
    cursor: not-allowed;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

/*.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}*/

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0px !important;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #1991EB;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1175c0;
}

.badge-secondary {
    color: #212529;
    background-color: #dee2e6;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #c1c9d0;
}

.badge-success,
.preview-list .preview-item .preview-thumbnail .badge.badge-online {
    color: #fff;
    background-color: #08D419;
}

.badge-success[href]:hover,
.preview-list .preview-item .preview-thumbnail [href].badge.badge-online:hover,
.badge-success[href]:focus,
.preview-list .preview-item .preview-thumbnail [href].badge.badge-online:focus {
    color: #fff;
    text-decoration: none;
    background-color: #06a313;
}

.badge-info,
.preview-list .preview-item .preview-thumbnail .badge.badge-offline {
    color: #fff;
    background-color: #745af2;
}

.badge-info[href]:hover,
.preview-list .preview-item .preview-thumbnail [href].badge.badge-offline:hover,
.badge-info[href]:focus,
.preview-list .preview-item .preview-thumbnail [href].badge.badge-offline:focus {
    color: #fff;
    text-decoration: none;
    background-color: #4c2bee;
}

.badge-warning,
.preview-list .preview-item .preview-thumbnail .badge.badge-busy {
    color: #212529;
    background-color: #FFC105;
}

.badge-warning[href]:hover,
.preview-list .preview-item .preview-thumbnail [href].badge.badge-busy:hover,
.badge-warning[href]:focus,
.preview-list .preview-item .preview-thumbnail [href].badge.badge-busy:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d19d00;
}

.badge-danger {
    color: #fff;
    background-color: #FF0017;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #cc0012;
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5;
}

.badge-dark {
    color: #fff;
    background-color: #424964;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2e3345;
}

.badge-teal {
    color: #212529;
    background-color: #58d8a3;
}

.badge-teal[href]:hover,
.badge-teal[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #30cd8c;
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0px;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0px;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: -2px;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #0d4b7a;
    background-color: #d1e9fb;
    border-color: #bfe0f9;
    border-left: 6px solid #0d4b7a !important;
}

.alert-primary hr {
    border-top-color: #a8d5f7;
}

.alert-primary .alert-link {
    color: #082f4c;
}

.alert-secondary {
    color: #737678;
    background-color: #f8f9fa;
    border-color: #f6f7f8;
    border-left: 6px solid #737678 !important;
}

.alert-secondary hr {
    border-top-color: #e8eaed;
}

.alert-secondary .alert-link {
    color: #5a5c5e;
}

.alert-success {
    color: #046e0d;
    background-color: #cef6d1;
    border-color: #baf3bf;
    border-left: 6px solid #046e0d !important;
}

.alert-success hr {
    border-top-color: #a4efab;
}

.alert-success .alert-link {
    color: #023d07;
}

.alert-info {
    color: #3c2f7e;
    background-color: #e3defc;
    border-color: #d8d1fb;
    border-left: 6px solid #3c2f7e !important;
}

.alert-info hr {
    border-top-color: #c4baf9;
}

.alert-info .alert-link {
    color: #2a2159;
}

.alert-warning {
    color: #856403;
    background-color: #fff3cd;
    border-color: #ffeeb9;
    border-left: 6px solid #856403 !important;
}

.alert-warning hr {
    border-top-color: #ffe8a0;
}

.alert-warning .alert-link {
    color: #533f02;
}

.alert-danger {
    color: #85000c;
    background-color: #ffccd1;
    border-color: #ffb8be;
    border-left: 6px solid #85000c !important;
}

.alert-danger hr {
    border-top-color: #ff9fa7;
}

.alert-danger .alert-link {
    color: #520007;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
    border-left: 6px solid #818182 !important;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686868;
}

.alert-dark {
    color: #222634;
    background-color: #d9dbe0;
    border-color: #caccd4;
    border-left: 6px solid #222634 !important;
}

.alert-dark hr {
    border-top-color: #bcbec9;
}

.alert-dark .alert-link {
    color: #0e0f15;
}

.alert-teal {
    color: #2e7055;
    background-color: #def7ed;
    border-color: #d0f4e5;
    border-left: 6px solid #2e7055 !important;
}

.alert-teal hr {
    border-top-color: #bbefda;
}

.alert-teal .alert-link {
    color: #1f4c3a;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    background-color: #007bff;
    transition: width 0.6s ease;
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover,
.list-group-item:focus {
    z-index: 1;
    text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
}

.list-group-item-primary {
    color: #0d4b7a;
    background-color: #bfe0f9;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #0d4b7a;
    background-color: #a8d5f7;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #0d4b7a;
    border-color: #0d4b7a;
}

.list-group-item-secondary {
    color: #737678;
    background-color: #f6f7f8;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #737678;
    background-color: #e8eaed;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #737678;
    border-color: #737678;
}

.list-group-item-success {
    color: #046e0d;
    background-color: #baf3bf;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #046e0d;
    background-color: #a4efab;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #046e0d;
    border-color: #046e0d;
}

.list-group-item-info {
    color: #3c2f7e;
    background-color: #d8d1fb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #3c2f7e;
    background-color: #c4baf9;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #3c2f7e;
    border-color: #3c2f7e;
}

.list-group-item-warning {
    color: #856403;
    background-color: #ffeeb9;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #856403;
    background-color: #ffe8a0;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856403;
    border-color: #856403;
}

.list-group-item-danger {
    color: #85000c;
    background-color: #ffb8be;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #85000c;
    background-color: #ff9fa7;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #85000c;
    border-color: #85000c;
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}

.list-group-item-dark {
    color: #222634;
    background-color: #caccd4;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #222634;
    background-color: #bcbec9;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #222634;
    border-color: #222634;
}

.list-group-item-teal {
    color: #2e7055;
    background-color: #d0f4e5;
}

.list-group-item-teal.list-group-item-action:hover,
.list-group-item-teal.list-group-item-action:focus {
    color: #2e7055;
    background-color: #bbefda;
}

.list-group-item-teal.list-group-item-action.active {
    color: #fff;
    background-color: #2e7055;
    border-color: #2e7055;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

.modal-open {
    overflow: hidden;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
    pointer-events: none;
    z-index: 1045;
}

.modal.fade .modal-dialog {
    transition: transform 0.4s ease;
    transform: translate(0, -25%);
}

.modal.show .modal-dialog {
    transform: translate(0, 0);
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (10px * 2));
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    outline: 0;
    border: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-open .modal-backdrop.fade {
    opacity: 0;
}

.modal-open .modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #f3f3f3;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background: #F6F8FA;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 15px;
}


.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid #f3f3f3;
}

/*.modal-footer > :not(:first-child) {
        margin-left: .25rem;
    }
    .modal-footer > :not(:last-child) {
        margin-right: .25rem;
    }*/

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 30px auto;
    }

    .modal-dialog-centered {
        min-height: calc(100% - (30px * 2));
    }

    .modal-sm {
        max-width: 350px;
    }
}

@media (min-width: 992px) {
    .modal-big {
        max-width: 90%;
    }

    .modal-lg {
        max-width: 60%;
    }

    .modal-md {
        max-width: 550px;
    }
}



.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0px;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}

.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    flex-wrap: nowrap;

}

.carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    transition: transform 0.6s ease;
    backface-visibility: hidden;
    perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translateX(0);
}

@supports (transform-style: preserve-3d) {

    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        transform: translate3d(0, 0, 0);
    }
}

.carousel-item-next,
.active.carousel-item-right {
    transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {

    .carousel-item-next,
    .active.carousel-item-right {
        transform: translate3d(100%, 0, 0);
    }
}

.carousel-item-prev,
.active.carousel-item-left {
    transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {

    .carousel-item-prev,
    .active.carousel-item-left {
        transform: translate3d(-100%, 0, 0);
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators .active {
    background-color: #fff;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

.carousel-control-next {
    background-color: #8d8383bd;
}

.carousel-control-next {
    background-color: #8d8383bd;
}

.carousel-control-prev {
    background-color: #8d8383bd;
}

.carousel-control-prev {
    background-color: #8d8383bd;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #1991EB !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #1175c0 !important;
}

.bg-secondary {
    background-color: #dee2e6 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #c1c9d0 !important;
}

.bg-success {
    background-color: #08D419 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #06a313 !important;
}

.bg-info {
    background-color: #745af2 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #4c2bee !important;
}

.bg-warning {
    background-color: #FFC105 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #d19d00 !important;
}

.bg-danger {
    background-color: #FF0017 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #cc0012 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #424964 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #2e3345 !important;
}

.bg-teal {
    background-color: #58d8a3 !important;
}

a.bg-teal:hover,
a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
    background-color: #30cd8c !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #f3f3f3 !important;
}

.border-top {
    border-top: 1px solid #f3f3f3 !important;
}

.border-right {
    border-right: 1px solid #f3f3f3 !important;
}

.border-bottom {
    border-bottom: 1px solid #f3f3f3 !important;
}

.border-left {
    border-left: 1px solid #f3f3f3 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #1991EB !important;
}

.border-secondary {
    border-color: #dee2e6 !important;
}

.border-success {
    border-color: #08D419 !important;
}

.border-info {
    border-color: #745af2 !important;
}

.border-warning {
    border-color: #FFC105 !important;
}

.border-danger {
    border-color: #FF0017 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #424964 !important;
}

.border-teal {
    border-color: #58d8a3 !important;
}

.border-white {
    border-color: #ffffff !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        justify-content: space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    clip-path: none;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.template-demo>.btn,
.template-demo>.btn-toolbar,
.template-demo>.btn-group,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.template-demo>.btn,
.template-demo>.btn-toolbar,
.template-demo>.btn-group,
.template-demo .dropdown,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.btn-toolbar .btn-group+.btn-group,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #1991EB !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #1175c0 !important;
}

.text-secondary {
    /* color: #dee2e6 !important; */
    color: #525252 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #c1c9d0 !important;
}

.text-success {
    color: #08D419 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #06a313 !important;
}

.text-info {
    color: #745af2 !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #4c2bee !important;
}

.text-warning {
    color: #FFC105 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #d19d00 !important;
}

.text-danger {
    color: #FF0017 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #cc0012 !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #dae0e5 !important;
}

.text-dark {
    color: #424964 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #2e3345 !important;
}

.text-teal {
    color: #58d8a3 !important;
}

a.text-teal:hover,
a.text-teal:focus {
    color: #30cd8c !important;
}

.text-muted,
.preview-list .preview-item .preview-item-content p .content-category {
    color: #c2c2c2 !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {

    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: " (" attr(title) ")";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #fff !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #ddd !important;
    }

}

/*-------------------------------------------------------------------*/
/* === Template mixins === */
/* Animation Mixins */
@keyframes dropdownAnimation {
    from {
        opacity: 0;
        transform: translate3d(0, -30px, 0);
    }

    to {
        opacity: 1;
        transform: none;
        transform: translate3d(0, 0px, 0);
    }
}

.dropdownAnimation,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
    animation-name: dropdownAnimation;
    -webkit-animation-duration: 0.25s;
    -moz-animation-duration: 0.25s;
    -ms-animation-duration: 0.25s;
    -o-animation-duration: 0.25s;
    animation-duration: 0.25s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
}

.infinite-spin {
    animation-name: spin;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

/* Miscellaneous Mixins */
/*-------------------------------------------------------------------*/
/* === Core Styles === */
/* Reset Styles */
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.form-control,
.form-control:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.form-control {
    box-shadow: none;
}

.form-control:focus {
    outline: 0;
    box-shadow: none;
}

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
    text-shadow: none;
}

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file]>input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
    outline: 0;
}

input,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    outline-width: 0;
    outline-color: transparent;
    box-shadow: none;
    outline-style: none;
}

textarea {
    resize: none;
    overflow-x: hidden;
}

.btn,
.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
    outline: 0;
    outline-offset: 0;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: 0 !important;
    outline-offset: 0;
}

.dropdown-menu>li>a:active,
.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:visited {
    outline: 0 !important;
}

a:focus,
input:focus {
    border-color: transparent;
    outline: none;
}

/* Fonts */
.text-twitter {
    color: #1da1f2;
}

.text-facebook {
    color: #3b579d;
}

.text-google {
    color: #dc4a38;
}

.text-linkedin {
    color: #0177b4;
}

.text-pinterest {
    color: #cc2127;
}

.text-youtube {
    color: #e52d27;
}

.text-github {
    color: #333333;
}

.text-behance {
    color: #1769ff;
}

.text-dribbble {
    color: #ea4c89;
}

.text-reddit {
    color: #ff4500;
}

.bg-twitter {
    background: #1da1f2;
}

.bg-facebook {
    background: #3b579d;
}

.bg-google {
    background: #dc4a38;
}

.bg-linkedin {
    background: #0177b4;
}

.bg-pinterest {
    background: #cc2127;
}

.bg-youtube {
    background: #e52d27;
}

.bg-github {
    background: #333333;
}

.bg-behance {
    background: #1769ff;
}

.bg-dribbble {
    background: #ea4c89;
}

.bg-reddit {
    background: #ff4500;
}

/* Sidebar */
.sidebar {
    min-height: calc(100vh - 58px);
    background: #ffffff;
    font-family: "Poppins", sans-serif;
    padding: 0;
    width: 255px;
    z-index: 11;
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
}

.sidebar .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 50px;
}

.sidebar .nav .nav-item .collapse {
    z-index: 999;
}

.sidebar .nav .nav-item .collapse.show,
.sidebar .nav .nav-item .collapsing {
    background: #fafbfc;
}

.sidebar .nav .nav-item .collapse.show a.nav-link.active,
.sidebar .nav .nav-item .collapsing a.nav-link.active {
    background: #fafbfc;
}

.sidebar .nav .nav-item .nav-link {
    align-items: center;
    display: flex;
    padding: 1rem 1.875rem;
    white-space: nowrap;
    height: 50px;
    color: #4a4949;
}

.sidebar .nav .nav-item .nav-link[aria-expanded="true"] {
    background: #fafbfc;
}

.sidebar .nav .nav-item .nav-link .menu-icon {
    margin-right: 1.25rem;
    width: 16px;
    line-height: 1;
}

.rtl .sidebar .nav .nav-item .nav-link .menu-icon {
    margin-right: 0;
    margin-left: 1.25rem;
}

.sidebar .nav .nav-item .nav-link i {
    color: #404852;
    /*new*/
    font-size: 16px;
    padding-right: 5px;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow {
    margin-left: auto;
    margin-right: 0;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
    content: "\F35D";
    font-family: "Material Design Icons";
    font-size: 18px;
    line-height: 1;
    font-style: normal;
    vertical-align: middle;
    color: rgba(74, 73, 73, 0.5);
}

.sidebar .nav .nav-item .nav-link .menu-title {
    color: inherit;
    display: inline-block;
    font-size: 0.840rem;
    line-height: 1;
    vertical-align: middle;
}

.sidebar .nav .nav-item .nav-link .badge {
    margin-left: auto;
    border-radius: 0px;
}

.sidebar .nav .nav-item .nav-link:hover {
    color: #3d3c3c;
}

.sidebar .nav .nav-item.nav-profile {
    background: transparent;
}

.sidebar .nav .nav-item.nav-profile .nav-link {
    font-family: "Poppins", sans-serif;
    display: block;
    height: auto;
    padding: 0 1.625rem 2.25rem 1.188rem;
}

.sidebar .nav .nav-item.nav-profile .profile-image {
    position: relative;
    width: 70px;
    height: 70px;
    margin: auto;
}

.sidebar .nav .nav-item.nav-profile .profile-image img {
    width: 70px;
    height: 70px;
    border-radius: 100%;
}

.sidebar .nav .nav-item.nav-profile .profile-name {
    margin-top: 0.75rem;
    text-align: center;
}

.rtl .sidebar .nav .nav-item.nav-profile .profile-name {
    margin-left: auto;
    margin-right: 0.75rem;
}

.sidebar .nav .nav-item.nav-profile .profile-name .designation,
.sidebar .nav .nav-item.nav-profile .profile-name .name {
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
    line-height: 1.5;
    font-weight: 500;
}

.sidebar .nav .nav-item.nav-profile .profile-name .name {
    color: #404852;
}

.sidebar .nav .nav-item.nav-profile .profile-name .designation {
    color: #8d9498;
    font-size: 0.75rem;
}

.sidebar .nav .nav-item.nav-profile i {
    color: #ffffff;
    font-size: 1rem;
    margin-left: auto;
}

.sidebar .nav .nav-item .nav-link.active {
    color: #1991EB;
}

.sidebar .nav .nav-item .nav-link.active .menu-title,
.sidebar .nav .nav-item .nav-link.active i {
    color: inherit;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover:not(.nav-profile)>.nav-link {
    background: #fafbfc;
    color: #4a4949;
}

.sidebar .nav.sub-menu {
    margin-bottom: 0;
    /*padding: 0 0 0 3.25rem;*/
    padding: 0 0 0 2.7rem;
}

.sidebar .nav.sub-menu .nav-item .nav-link {
    color: #4a4949;
    padding: 0.75rem 1rem;
    font-size: inherit;
    line-height: 1;
    height: auto;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active {
    color: #1991EB;
    background: transparent;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active:before {
    background: #1991EB;
}

.sidebar .nav.sub-menu .nav-item:hover>.nav-link {
    background: initial;
    color: #000;
}

.sidebar .nav.sub-menu .nav-item:hover>.nav-link:before {
    background: #000;
}

.sidebar .nav .nav-item.purchase-button {
    text-align: center;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.sidebar .nav .nav-item.purchase-button .nav-link {
    background-color: #d209fa;
    /* *zoom: 1; */
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#FFD209FA', endColorstr='#FF4F81D4');
    background-image: -owg-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: -webkit-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: -moz-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: -o-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    color: #ffffff;
    text-align: center;
    display: block;
    font-weight: 500;
    border-radius: 0px;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    font-size: 14px;
}

.sidebar .nav .nav-item.purchase-button .nav-link:hover {
    background-color: #d209fa;
    /* *zoom: 1; */
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#FFD209FA', endColorstr='#FF4F81D4');
    background-image: -owg-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: -webkit-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: -moz-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: -o-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
}

.sidebar .nav .nav-item.purchase-button:hover .nav-link {
    background-color: #d209fa !important;
    color: #ffffff !important;
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
    .sidebar-offcanvas {
        position: fixed;
        max-height: calc(100vh - 58px);
        top: 58px;
        bottom: 0;
        overflow: auto;
        right: -255px;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
    }

    .sidebar-offcanvas.active {
        right: 0;
    }
}

/* Navbar */
// .navbar {
//     font-family: "Poppins", sans-serif;
//     background: linear-gradient(88deg, #318be3, #04befe);
//     transition: background 0.25s ease;
//     -webkit-transition: background 0.25s ease;
//     -moz-transition: background 0.25s ease;
//     -ms-transition: background 0.25s ease;
// }

//     .navbar .navbar-brand-wrapper {
//         transition: width 0.25s ease, background 0.25s ease;
//         -webkit-transition: width 0.25s ease, background 0.25s ease;
//         -moz-transition: width 0.25s ease, background 0.25s ease;
//         -ms-transition: width 0.25s ease, background 0.25s ease;
//         background: #ffffff;
//         width: 255px;
//         height: 58px;
//         border-bottom: 1px solid #eeeff0;
//     }

//         .navbar .navbar-brand-wrapper .navbar-brand {
//             color: #ffffff;
//             font-size: 1.5rem;
//             /*line-height: 48px;*/
//             margin-right: 0;
//             padding: 0.25rem 0;
//         }

//             .navbar .navbar-brand-wrapper .navbar-brand:active, .navbar .navbar-brand-wrapper .navbar-brand:focus, .navbar .navbar-brand-wrapper .navbar-brand:hover {
//                 color: #424546;
//             }

//             .navbar .navbar-brand-wrapper .navbar-brand img {
//                 width: calc(255px - 130px);
//                 max-width: 100%;
//                 height: 28px;
//                 margin: auto;
//                 vertical-align: middle;
//             }

//         .navbar .navbar-brand-wrapper .brand-logo-mini {
//             display: none;
//         }

//             .navbar .navbar-brand-wrapper .brand-logo-mini img {
//                 width: calc(70px - 50px);
//                 max-width: 100%;
//                 height: 28px;
//                 margin: auto;
//             }

//     .navbar .navbar-menu-wrapper {
//         transition: width 0.25s ease;
//         -webkit-transition: width 0.25s ease;
//         -moz-transition: width 0.25s ease;
//         -ms-transition: width 0.25s ease;
//         color: #ffffff;
//         padding-left: 15px;
//         padding-right: 15px;
//         width: calc(100% - 255px);
//         height: 58px;
//     }

// @media (max-width: 991px) {
//     .navbar .navbar-menu-wrapper {
//         width: auto;
//     }
// }

// .navbar .navbar-menu-wrapper .navbar-toggler {
//     border: 0;
//     color: inherit;
// }

// @media (max-width: 991px) {
//     .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
//         display: none;
//     }
// }

// .navbar .navbar-menu-wrapper .navbar-text {
//     font-size: 0.875rem;
// }

// .navbar .navbar-menu-wrapper .navbar-nav {
//     flex-direction: row;
//     align-items: center;
// }

//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item {
//         margin-left: 0.7rem;
//         margin-right: 0.7rem;
//     }

//         .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
//             color: inherit;
//             font-size: 0.9375rem;
//             vertical-align: middle;
//         }

// @media (max-width: 767px) {
//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
//         margin-left: 0.5rem;
//         margin-right: 0.5rem;
//     }
// }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
//     font-size: 1.25rem;
//     vertical-align: middle;
// }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link.nav-btn .btn {
//     background: rgba(255, 255, 255, 0.1);
//     padding: 0.75rem 1rem;
//     color: #ffffff;
// }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link.nav-btn:after {
//     display: none;
// }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator {
//     position: relative;
//     text-align: center;
// }

//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator i {
//         font-size: 21px;
//         margin-right: 0;
//         vertical-align: middle;
//     }

//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator .count {
//         position: absolute;
//         left: 50%;
//         width: 1rem;
//         height: 1rem;
//         border-radius: 100%;
//         background: #FF0017;
//         font-size: 75%;
//         /*top: 5px;*/
//         top: 0px;
//         font-weight: 600;
//         line-height: 1rem;
//         border: none;
//         text-align: center;
//     }

//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator:after {
//         display: none;
//     }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown i {
//     margin-right: 0.5rem;
//     vertical-align: middle;
// }

// .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown i {
//     margin-left: 0.5rem;
//     margin-right: 0;
// }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
//     font-size: inherit;
//     margin-top: 0;
//     position: absolute;
//     top: 48px;
//     right: -1px;
//     left: auto;
//     min-width: 100%;
//     -webkit-box-shadow: 0 0 13px -3px rgba(0, 0, 0, 0.1);
//     -moz-box-shadow: 0 0 13px -3px rgba(0, 0, 0, 0.1);
//     box-shadow: 0 0 13px -3px rgba(0, 0, 0, 0.1);
//     border-radius: 0px;
//     border: 1px solid rgba(182, 182, 182, 0.1);
//     padding: 0;
//     border-radius: 0;
//     overflow: hidden;
// }

// .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
//     right: auto;
//     left: 0;
// }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown.dropdown-left {
//     left: 0;
//     right: auto;
// }

// @media (max-width: 991px) {
//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
//         left: 0;
//         right:auto
//     }
// }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .badge {
//     margin-left: 2.5rem;
// }

// .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .badge {
//     margin-left: 0;
//     margin-right: 1.25rem;
// }

// @media (max-width: 991px) {
//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .badge {
//         margin-right: 0.5rem;
//     }

//     .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .badge {
//         margin-left: 0.5rem;
//         margin-right: 0;
//     }
// }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item {
//     /*border-bottom: 1px solid #b2b8bc;*/
//     margin-bottom: 0;
//     /*padding: 11px 30px;*/
//     padding: 11px 20px;
// }

//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item i {
//         font-size: 17px;
//     }

//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .ellipsis {
//         max-width: 200px;
//         overflow: hidden;
//         text-overflow: ellipsis;
//     }

//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .preview-icon {
//         width: 40px;
//         height: 40px;
//     }

//     .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .small-text {
//         font-size: 0.75rem;
//     }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-divider {
//     margin: 0;
// }

// .navbar .navbar-menu-wrapper .navbar-nav .nav-item.color-setting i {
//     font-size: 25px;
//     vertical-align: text-top;
// }

// @media (min-width: 992px) {
//     .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
//         margin-left: auto;
//     }

//     .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
//         margin-left: 0;
//         margin-right: auto;
//     }
// }

// .navbar .navbar-menu-wrapper .navbar-nav.header-links {
//     height: 58px;
//     /*padding-left: 2%;*/
// }

//     .navbar .navbar-menu-wrapper .navbar-nav.header-links .nav-item {
//         margin: 0;
//     }

//         .navbar .navbar-menu-wrapper .navbar-nav.header-links .nav-item .nav-link {
//             height: 58px;
//             font-size: inherit;
//             padding: 16px 20px;
//             display: -webkit-flex;
//             display: flex;
//             line-height: 29px;
//             vertical-align: middle;
//         }

//             .navbar .navbar-menu-wrapper .navbar-nav.header-links .nav-item .nav-link i {
//                 margin-right: 10px;
//                 /*font-size: inherit;*/
//                 /*new*/
//                 vertical-align: middle;
//                 line-height: 28px;
//                 font-size: 16px;
//             }

// .rtl .navbar .navbar-menu-wrapper .navbar-nav.header-links .nav-item .nav-link i {
//     margin-right: 0;
//     margin-left: 10px;
// }

// .navbar .navbar-menu-wrapper .navbar-nav.header-links .nav-item a.active, .navbar .navbar-menu-wrapper .navbar-nav.header-links .nav-item a:hover {
//     background: rgba(255, 255, 255, 0.3);
// }

// .navbar.fixed-top + .page-body-wrapper {
//     padding-top: 58px;
// }

// @media (max-width: 991px) {
//     .navbar {
//         flex-direction: row;
//     }

//         .navbar .navbar-brand-wrapper {
//             /*width: 75px;*/
//             width: 100%;
//         }

//             .navbar .navbar-brand-wrapper .brand-logo {
//                 display: none;
//             }

//             .navbar .navbar-brand-wrapper .brand-logo-mini {
//                 display: inline-block;
//             }

//     .navbar-collapse {
//         display: flex;
//         margin-top: 0.5rem;
//     }
// }

/* Typography */
body {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    font-weight: initial;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}

p {
    font-size: 0.875rem;
}

.h1,
h1 {
    font-size: 2.19rem;
}

.h2,
h2 {
    font-size: 1.88rem;
}

.h3,
h3 {
    font-size: 1.56rem;
}

.h4,
h4 {
    font-size: 1.13rem;
}

.h5,
h5 {
    font-size: 1rem;
}

.h6,
h6 {
    font-size: 0.9375rem;
}

p {
    font-size: 0.88rem;
}

.display-1 {
    font-size: 3.75rem;
}

@media (max-width: 991px) {
    .display-1 {
        font-size: 3rem;
    }
}

.display-2 {
    font-size: 3.125rem;
}

@media (max-width: 991px) {
    .display-2 {
        font-size: 2.5rem;
    }
}

.display-3 {
    font-size: 2.5rem;
}

@media (max-width: 991px) {
    .display-3 {
        font-size: 2rem;
    }
}

.display-4 {
    font-size: 1.875rem;
}

@media (max-width: 991px) {
    .display-4 {
        font-size: 1.5rem;
    }
}

.display-5 {
    font-size: 1.25rem;
}

@media (max-width: 991px) {
    .display-5 {
        font-size: 1rem;
    }
}

.blockquote {
    padding: 1.25rem;
    border: 1px solid #f3f3f3;
}

address p {
    margin-bottom: 0;
}

.blockquote-primary {
    border-color: #1991EB;
}

.blockquote-primary .blockquote-footer {
    color: #1991EB;
}

.blockquote-secondary {
    border-color: #dee2e6;
}

.blockquote-secondary .blockquote-footer {
    color: #dee2e6;
}

.blockquote-success {
    border-color: #08D419;
}

.blockquote-success .blockquote-footer {
    color: #08D419;
}

.blockquote-info {
    border-color: #745af2;
}

.blockquote-info .blockquote-footer {
    color: #745af2;
}

.blockquote-warning {
    border-color: #FFC105;
}

.blockquote-warning .blockquote-footer {
    color: #FFC105;
}

.blockquote-danger {
    border-color: #FF0017;
}

.blockquote-danger .blockquote-footer {
    color: #FF0017;
}

.blockquote-light {
    border-color: #f8f9fa;
}

.blockquote-light .blockquote-footer {
    color: #f8f9fa;
}

.blockquote-dark {
    border-color: #424964;
}

.blockquote-dark .blockquote-footer {
    color: #424964;
}

.blockquote-teal {
    border-color: #58d8a3;
}

.blockquote-teal .blockquote-footer {
    color: #58d8a3;
}

.page-title {
    color: #000;
    margin: 0.38rem 0 0.75rem;
}

.card-title {
    margin-bottom: 0.125rem;
    text-transform: capitalize;
    /*new*/
    /*border-bottom: 1px solid #f3f3f3;
    padding-bottom: 5px;*/
}

form .card-title,
.form .card-title {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 15px;
}

.card>.card-title {
    font-size: 15px;
    border-bottom: 1px solid #f3f3f3;
    padding: 15px;
    margin-bottom: 0px;
    /*new temp*/
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rtl .card-title {
    text-align: right;
}

.card-subtitle {
    font-family: "Poppins", sans-serif;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}

.card-description {
    margin-bottom: 0.9375rem;
    font-family: "Poppins", sans-serif;
}

.rtl .card-description {
    text-align: right;
}

.font-weight-normal {
    font-weight: 400;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-bold {
    font-weight: 600;
}

table tr.font-weight-bold {
    background-color: #fff8e9 !important;
}

.text-small {
    font-size: 0.875rem !important;
}

.icon-lg {
    font-size: 2.5rem !important;
}

.icon-md {
    font-size: 1.875rem !important;
}



.icon-sm {
    font-size: 1rem !important;
}

/* Miscellanoeous */
/*body ,html{*/
body {
    overflow-x: hidden;
    padding-right: 0 !important;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
    overflow: auto !important;
}

.page-body-wrapper {
    min-height: calc(100vh - 58px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
}

.page-body-wrapper.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
}

.main-panel {
    transition: width 0.25s ease, margin 0.25s ease;
    width: calc(100% - 255px);
    min-height: calc(100vh - 58px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

@media (max-width: 991px) {
    .main-panel {
        margin-left: 0;
        width: 100%;
    }
}

.form-controlSearch {
    display: block;
    width: 100%;
    padding: 8px 12px;
    font-size: 1rem;
    line-height: 16px;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cardproduct .card-bodyproduct {
    padding: 0.5rem;
}

.content-wrapperproduct {
    background: #F6F8FA;
    padding: 0.5rem 0.7rem;
    width: 100%;
    -webkit-flex-grow: 1;
    flex-grow: 1;
}

.content-wrapper {
    background: #F6F8FA;
    padding: 1.5rem 1.7rem;
    width: 100%;
    -webkit-flex-grow: 1;
    flex-grow: 1;
}

@media(max-width:400px) {
    .content-wrapper {
        padding: 8px;
    }

    .card .card-body {
        padding: 10px 8px !important;
    }
}

.container-scroller {
    overflow: hidden;
}

.scroll-container {
    position: relative;
}

.scroll-container.horizontally {
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
}

.scroll-container.vertically {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
}

pre {
    padding: 15px;
    font-size: 14px;
}

/* Footer */
.footer {
    background: rgba(228, 228, 228, 0.46);
    padding: 20px 1rem;
    transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    border-top: 1px solid #f3f3f3;
    font-size: calc(0.875rem - 0.05rem);
    font-family: "Poppins", sans-serif;
}

.footer a {
    color: #08D419;
    font-size: inherit;
}

@media (max-width: 991px) {
    .footer {
        margin-left: 0;
        width: 100%;
    }
}

/* Utilities */
.grid-margin,
.purchace-popup>div {
    margin-bottom: 20px;
}

.grid-margin-sm {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .grid-margin-sm {
        margin-bottom: 20px;
    }
}

@media (min-width: 576px) {
    .grid-margin-sm-0 {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .grid-margin-md-0 {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .grid-margin-lg-0 {
        margin-bottom: 0;
    }
}

@media (min-width: 1200px) {
    .grid-margin-xl-0 {
        margin-bottom: 0;
    }
}

.img-lg {
    width: 92px;
    height: 92px;
}

.img-md {
    width: 60px;
    height: 60px;
}

.img-sm {
    width: 43px;
    height: 43px;
}

.img-xs {
    width: 37px;
    height: 37px;
}

.img-ss {
    width: 26px;
    height: 26px;
}

.stretch-card {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-justify-content: stretch;
    justify-content: stretch;
}

.stretch-card>.card {
    width: 100%;
    min-width: 100%;
}

@media (min-width: 576px) {
    .border-right-sm {
        border-right: 1px solid #f3f3f3;
    }
}

@media (min-width: 768px) {
    .border-right-md {
        border-right: 1px solid #f3f3f3;
    }
}

@media (min-width: 992px) {
    .border-right-lg {
        border-right: 1px solid #f3f3f3;
    }
}

@media (min-width: 576px) {
    .border-left-sm {
        border-left: 1px solid #f3f3f3;
    }
}

@media (min-width: 768px) {
    .border-left-md {
        border-left: 1px solid #f3f3f3;
    }
}

@media (min-width: 992px) {
    .border-left-lg {
        border-left: 1px solid #f3f3f3;
    }
}

.text-gray,
.card-subtitle {
    color: #8c8c8c;
}

.text-black {
    color: #000;
}

.flex-grow {
    flex-grow: 1;
}

.ellipsis {
    max-width: 95%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.no-wrap {
    white-space: nowrap;
}

.bg-transparent {
    background: transparent;
}

/* Demo Styles */
.template-demo>.btn-group .btn {
    margin: 0 !important;
}

.template-demo>h1,
.template-demo>h3,
.template-demo>h4,
.template-demo>h5,
.template-demo>h6,
.template-demo>h2 {
    border-top: 1px solid #f3f3f3;
    padding: 0.5rem 0 0;
}

.template-demo .dropdown {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.template-demo nav .breadcrumb {
    margin-bottom: 1.375rem;
}

.template-demo nav:last-child .breadcrumb {
    margin-bottom: 0;
}

.purchace-popup>div>span {
    background: rgba(228, 228, 228, 0.46);
    padding: 15px 20px;
    border-radius: 0px;
}

.purchace-popup>div>span .btn {
    margin-right: 20px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0px;
}

.purchace-popup>div>span .btn.download-button {
    background: rgba(228, 228, 228, 0.2);
    color: #b1b1b1;
    border: 2px solid #cbcbcb;
}

.purchace-popup>div>span .btn.purchase-button {
    background-color: #d209fa;
    /* *zoom: 1; */
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#FFD209FA', endColorstr='#FF4F81D4');
    background-image: -owg-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: -webkit-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: -moz-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: -o-linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    background-image: linear-gradient(to left, #d209fa 1%, #4f81d4 100%);
    color: #ffffff;
    border: none;
    line-height: 1;
    vertical-align: middle;
}

.purchace-popup>div>span p {
    margin-bottom: auto;
    margin-top: auto;
    color: #7e7e7e;
    font-weight: 400;
    vertical-align: middle;
    line-height: 1;
}

.purchace-popup>div>span i {
    vertical-align: middle;
    line-height: 1;
    margin: auto 0;
    color: #b1b1b1;
}

/* Dashboard */
.card-statistics .highlight-icon {
    font-size: 3.125rem;
}

.card-statistics .highlight-icon-small {
    font-size: 2.5rem;
}

.card-statistics p {
    margin-bottom: 0;
}

/*-------------------------------------------------------------------*/
/* === Components === */
/* Badges */
.badge {
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: initial;
    line-height: 1;
    padding: 0.25rem 0.375rem;
    font-family: "Poppins", sans-serif;
}

.badge.badge-pill {
    border-radius: 10rem;
}

.badge.badge-fw {
    min-width: 70px;
}

/*Badge variations*/
.badge-primary {
    border: 1px solid #1991EB;
    color: #ffffff;
}

.badge-secondary {
    border: 1px solid #dee2e6;
    color: #ffffff;
}

.badge-success,
.preview-list .preview-item .preview-thumbnail .badge.badge-online {
    border: 1px solid #08D419;
    color: #ffffff;
}

.badge-info,
.preview-list .preview-item .preview-thumbnail .badge.badge-offline {
    border: 1px solid #745af2;
    color: #ffffff;
}

.badge-warning,
.preview-list .preview-item .preview-thumbnail .badge.badge-busy {
    border: 1px solid #FFC105;
    color: #ffffff;
}

.badge-danger {
    border: 1px solid #FF0017;
    color: #ffffff;
}

.badge-light {
    border: 1px solid #f8f9fa;
    color: #ffffff;
}

.badge-dark {
    border: 1px solid #424964;
    color: #ffffff;
}

.badge-teal {
    border: 1px solid #58d8a3;
    color: #ffffff;
}

/*Badge outlined variations*/
.badge-outline-primary {
    color: #1991EB;
    border: 1px solid #1991EB;
}

.badge-outline-secondary {
    color: #dee2e6;
    border: 1px solid #dee2e6;
}

.badge-outline-success {
    color: #08D419;
    border: 1px solid #08D419;
}

.badge-outline-info {
    color: #745af2;
    border: 1px solid #745af2;
}

.badge-outline-warning {
    color: #FFC105;
    border: 1px solid #FFC105;
}

.badge-outline-danger {
    color: #FF0017;
    border: 1px solid #FF0017;
}

.badge-outline-light {
    color: #f8f9fa;
    border: 1px solid #f8f9fa;
}

.badge-outline-dark {
    color: #424964;
    border: 1px solid #424964;
}

.badge-outline-teal {
    color: #58d8a3;
    border: 1px solid #58d8a3;
}

/* Buttons */
.btn {
    font-size: 0.875rem;
    line-height: 1;
    font-family: "Poppins", sans-serif;
}

.btn i {
    /*margin-right: 0.3125rem;*/
}

.btn .btn-label:before {
    font-size: 1rem;
    line-height: 5px;
    vertical-align: middle;
}

.btn .btn-label.btn-label-left {
    margin-right: 5px;
}

.btn .btn-label.btn-label-right {
    margin-left: 5px;
}

.btn.btn-rounded {
    border-radius: 50px;
}

.btn.btn-fw {
    min-width: 120px;
}

.btn.icon-btn i {
    margin-right: 0;
}

.btn.social-btn {
    padding: 18px;
}

.btn.social-btn i {
    margin-right: 0;
    font-size: 1rem;
}

.btn.btn-sm,
.btn-group-sm>.btn {
    font-size: 13px;
}

.btn.btn-lg,
.btn-group-lg>.btn {
    font-size: 0.875rem;
}

.btn.btn-xs {
    padding: 0.5rem 0.75rem;
    font-size: 0.625rem;
}

.btn.btn-primary,
.btn.btn-danger,
.btn.btn-info,
.btn.btn-teal,
.btn.btn-warning {
    color: #ffffff !important;
}

.btn.btn-light {
    color: #000;
    border-color: #d9d9d9;
}

.btn.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
}

.btn.btn-outline-light:hover {
    color: #ffffff;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn.btn-outline-light:focus,
.btn.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn.btn-outline-light.disabled,
.btn.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):active,
.btn.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn.btn-outline-secondary {
    color: rgba(0, 0, 0, 0.5);
}

.btn.btn-inverse-secondary {
    background-color: rgba(222, 226, 230, 0.5);
    color: rgba(0, 0, 0, 0.5);
}

.btn.btn-inverse-secondary:hover {
    color: rgba(0, 0, 0, 0.5);
}

.btn.btn-inverse-light {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.5);
    border-color: #d9d9d9;
}

.btn.btn-inverse-light:hover {
    color: rgba(0, 0, 0, 0.5);
    border-color: #d9d9d9;
}

.btn-group {
    /*border: 1px solid #f3f3f3;*/
    border-radius: 0px;
}

.btn-group .btn {
    border-top: none;
    border-bottom: none;
    border-left: none;
}

.btn-group .btn:last-child {
    border-right: none;
}

.btn-group .btn.btn-primary {
    border-color: #1489e1;
}

.btn-group .btn.btn-secondary {
    border-color: #d5dadf;
}

.btn-group .btn.btn-info {
    border-color: #684cf1;
}

.btn-group .btn.btn-warning {
    border-color: #f5b800;
}

.btn-group .btn.btn-success {
    border-color: #07c517;
}

.btn-group .btn.btn-danger {
    border-color: #f00016;
}

.btn-group .btn.btn-dark {
    border-color: #3c425b;
}

.btn-group .btn.btn-light {
    border-color: #eff1f4;
}

/*social buttons*/
.btn-twitter {
    background: #1da1f2;
    color: #ffffff;
}

.btn-twitter:hover {
    background: #0c85d0;
}

.btn-twitter.btn-link {
    background: none;
    color: #1da1f2;
}

.btn-twitter.btn-link:hover {
    color: #0c85d0;
}

.btn-facebook {
    background: #3b579d;
    color: #ffffff;
}

.btn-facebook:hover {
    background: #2d4278;
}

.btn-facebook.btn-link {
    background: none;
    color: #3b579d;
}

.btn-facebook.btn-link:hover {
    color: #2d4278;
}

.btn-google {
    background: #dc4a38;
    color: #ffffff;
}

.btn-google:hover {
    background: #bf3322;
}

.btn-google.btn-link {
    background: none;
    color: #dc4a38;
}

.btn-google.btn-link:hover {
    color: #bf3322;
}

.btn-linkedin {
    background: #0177b4;
    color: #ffffff;
}

.btn-linkedin:hover {
    background: #015581;
}

.btn-linkedin.btn-link {
    background: none;
    color: #0177b4;
}

.btn-linkedin.btn-link:hover {
    color: #015581;
}

.btn-pinterest {
    background: #cc2127;
    color: #ffffff;
}

.btn-pinterest:hover {
    background: #a01a1f;
}

.btn-pinterest.btn-link {
    background: none;
    color: #cc2127;
}

.btn-pinterest.btn-link:hover {
    color: #a01a1f;
}

.btn-youtube {
    background: #e52d27;
    color: #ffffff;
}

.btn-youtube:hover {
    background: #c21d17;
}

.btn-youtube.btn-link {
    background: none;
    color: #e52d27;
}

.btn-youtube.btn-link:hover {
    color: #c21d17;
}

.btn-github {
    background: #333333;
    color: #ffffff;
}

.btn-github:hover {
    background: #1a1a1a;
}

.btn-github.btn-link {
    background: none;
    color: #333333;
}

.btn-github.btn-link:hover {
    color: #1a1a1a;
}

.btn-behance {
    background: #1769ff;
    color: #ffffff;
}

.btn-behance:hover {
    background: #0050e3;
}

.btn-behance.btn-link {
    background: none;
    color: #1769ff;
}

.btn-behance.btn-link:hover {
    color: #0050e3;
}

.btn-dribbble {
    background: #ea4c89;
    color: #ffffff;
}

.btn-dribbble:hover {
    background: #e51e6b;
}

.btn-dribbble.btn-link {
    background: none;
    color: #ea4c89;
}

.btn-dribbble.btn-link:hover {
    color: #e51e6b;
}

.btn-reddit {
    background: #ff4500;
    color: #ffffff;
}

.btn-reddit:hover {
    background: #cc3700;
}

.btn-reddit.btn-link {
    background: none;
    color: #ff4500;
}

.btn-reddit.btn-link:hover {
    color: #cc3700;
}

/* inverse buttons */
.btn-inverse-primary {
    color: #1991EB;
    background-color: rgba(25, 145, 235, 0.2);
    background-image: none;
    border-color: rgba(25, 145, 235, 0);
}

.btn-inverse-primary:hover {
    color: #ffffff;
    background-color: #1991EB;
    border-color: #1991EB;
}

.btn-inverse-primary.focus,
.btn-inverse-primary:focus {
    box-shadow: 0 0 0 3px rgba(25, 145, 235, 0.5);
}

.btn-inverse-primary.disabled,
.btn-inverse-primary:disabled {
    color: #1991EB;
    background-color: transparent;
}

.btn-inverse-primary.active,
.btn-inverse-primary:active,
.show>.btn-inverse-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #1991EB;
    border-color: #1991EB;
}

.btn-inverse-secondary {
    color: #dee2e6;
    background-color: rgba(222, 226, 230, 0.2);
    background-image: none;
    border-color: rgba(222, 226, 230, 0);
}

.btn-inverse-secondary:hover {
    color: #ffffff;
    background-color: #dee2e6;
    border-color: #dee2e6;
}

.btn-inverse-secondary.focus,
.btn-inverse-secondary:focus {
    box-shadow: 0 0 0 3px rgba(222, 226, 230, 0.5);
}

.btn-inverse-secondary.disabled,
.btn-inverse-secondary:disabled {
    color: #dee2e6;
    background-color: transparent;
}

.btn-inverse-secondary.active,
.btn-inverse-secondary:active,
.show>.btn-inverse-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #dee2e6;
    border-color: #dee2e6;
}

.btn-inverse-success {
    color: #08D419;
    background-color: rgba(8, 212, 25, 0.2);
    background-image: none;
    border-color: rgba(8, 212, 25, 0);
}

.btn-inverse-success:hover {
    color: #ffffff;
    background-color: #08D419;
    border-color: #08D419;
}

.btn-inverse-success.focus,
.btn-inverse-success:focus {
    box-shadow: 0 0 0 3px rgba(8, 212, 25, 0.5);
}

.btn-inverse-success.disabled,
.btn-inverse-success:disabled {
    color: #08D419;
    background-color: transparent;
}

.btn-inverse-success.active,
.btn-inverse-success:active,
.show>.btn-inverse-success.dropdown-toggle {
    color: #ffffff;
    background-color: #08D419;
    border-color: #08D419;
}

.btn-inverse-info {
    color: #745af2;
    background-color: rgba(116, 90, 242, 0.2);
    background-image: none;
    border-color: rgba(116, 90, 242, 0);
}

.btn-inverse-info:hover {
    color: #ffffff;
    background-color: #745af2;
    border-color: #745af2;
}

.btn-inverse-info.focus,
.btn-inverse-info:focus {
    box-shadow: 0 0 0 3px rgba(116, 90, 242, 0.5);
}

.btn-inverse-info.disabled,
.btn-inverse-info:disabled {
    color: #745af2;
    background-color: transparent;
}

.btn-inverse-info.active,
.btn-inverse-info:active,
.show>.btn-inverse-info.dropdown-toggle {
    color: #ffffff;
    background-color: #745af2;
    border-color: #745af2;
}

.btn-inverse-warning {
    color: #FFC105;
    background-color: rgba(255, 193, 5, 0.2);
    background-image: none;
    border-color: rgba(255, 193, 5, 0);
}

.btn-inverse-warning:hover {
    color: #ffffff;
    background-color: #FFC105;
    border-color: #FFC105;
}

.btn-inverse-warning.focus,
.btn-inverse-warning:focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 5, 0.5);
}

.btn-inverse-warning.disabled,
.btn-inverse-warning:disabled {
    color: #FFC105;
    background-color: transparent;
}

.btn-inverse-warning.active,
.btn-inverse-warning:active,
.show>.btn-inverse-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #FFC105;
    border-color: #FFC105;
}

.btn-inverse-danger {
    color: #FF0017;
    background-color: rgba(255, 0, 23, 0.2);
    background-image: none;
    border-color: rgba(255, 0, 23, 0);
}

.btn-inverse-danger:hover {
    color: #ffffff;
    background-color: #FF0017;
    border-color: #FF0017;
}

.btn-inverse-danger.focus,
.btn-inverse-danger:focus {
    box-shadow: 0 0 0 3px rgba(255, 0, 23, 0.5);
}

.btn-inverse-danger.disabled,
.btn-inverse-danger:disabled {
    color: #FF0017;
    background-color: transparent;
}

.btn-inverse-danger.active,
.btn-inverse-danger:active,
.show>.btn-inverse-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #FF0017;
    border-color: #FF0017;
}

.btn-inverse-light {
    color: #f8f9fa;
    background-color: rgba(248, 249, 250, 0.2);
    background-image: none;
    border-color: rgba(248, 249, 250, 0);
}

.btn-inverse-light:hover {
    color: #ffffff;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-inverse-light.focus,
.btn-inverse-light:focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.btn-inverse-light.disabled,
.btn-inverse-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-inverse-light.active,
.btn-inverse-light:active,
.show>.btn-inverse-light.dropdown-toggle {
    color: #ffffff;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-inverse-dark {
    color: #424964;
    background-color: rgba(66, 73, 100, 0.2);
    background-image: none;
    border-color: rgba(66, 73, 100, 0);
}

.btn-inverse-dark:hover {
    color: #ffffff;
    background-color: #424964;
    border-color: #424964;
}

.btn-inverse-dark.focus,
.btn-inverse-dark:focus {
    box-shadow: 0 0 0 3px rgba(66, 73, 100, 0.5);
}

.btn-inverse-dark.disabled,
.btn-inverse-dark:disabled {
    color: #424964;
    background-color: transparent;
}

.btn-inverse-dark.active,
.btn-inverse-dark:active,
.show>.btn-inverse-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #424964;
    border-color: #424964;
}

.btn-inverse-teal {
    color: #58d8a3;
    background-color: rgba(88, 216, 163, 0.2);
    background-image: none;
    border-color: rgba(88, 216, 163, 0);
}

.btn-inverse-teal:hover {
    color: #ffffff;
    background-color: #58d8a3;
    border-color: #58d8a3;
}

.btn-inverse-teal.focus,
.btn-inverse-teal:focus {
    box-shadow: 0 0 0 3px rgba(88, 216, 163, 0.5);
}

.btn-inverse-teal.disabled,
.btn-inverse-teal:disabled {
    color: #58d8a3;
    background-color: transparent;
}

.btn-inverse-teal.active,
.btn-inverse-teal:active,
.show>.btn-inverse-teal.dropdown-toggle {
    color: #ffffff;
    background-color: #58d8a3;
    border-color: #58d8a3;
}

/* Cards */
.card {
    border: 0;
}

.card .card-body {
    padding: 1.5rem;

}

.card.card-outline-success {
    border: 1px solid #08D419;
}

.card.card-outline-primary {
    border: 1px solid #1991EB;
}

.card.card-outline-warning {
    border: 1px solid #FFC105;
}

.card.card-outline-danger {
    border: 1px solid #FF0017;
}

.card.card-rounded {
    border-radius: 5px;
}

.card.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
}

.card.card-circle-progress {
    color: #ffffff;
    text-align: center;
}

.card-inverse-primary {
    background: rgba(25, 145, 235, 0.2);
    border: 1px solid #1785d8;
    color: #136eb3;
}

.card-inverse-secondary {
    background: rgba(222, 226, 230, 0.2);
    border: 1px solid #ccd0d4;
    color: #a9acaf;
}

.card-inverse-success {
    background: rgba(8, 212, 25, 0.2);
    border: 1px solid #07c317;
    color: #06a113;
}

.card-inverse-info {
    background: rgba(116, 90, 242, 0.2);
    border: 1px solid #6b53df;
    color: #5844b8;
}

.card-inverse-warning {
    background: rgba(255, 193, 5, 0.2);
    border: 1px solid #ebb205;
    color: #c29304;
}

.card-inverse-danger {
    background: rgba(255, 0, 23, 0.2);
    border: 1px solid #eb0015;
    color: #c20011;
}

.card-inverse-light {
    background: rgba(248, 249, 250, 0.2);
    border: 1px solid #e4e5e6;
    color: #bcbdbe;
}

.card-inverse-dark {
    background: rgba(66, 73, 100, 0.2);
    border: 1px solid #3d435c;
    color: #32374c;
}

.card-inverse-teal {
    background: rgba(88, 216, 163, 0.2);
    border: 1px solid #51c796;
    color: #43a47c;
}

/* Checkboxes and Radios */
.form-check,
.form-radio {
    position: relative;
    display: block;
    margin-top: 15px;
    margin-bottom: 10px;
}

.form-check .form-check-label,
.form-radio .form-check-label {
    display: block;
    padding-left: 30px;
    line-height: 1.5;
}

.rtl .form-check .form-check-label,
.rtl .form-radio .form-check-label {
    padding-left: 0;
    padding-right: 30px;
}

.form-check .form-check-label input,
.form-radio .form-check-label input {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px\9;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    margin-top: 0;
}

.rtl .form-check .form-check-label input,
.rtl .form-radio .form-check-label input {
    left: auto;
    right: 0;
}

.form-check .form-check-label {
    font-size: 0.875rem;
    line-height: 1.5;
    padding-left: 10px;
}

.form-check .form-check-label input:checked+.input-helper:before {
    background-color: #ffffff;
}

.form-check .form-check-label input:checked+.input-helper:after {
    width: 18px;
    opacity: 1;
    line-height: 18px;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.form-check .form-check-label input:disabled+.input-helper:before {
    border-color: #f3f3f3;
}

.form-check .form-check-label input:disabled:checked+.input-helper:after {
    color: #f3f3f3;
}

.form-check .form-check-label .input-helper:before {
    position: absolute;
    content: "";
    top: 2px;
    width: 18px;
    height: 18px;
    border-radius: 0px;
    left: 0;
    border: 2px solid #f3f3f3;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    transition-duration: 0s;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
}

.rtl .form-check .form-check-label .input-helper:before {
    left: auto;
    right: 0;
}

.form-check .form-check-label .input-helper:after {
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    transition-duration: 0s;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    font-family: Material Design Icons;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    content: '\F12C';
    position: absolute;
    font-size: .9375rem;
    font-weight: bold;
    left: 0;
    top: 2px;
    color: #745af2;
}

.rtl .form-check .form-check-label .input-helper:after {
    left: auto;
    right: 0;
}

.form-check.form-check-flat label input:checked+.input-helper:before {
    background-color: #08D419;
    border: none;
}

.form-check.form-check-flat label input:disabled+.input-helper:after {
    color: #ffffff;
}

.form-check.form-check-flat label input:disabled+.input-helper:before {
    border-color: #f3f3f3;
}

.form-check.form-check-flat label input:disabled:checked+.input-helper:before {
    background: #f7f7f9;
}

.form-check.form-check-flat label .input-helper:before {
    border: 2px solid #f3f3f3;
}

.form-check.form-check-flat label .input-helper:after {
    color: #ffffff;
}

.form-radio label input+.input-helper:before {
    position: absolute;
    content: "";
    top: 2px;
    left: 0;
    border: 2px solid #f3f3f3;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    transition-duration: 0s;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
}

.rtl .form-radio label input+.input-helper:before {
    left: auto;
    right: 0;
}

.form-radio label input+.input-helper:after {
    content: "";
    width: 8px;
    height: 8px;
    background: #FF0017;
    border-radius: 50%;
    top: 8px;
    left: 6px;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    transition-duration: 0s;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    position: absolute;
}

.rtl .form-radio label input+.input-helper:after {
    left: auto;
    right: 6px;
}

.form-radio label input:checked+.input-helper:before {
    background-color: #ffffff;
    border: 2px solid #f3f3f3;
}

.form-radio label input:checked+.input-helper:after {
    opacity: 1;
    line-height: 1.5;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.form-radio label input:disabled+.input-helper:before {
    border-color: #f3f3f3;
}

.form-radio label input:disabled:checked+.input-helper:before {
    background-color: #ffffff;
    border-color: #f3f3f3;
}

.form-radio label input:disabled:checked+.input-helper:after {
    background-color: #f3f3f3;
}

.form-radio.form-radio-flat label input:checked+.input-helper:before {
    background: #08D419;
    border-color: #08D419;
}

.form-radio.form-radio-flat label input:checked+.input-helper:after {
    width: 20px;
    height: 20px;
    top: 2px;
    left: -2px;
    color: #ffffff;
    background: none;
    content: '\F12C';
    font-family: Material Design Icons;
    text-align: center;
    font-weight: bold;
}

.rtl .form-radio.form-radio-flat label input:checked+.input-helper:after {
    left: auto;
    right: -2px;
}

/* Dropdowns */
.dropdown-menu {
    font-size: 0.875rem;
}

.dropdown-menu .dropdown-item:active {
    background: initial;
}

/* Forms */
.input-group-append,
.input-group-prepend {
    background: #ffffff;
    color: #c9c8c8;
    width: auto;
    border: none;
}

.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
    background: transparent;
    border-color: #f3f3f3;
}

.form-control {
    border: 1px solid #989898;
    font-family: "Poppins", sans-serif;
    font-size: 0.80rem;
}

.datepicker .form-control {
    line-height: 16px;
}

.form-control.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.form-control.input-group-text,
.input-group-lg>.input-group-append>.form-control.input-group-text,
.input-group-lg>.input-group-prepend>.form-control.btn,
.input-group-lg>.input-group-append>.form-control.btn {
    padding: 0.5rem 0.75rem;
}

.form-control.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.form-control.input-group-text,
.input-group-sm>.input-group-append>.form-control.input-group-text,
.input-group-sm>.input-group-prepend>.form-control.btn,
.input-group-sm>.input-group-append>.form-control.btn {
    padding: 0.3rem 0.75rem;
}

.form-group label {
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: top;
    font-weight: 500;
}

.form-group.has-danger .form-control {
    border-color: #FF0017;
}

.form-group .file-upload-default {
    visibility: hidden;
    position: absolute;
}

.form-group .file-upload-info {
    background: transparent;
}

/* Icons */
.icons-list {
    border-left: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.icons-list>div {
    background: #ffffff;
    border-top: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 15px;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
}

.icons-list>div i {
    display: inline-block;
    font-size: 20px;
    width: 40px;
    text-align: left;
    color: #1991EB;
}

/* Preview */
.preview-list .preview-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
}

.preview-list .preview-item:last-child {
    border-bottom: 0;
}

.preview-list .preview-item:hover {
    background: #f8f9fa;
}

.preview-list .preview-item .form-check {
    margin-top: 8px;
    margin-right: 1rem;
}

.preview-list .preview-item .preview-thumbnail {
    color: #ffffff;
    position: relative;
}

.preview-list .preview-item .preview-thumbnail .preview-icon,
.preview-list .preview-item .preview-thumbnail img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.preview-list .preview-item .preview-thumbnail .preview-icon {
    padding: 6px;
    text-align: center;
}

.preview-list .preview-item .preview-thumbnail .preview-icon i {
    font-size: 1.125rem;
}

.preview-list .preview-item .preview-thumbnail .badge {
    border: 2px solid #ffffff;
    border-radius: 100%;
    bottom: 5px;
    display: block;
    height: 14px;
    left: -5px;
    padding: 0;
    position: absolute;
    width: 14px;
}

.preview-list .preview-item .preview-item-content {
    line-height: 1;
    padding-left: 15px;
}

.rtl .preview-list .preview-item .preview-item-content {
    padding-left: 0;
    padding-right: 15px;
}

.preview-list .preview-item .preview-item-content:first-child {
    padding-left: 0;
}

.rtl .preview-list .preview-item .preview-item-content:first-child {
    padding-right: 0;
}

.preview-list .preview-item .preview-item-content p {
    margin-bottom: 10px;
}

.preview-list .preview-item .preview-item-content p .content-category {
    font-family: "Poppins", sans-serif;
    padding-right: 15px;
    border-right: 1px solid #f3f3f3;
}

.preview-list .preview-item .preview-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.preview-list .preview-item .preview-actions i {
    width: 29px;
    color: #f7f7f9;
    height: 29px;
    border: 2px solid #f7f7f9;
    border-radius: 100%;
    padding: 3px 6px;
    display: inline-block;
}

.preview-list .preview-item .preview-actions i:first-child {
    margin-right: 10px;
}

.preview-list.comment-preview .preview-item {
    padding: 0.87rem 0;
}

.preview-list.comment-preview .preview-item:first-child {
    padding-top: 0;
}

.preview-list.comment-preview .preview-item p {
    line-height: 27px;
}

.preview-list.bordered .preview-item {
    border-bottom: 1px solid #f3f3f3;
}

.preview-list.bordered .preview-item:last-child {
    border-bottom: 0;
}

/* Tables */
.table {
    margin-bottom: 0;
}

.table thead th {
    border-top: 0;
    border-bottom-width: 1px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #333;
    user-select: none;
    padding: 12px 10px;
    min-height: 48px;
    vertical-align: middle;
    white-space: nowrap;
}

.table td,
.table th {
    vertical-align: middle;
    font-size: 0.875rem;
    line-height: 1;
}

.table td img,
.table th img {
    max-width: 100%;
}

.table td .badge,
.table th .badge {
    margin-bottom: 0;
}

.table.table-borderless {
    border: none;
}

.table.table-borderless td,
.table.table-borderless th,
.table.table-borderless tr {
    border: none;
}

/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* Auth */
.auth.login-full-bg {
    //background: url("../images/login_1.jpg");
    background-size: cover;
}

.auth .auth-form-dark {
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    border-radius: 10px;
}

.auth .auth-form-dark .form-control {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.auth .auth-form-dark .form-control:-ms-input-placeholder {
    color: #ffffff;
}

.auth .auth-form-dark .form-control:-moz-placeholder {
    color: #ffffff;
}

.auth .auth-form-dark .form-control::-moz-placeholder {
    color: #ffffff;
}

.auth .auth-form-dark .form-control::-webkit-input-placeholder {
    color: #ffffff;
}

.auth .auth-form-light {
    background: #ffffff;
    color: #000;
}

.auth .auth-form-light .form-control {
    border-color: rgba(0, 0, 0, 0.2);
    color: #000;
}

.auth .auth-form-light .form-control:-ms-input-placeholder {
    color: #000;
}

.auth .auth-form-light .form-control:-moz-placeholder {
    color: #000;
}

.auth .auth-form-light .form-control::-moz-placeholder {
    color: #000;
}

.auth .auth-form-light .form-control::-webkit-input-placeholder {
    color: #000;
}

.auth .auth-form-transparent {
    background: transparent;
    color: #ffffff;
}

.auth .auth-form-transparent form .form-group .form-control {
    padding-left: 1rem;
    padding-right: 1rem;
    border-color: rgba(255, 255, 255, 0.2);
}

.auth form .form-group {
    position: relative;
}

.auth form .form-group label {
    margin-bottom: 0;
    vertical-align: bottom;
    font-size: 0.8125rem;
    font-family: "Poppins", sans-serif;
}

.auth form .form-group .form-control {
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 2.5rem;
    font-size: 0.9375rem;
}

.auth form .form-group i {
    position: absolute;
    right: 1rem;
    height: 18px;
    top: calc((100% - 18px) / 2);
}

.auth form .btn {
    font-size: 1.25rem;
}

.auth form .auth-link {
    font-size: 0.875rem;
}

.auth form .auth-link:hover {
    color: initial;
}

/* Error */
.error-page h1 {
    font-size: 9.375rem;
    line-height: 1;
}

@media (max-width: 991px) {
    .error-page h1 {
        font-size: 8rem;
    }
}

.error-page h2 {
    font-size: 4.375rem;
    line-height: 1;
}

@media (min-width: 992px) {
    .error-page .error-page-divider {
        /*border-left: 3px solid rgba(255, 255, 255, 0.2);*/
        border-left: 3px solid #333;
    }
}

/*# sourceMappingURL=maps/style.css.map */

.breadcrumbadmin {
    background: rgba(228, 228, 228, 0.46);
    text-align: right;
    font-size: 13px;
    line-height: 20px;
    padding: 12px 1.1rem;
    margin-top: 58px;
}

.breadcrumbadmin a {
    cursor: pointer;
}

.breadcrumbadmin .fa {
    padding: 0px 5px;
}

.breadcrumbadmin .link {
    color: #745af2 !important
}

.user-dropdown a {
    color: #212529;
    text-decoration: none;
}

.user-dropdown a:hover {
    color: #212529;
    text-decoration: none;
}

.breadcrumbadmin .title {
    font-size: 18px;
    color: #565656;
    font-weight: 600;
    text-align: left;
}


























.breadcrumb {
    background: rgba(228, 228, 228, 0.46);
    text-align: right;
    font-size: 13px;
    line-height: 20px;
    padding: 12px 1.1rem;
    margin: 0px;
}

.breadcrumb a {
    cursor: pointer;
}

.breadcrumb .fa {
    padding: 0px 5px;
}

.breadcrumb .link {
    color: #745af2 !important
}

.user-dropdown a {
    color: #212529;
    text-decoration: none;
}

.user-dropdown a:hover {
    color: #212529;
    text-decoration: none;
}

.breadcrumb .title {
    font-size: 18px;
    color: #565656;
    font-weight: 600;
    text-align: left;
}

/*Custom Css*/
.cursor {
    cursor: pointer;
}

.border-radius-5 {
    border-radius: 5px;
}

ng-component,
app {
    width: 100%;
}

/* loader */
.main_loader>.background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #E7F4FC;
}

.main_loader>.background>.wrapper {
    position: fixed;
    top: calc(50% - 30px);
    left: 50%;
}

.main_loader>.background>.wrapper>.loading {
    margin-left: -34px;
    margin-top: 40px;
}

@-webkit-keyframes ball-scale-multiple {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes ball-scale-multiple {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

.ball-scale-multiple {
    position: relative;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
}

.ball-scale-multiple>div:nth-child(2) {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.ball-scale-multiple>div:nth-child(3) {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.ball-scale-multiple>div {
    background-color: #2B303B;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    left: -30px;
    top: 0;
    opacity: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
    animation: ball-scale-multiple 1s 0s linear infinite;
}

.main_loader.app_loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2222;
}

.main_loader.app_loader .background {
    background: none
}

/* loader */

.has-error .input-group-append {
    border: 1px solid #dd4b39;
    border-left: 0px;
}

input.has-error {
    border: 1px solid #dd4b39 !important;
}

.has-error label {
    color: #dd4b39
}

.has-error .form-control,
.has-error .input-group-addon,
input.has-error {
    border-color: #dd4b39 !important;
    box-shadow: none
}

.has-error-login,
.has-error-login i,
.has-error-login span {
    color: #fb4c2f !important;
}

.has-error-login input,
.has-error-login button {
    border-color: #fb4c2f !important;
}

/*Start Grid Filter menu*/
.hide {
    display: none;
}

.filter_list {
    min-width: 130px;
    margin-top: 65px;
    position: absolute;
    z-index: 100;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 3px;
}

.filter_list ul {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.filter_list li {
    padding: 3px 8px;
    list-style: none;
    margin: 1px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border-width: 0;
    line-height: 20px;
}

.filter_list li:hover,
.filter_list li.selected {
    cursor: pointer;
    text-decoration: none;
    background: #f5f5f5;
    box-shadow: none;
    outline: none;
    color: #000000;
    font-weight: 600;
}

/*End Filter menu*/
/*Table Header Footer*/
.table-header .title {
    font-size: 19px;
    color: #565656;
    font-weight: 600;
}

.table-header .btn i {
    margin-right: 0px;
}

.table-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 10px;
    /*border-bottom: 1px solid #E0E0E0;*/
    width: 100%;
    padding-top: 10px;
}

.table-header .btn:not(:last-child),
.card-title .btn:not(:last-child) {
    margin-right: 3px;
}

.table-header .btn,
.card-title .btn {
    margin-bottom: 6px;
    font-size: 13px;
}

.modal-footer .btn:not(:last-child) {
    margin-right: 5px;
}

.table-filter:not(.summary) input.form-control {
    margin-right: 10px;
    margin-bottom: 5px;
}

.card-title .summary .btn {
    margin-bottom: 0;
}

.card-title .btn i {
    /*margin-right: 5px;*/
}

.table-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px;
}

.table-footer {
    background-color: #fafafa;
    padding: 20px;
    font-size: 13px;
}

.table-footer .pagination {
    margin-bottom: 0px;
}

.table-footer select {
    width: 70px;
    display: inline-block;
    padding: 5px;
    height: 36px;
}

.table-filter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
}

.table-filter select,
.table-filter .ng2-flatpickr-input-container input {
    height: 34px !important;
}

/*.page-link {
    color: #000;
    border-radius: 0px !important;
}*/
.page-item.active .page-link,
.page-link:hover {
    z-index: 1;
    color: #fff !important;
    background: #2196f3;
    border-color: #2196f3;
    opacity: 0.9;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none;
}

.table-footer span {
    white-space: nowrap;
    letter-spacing: 0.5px;
}

.table td,
.table th {
    font-size: 13px;
}

.table td .custom-checkbox,
.table th .custom-checkbox {
    text-align: center;
    display: inline-block;
    /*left: -20px;
        top: -12px;*/
}

@media screen and (max-width: 600px) {
    .table-footer {
        display: inline-block;
        text-align: center;
        padding: 10px;
        font-size: 13px;
    }

    .table-footer nav {
        display: inline-block;
        margin-top: 10px;
    }

    .table td,
    .table th {
        font-size: 13px;
    }

    .table-filter:not(.summary) .form-control {
        margin-bottom: 5px;
    }
}

.input-group .filter-textbox,
.input-group .filter-textbox .form-control {
    height: 28px !important;
}

.input-group .filter-textbox,
.input-group .ng2-flatpickr-input.form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.txt-filter .btn {
    height: 28px;
    line-height: 14px;
    width: 32px;
    text-align: center;
    padding: 7px 3px;
    font-size: 11px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

table th div.input-group {
    padding-top: 5px;
}

/*Table Footer*/

.form-group .custom-checkbox .custom-control-label,
.form-group .custom-radio .custom-control-label {
    margin-top: 6px;
}

.nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #aeb4b7;
    font-size: 14px;
    border: 1px solid #f3f3f3;
}

.nodata>span {
    line-height: 100px;
}


.font-10 {
    font-size: 10px !important;
}

.font-11 {
    font-size: 11px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-17 {
    font-size: 17px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-19 {
    font-size: 19px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px !important;
}

.prl-0 {
    padding-right: 0px;
    padding-left: 0px;
}

.ptb-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.pd-7 {
    padding: 7px;
}

.pd-10 {
    padding: 10px;
}

.admin-header .card-title {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.admin-header .bookmark-icon i.fa,
.admin-header .bookmark-icon span.fa {
    margin-top: -40px;
    margin-right: -15px;
}

.admin-header .card .card-body {
    padding: 1.88rem 1.2rem !important;
}

.bg-pink {
    background-color: #e83e8c;
}

.Announcement label {
    font-size: inherit;
}

.login-full-bg .dropdown-menu {
    width: 100%;
    max-height: 250px;
    overflow: auto;
    cursor: pointer;
}

.login-full-bg .dropdown-menu .dropdown-item {
    padding: 10px 15px !important;
}

.login-full-bg .dropdown-menu .dropdown-item img {
    margin-right: 5px;
}

.login-full-bg .dropdown-toggle,
.login-full-bg .btn-arrow {
    background: transparent;
    color: white;
    border-color: rgba(255, 255, 255, 0.2);
}

.login-full-bg .input-group-append {
    background: transparent;
}

.login-full-bg .dropdown .btn-lang {
    background: none;
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    text-align: left;
    font-size: 0.9375rem;
    padding: 0.56rem 10px 0.56rem 0px;
    border-bottom-width: 1px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.login-full-bg .dropdown .img-flag {
    float: right;
}


.overlayHome {
    min-height: 0px;
    text-align: center;
    vertical-align: middle;
    line-height: 200px;
    font-size: 30px;
}

.overlay {
    min-height: 200px;
    text-align: center;
    vertical-align: middle;
    line-height: 200px;
    font-size: 30px;
}

.admin-header .card a:hover {
    text-decoration: none;
}

.profile-list-pic {
    width: 35px;
    height: 35px;
    max-width: none !important;
    object-fit: cover;
}

.admin-header .bg-info-light {
    background: rgba(116, 90, 242, 0.8);
}

.admin-header .bg-primary-light {
    background: rgba(25, 145, 235, 0.8);
}

.admin-header .bg-teal-light {
    background: rgba(88, 216, 163, 0.8);
}

.admin-header .bg-warning-light {
    background: rgba(255, 193, 5, 0.8);
}

.admin-header .bg-dark-light {
    background: rgba(66, 73, 100, 0.8);
}

.admin-header .bg-pink-light {
    background: rgba(232, 62, 140, 0.8);
}

.brand-logo .company-title,
.brand-logo-mini .company-title {
    font-size: 12px;
    display: block;
    color: #333;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
}

.max-dropdown-overflow {
    max-height: 300px;
    overflow: auto;
}

.subtooltip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
}

.subtooltip a {
    cursor: pointer;
}

.subtooltip,
.subtooltip a:hover {
    color: #007bff !important;
}

.dropdown-item label {
    margin-bottom: 0px;
    padding: 3px;
    cursor: pointer;
}

.min-120 {
    min-width: 120px;
}

.table-header .custom-dropdown button {
    background: inherit;
    border: 1px solid #c1c9d0;
    text-align: left;
}

.table-header ng-select {
    display: inline-block;
}

.table-header ng-select .form-control {
    border-color: #eeeff0;
}

.table-header select {
    width: auto;
    display: inline-block;
    height: 33px !important;
    vertical-align: middle;
    max-width: 200px;
}

.table-header .input-group .form-control {
    background-color: #fff;
}

.table-header .input-group .input-group-append .dropdown-toggle-split {
    background: #f3f3f3;
    border: 1px solid #eeeff0;
    padding: 0.5rem 0.75rem;
}

.table-header .dropdown {
    display: inline-block;
}

.dropdown.open .fa-angle-down {
    transform: rotate(180deg);
}

.table-header .dropdown button {
    max-width: 250px;
    text-overflow: ellipsis;
}

.table-header .dropdown-menu {
    max-height: 260px;
    overflow-y: auto;
}

.table-header .dropdown-menu .dropdown-item {
    font-size: 13px;
}

.clrBox {
    width: 20px;
    height: 20px;
}

.form-box .input-append .btn {
    padding-left: 15px;
    padding-right: 15px;
}

.form-box .input-append .btn-sm {
    font-size: 16px;
}

.save-close {
    font-size: 13px;
    background: none;
    padding: 0px;
    border: none;
    position: absolute;
    top: 3px;
    right: 4px;
}

.save-plus {
    font-size: 12px;
    background: none;
    padding: 0px;
    border: none;
    position: absolute;
    top: 5px;
    left: 18px;
}


.small-btn .btn-xs {
    font-size: 12px;
    padding: 3px 6px;
}

.bg-light-yellow,
.bg-light-yellow tr {
    background-color: #ffffe0 !important;
}

.table-attachment tr td {
    padding: 10px !important;
}

typeahead-container {
    width: 100%;
}

typeahead-container.dropdown-menu {
    padding: 0.2rem 0;
}

typeahead-container .dropdown-item strong {
    color: #f33848;
}

.min-ht-100 {
    min-height: 100px;
}

.min-ht-60 {
    min-height: 60px;
}

.attachment-count-indicator {
    position: relative;
}

.attachment-count-indicator .count {
    position: absolute;
    left: 50%;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background: #ff3345;
    font-size: 75%;
    top: 7px;
    font-weight: 600;
    line-height: 1rem;
    border: none;
    text-align: center;
    color: #fff;
}

.table th,
.table td {
    padding: 0.5375rem;
}

.db-summary .card-body {
    padding: 1rem;
    border-radius: 5px;
}

.db-summary .card-body .progress {
    height: 0.80rem;
    font-size: 0.70rem;
}

.db-summary .card-body .head-name {
    line-height: 30px;
    vertical-align: middle;
    font-size: 14px;
}

.text-pink {
    color: #e83e8c;
}

.bg-orange {
    background-color: #fb9678;
}

.text-orange {
    color: #fb9678;
}

.overflow_chart {
    overflow-x: auto;
    overflow-y: hidden;
}

.ticket-details a {
    font-size: 14px;
}

.ticket-details a:hover {
    text-decoration: none !important;
    color: #0056b3 !important;
}

.bg-main-body {
    background: #f9f9f9;
}

@media (min-width: 1024px) {
    .full-height {
        height: 100%;
    }
}

.btn-md-group {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
}

/*Webuipopover tooltip*/
.webui-popover {
    border-radius: 5px !important;
    border: 1px solid rgba(0, 0, 0, .17) !important;
    box-shadow: none;
}

.webui-popover.top>.arrow,
.webui-popover.top-right>.arrow,
.webui-popover.top-left>.arrow {
    border-top-color: #f3f3f3 !important;
}

.webui-popover .webui-popover-content {
    padding: 10px 15px !important;
}

.webui-popover-text-center {
    text-align: center !important;
}

.desctooltip {
    max-height: 450px;
}

.tooltip {
    min-width: 80px;
}

.card .card-title .table-filter.summary input {
    width: 100%;
    max-width: 90px;
    padding: 8px 4px;
    text-align: center;
}

.card .card-title .table-filter.summary input.ng2-flatpickr-input-container {
    width: 100%;
}

.card .card-title .table-filter.summary select {
    max-width: 125px;
    min-width: 110px;
}

/*.chart-style > div::before {
    content: "";
    position: absolute;
    display: block;
    width: 240px;
    height: 30px;
    left: 155px;
    top: 254px;
    background: #FAFAFA;
    box-shadow: 1px 1px 0 0 #DDD;
}*/


/*Start Notification*/
.notification {
    position: fixed;
    padding: 1.25rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0px;
    min-width: 350px;
    max-width: 350px;
    top: 10px;
    right: 10px;
    z-index: 10500;
}

.notification .icon {
    position: relative;
    float: left;
    font-size: 20px;
    margin-right: 10px;
}

.notification .close {
    padding: 1.25rem 1rem !important;
}

/*End Notification*/
/*Start Kendo Editor*/
.k-editor-toolbar .k-button-group .k-tool {
    width: auto !important;
    height: auto !important;
    line-height: 0px !important;
    padding: 5px 5px !important;
}

.k-editor-toolbar .k-colorpicker,
.k-editor-toolbar .k-combobox,
.k-editor-toolbar .k-dropdown,
.k-editor-toolbar .k-selectbox {
    max-width: 120px;
}

.k-editor .k-editor-toolbar-wrap {
    padding: 0 5px !important;
}

.editor-height .k-editable-area .k-content {
    min-height: 250px;
}

/*End Kendo Editor*/


/*Start Sidebar menu*/
.sidebar {
    position: fixed;
    left: -265px;
    z-index: 111111;
}

@media (max-width: 1024px) {
    .breadcrumb {
        margin-top: 58px;
    }

    .show-left-menu {
        left: 0 !important;
        position: fixed;
    }

    .hide-left-menu {
        left: -265px !important;
        position: fixed;
    }

    .rtl .show-left-menu {
        right: 0 !important;
        left: auto;
    }

    .rtl .hide-left-menu {
        right: -265px !important;
        left: auto;
    }

    .sidebar-main {
        display: block !important;
        z-index: 1001111;
        position: fixed;
        -webkit-overflow-scrolling: touch;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        -webkit-transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        width: 255px !important;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        left: -265px;
        margin-top: 58px;
    }
}

@media (min-width: 1024px) {
    .sidebar-admin {
        left: 0;
        position: relative;
    }
}

/*End Sidebar menu*/


.m-card-profile__pic-wrapper {
    border-radius: 100%;
    margin: 0.50rem auto;
    padding: 8px;
    display: inline-block;
    border: 2px solid #747474 !important
}

.m-card-profile__pic-wrapper img {
    width: 160px;
    height: 160px;
    border-radius: 100%;
}

@media (max-width : 500px) {
    .hidden-phone {
        display: none;
    }
}

.dv-profile-left {
    /*max-width: 260px;*/
}

.dv-profile-right {
    padding-left: 3rem;
}

.webui-popover .webui-popover-content {
    min-height: 150px;
}

.webui-popover i.icon-refresh {
    //background: url(../images/loading.gif) no-repeat !important;
    margin-top: -15px;
}

.card-body.min-height {
    min-height: 200px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown.ddl-announcment .dropdown-item {
    align-items: baseline !important;
}

.ddl-announcment .preview-item-content h6 {
    font-size: 0.800rem;
}

.preview-list.ddl-announcment .preview-item .preview-item-content p {
    margin-bottom: 2px;
    font-size: 0.60rem;
}

.ticket-chat .table-responsive {
    max-height: 1000px;
}

@media (max-width: 1199.98px) {
    .ticket-chat {
        margin-top: 48px;
    }

    .ticket-chat .table-responsive {
        max-height: 250px;
    }
}


/*Start Overlay div Loader*/
.overlay-inner {
    min-height: 200px;
    text-align: center;
    vertical-align: middle;
    line-height: 200px;
    font-size: 30px;
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /*background-color: rgba(33,37,41,0.3);*/
    z-index: 2;
}

.overlay-inner i {
    position: absolute;
    top: 49%;
    margin-top: 25px;
}

/*End Overlay div Loader*/


/*START RTL*/
.rtl body {
    direction: rtl;
    text-align: right;
}

.rtl .navbar .navbar-menu-wrapper .navbar-toggler {
    left: 0;
    right: auto !important;
}

.rtl .sidebar .nav {
    padding-right: 0;
    padding-left: 0;
}

.rtl .sidebar .nav .nav-item .nav-link i {
    padding-left: 5px;
    padding-right: 0;
}

.rtl .auth-form-dark {
    text-align: right !important;
}

.rtl .auth-form-dark btn.btn-lang {
    text-align: right;
}

.rtl .auth-form-dark btn.btn-lang img {
    float: left;
}

.rtl .dropdown-toggle::after {
    margin-right: 0.255em;
}

.rtl .navbar-nav {
    padding-right: 0;
}

.rtl .row.breadcrumb .title {
    text-align: right;
}

.rtl .row.breadcrumb div:last-child {
    text-align: left;
}

.rtl .table-header .btn:not(:last-child),
.rtl .card-title .btn:not(:last-child) {
    margin-left: 3px;
    margin-right: 0;
}

.rtl .table .input-group.bool .input-append {
    margin-right: 4px;
}

.rtl .table tr th a.sort span {
    left: -5px;
    right: auto;
}

.rtl .table tr th a.sort {
    padding-left: 15px;
    padding-right: 0;
}

.rtl .dropleft .dropdown-menu {
    margin-left: 0.125rem;
    left: 0;
    right: auto;
}

.rtl .dropdown-item {
    text-align: right;
}

.rtl .dropdown-item .custom-control.custom-checkbox {
    padding-right: 1.5rem;
    padding-left: 0;
}

.rtl .dropdown-item .custom-control.custom-checkbox .custom-control-label::before,
.rtl .dropdown-item .custom-control.custom-checkbox .custom-control-label::after {
    right: 0;
    left: auto;
}

.rtl .db-summary .card-body .head-name .float-left {
    float: right !important;
}

.rtl .flatpickr-current-month .flatpickr-monthDropdown-months {
    max-width: 95px;
}

.rtl .notification {
    border-left: 0 !important;
}

.rtl .notification .icon {
    margin-left: 10px;
    margin-right: 0;
}

.rtl .notification .close {
    left: 0;
    right: auto;
}

.rtl .alert-success {
    border-right: 6px solid #046e0d !important;
}

.rtl .alert-warning {
    border-right: 6px solid #856403 !important;
}

.rtl .alert-info {
    border-right: 6px solid #3c2f7e !important;
}

.rtl .alert-primary {
    border-right: 6px solid #0d4b7a !important;
}

.rtl .alert-teal {
    border-right: 6px solid #2e7055 !important;
}

.rtl form .custom-control.custom-checkbox,
.rtl form .custom-control.custom-radio {
    padding-right: 1.5rem;
    padding-left: 0;
}

.rtl form .custom-control.custom-checkbox .custom-control-label::before,
.rtl form .custom-control.custom-checkbox .custom-control-label::after,
.rtl form .custom-control.custom-radio .custom-control-label::before,
.rtl form .custom-control.custom-radio .custom-control-label::after {
    right: 0;
    left: auto;
}

.rtl .modal-header .close {
    margin: -1rem auto -1rem -1rem;
}

.rtl .modal-footer .btn:not(:last-child) {
    margin-left: 5px;
    margin-right: 0;
}

.rtl .save-plus {
    right: 6px;
    left: auto;
}

.rtl .webui-popover-content .form-box {
    text-align: right;
}

.rtl .float-right {
    float: left !important;
}

.rtl .ml-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important;
}

.rtl .mr-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important;
}

.rtl .ml-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
}

.rtl .mr-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
}

.rtl .table-filter.summary .input-group.datepicker {
    margin: 0 0.50rem !important;
}

.rtl .pagination .page-link .fa-angle-right,
.rtl .pagination .page-link .fa-angle-double-right,
.rtl .pagination .page-link .fa-angle-left,
.rtl .pagination .page-link .fa-angle-double-left {
    transform: rotate(180deg);
}

.rtl .ng2-flatpickr-input {
    width: 100% !important;
}

.rtl .custom-select {
    background-position: left 0.55rem center;
}

.rtl .table-filter:not(.summary) input.form-control {
    margin-right: 0;
    margin-left: 10px;
}

@media (min-width: 576px) {
    .rtl .float-sm-right {
        float: left !important;
    }
}

/*END RTL*/
@media (min-width: 992px) and (max-width: 1199px) {
    .navbar .navbar-menu-wrapper .navbar-nav.header-links .nav-item .nav-link {
        padding: 16px 10px;
    }

    .table-header {
        -ms-flex-align: flex-start;
        align-items: flex-start;
    }
}

@media (max-width: 992px) {
    .webui-popover {
        display: none !important;
    }

    .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
        left: auto;
    }
}

@media (max-width: 767px) {
    .card>.card-title {
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
    }

    .card .table-filter {
        display: -ms-flexbox;
        display: flex;
    }

    .table-header,
    .table-filter,
    .table-header div.dropdown {
        display: inline-block;
    }

    .card .table-filter.summary {
        margin-top: 10px;
    }
}

@media (max-width: 481px) {
    .card .table-filter {
        width: 100%;
    }

    .card .table-filter.summary {
        width: 100%;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
    }

    .card .table-filter.summary .input-group {
        width: 44%;
        margin-top: 10px;
    }

    .card .table-filter.summary {
        margin-top: 0px;
    }

    .card .card-title .table-filter.summary input {
        max-width: 140px;
    }

    .card .card-title .table-filter.summary select {
        max-width: 73%;
        margin-top: 10px;
        margin-right: 5px;
    }

    .card .table-filter.summary .dropdown {
        margin-top: 10px;
    }

    .card-body .table-header .btn {
        padding: 0.5rem 0.53rem;
    }

    .table-footer span {
        white-space: normal;
    }

    .tooltip {
        background: #000;
    }



}


//********************************************************************************
/* your-component.component.css */

/* Styling for ng-select component */
.ng-select {
    width: 100%;
    border: 1px solid #9d9d9d;
    font-family: Arial, sans-serif;
    /* Set font family as needed */
}

/* Styling for ng-option items */
.ng-select .ng-option {
    padding: 8px;
}

//   /* Styling for error message */
.error-block {
    color: #ff0000;
    font-size: 12px;
    margin-top: 5px;
}

/* Styling for the selected items container */
.ng-value-container {
    // border: 1px solid #ccc;
    padding: 5px;
    min-height: 32px;
}

//   /* Styling for the selected items in the dropdown */
.ng-value {
    background-color: #f14300;
    border: 1px solid #ccc;
    padding: 2px 5px;
    margin: 2px;
    color: #fff;
}

/* Styling for the clear button */
.ng-clear-wrapper::before {
    //content: '✕'; /* You can use any other symbol or text for the clear button */
    color: #ff0000;
    font-weight: bold;
    cursor: pointer;
    top: 8px;
}

.ng-clear-wrapper {
    content: '✕';
    /* You can use any other symbol or text for the clear button */
    color: #ff0000;
    font-weight: bold;
    cursor: pointer;
    top: 8px;
}

.ng-arrow-wrapper {
    content: '✕';
    /* You can use any other symbol or text for the clear button */
    color: #ff0000;
    font-weight: bold;
    cursor: pointer;
    top: 8px;
}

.ng-arrow {
    content: '✕';
    /* You can use any other symbol or text for the clear button */
    color: #ff0000;
    font-weight: bold;
    cursor: pointer;
    top: 8px;
}


//   /* Styling for the search input */
.ng-input {
    padding: 5px;
    color: #000;
}

//   /* Styling for the dropdown options */
.ng-dropdown-panel .ng-option {
    padding: 8px;
    background: #f2f2f2;
    color: #000;
}

//   /* Styling for the selected option in the dropdown */
.ng-dropdown-panel .ng-option.ng-option-marked {
    background-color: #f2f2f2;
    color: #000;
}

//   /* Styling for the hover effect on dropdown options */
.ng-dropdown-panel .ng-option.ng-option-marked {
    background-color: var(--mainColor);
    color: var(--bs-white);
}

//   /* Styling for the dropdown panel */
.ng-dropdown-panel {
    border: 1px solid #ccc;
}

//   /* Styling for the dropdown arrow icon */
.ng-arrow-wrapper::before {
    color: #333;
}

.ng-select-container .ng-placeholder.ng-select-focused {
    display: none;
}

.headerbar {
    background: var(--mainColor);
    border-radius: 100px;
    height: 60px;
}

.typeform_input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid lightgrey;
    border-radius: 0;
    outline: none;
    width: 90%;
    font-size: 20px;
    margin: 10px;
    padding: 10px;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 1s;

    &:focus {
        width: 100%;
        border-bottom: 3px solid #52B6AC;
    }
}

.content-container {
    text-align: center;
}

.mod-body {
    position: relative;
    flex: 1 1 auto;
    padding: 15px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

/* your-component.component.css */
.mod-dialog {
    max-width: 80%;
    /* Adjust the maximum width of the modal */
}

.mod-body img {
    max-width: 100%;
    /* Make the image responsive within the modal body */
    height: auto;
    margin: 0 auto;
    /* Center the image within the modal body */
}

@media (max-width: 576px) {

    /* Adjust the image size for small screens (e.g., mobile devices) */
    .mod-dialog {
        max-width: 90%;
        /* Adjust the maximum width for small screens */
    }

    .mod-body img {
        max-width: 100%;
        /* Make the image responsive for small screens */
    }
}

.buttonapply {
    background-color: #1c87c9;
    -webkit-border-radius: 60px;
    border-radius: 60px;
    border: none;
    color: #eeeeee;
    cursor: pointer;
    display: inline-block;
    font-family: sans-serif;
    font-size: 20px;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
}

@keyframes glowing {
    0% {
        background-color: #2ba805;
        box-shadow: 0 0 5px #2ba805;
    }

    50% {
        background-color: #49e819;
        box-shadow: 0 0 20px #49e819;
    }

    100% {
        background-color: #2ba805;
        box-shadow: 0 0 5px #2ba805;
    }
}

.a {
    animation: glowing 1300ms infinite;
}

.card-container2 {
    padding-top: 40px;
    display: flex;
}

.card2 {
    display: flex;
    min-width: 300px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-right: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    a {
        display: block;

        img {
            height: 360px;
            width: 100%;
            transition: 0.7s;
        }
    }
}

.animate2 {
    transition: all 0.25s ease;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .card2 {
        min-width: 100%;
        /* Adjust as needed for full-width cards on smaller screens */
    }

    .card-container2 {
        flex-direction: column;
        /* Stack cards vertically on smaller screens */
        align-items: center;
        /* Center-align cards on smaller screens */
    }
}


.video-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

ngx-youtube-player .container {
    position: static !important;
    /* Use 'static' to remove 'position: relative;' */
    height: 647px !important;
    /* Override the height */
    width: 100% !important;
    /* Override other styles as needed */
}

ngx-youtube-player .container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

ngx-youtube-player .container .overlay img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

ngx-youtube-player .container .overlay.loaded img {
    opacity: 0;
}

ngx-youtube-player .container .overlay button {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 1.5rem;
    z-index: 2;
    border: 0;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    background: transparent;
}

/* Default styles for all screen sizes */
ngx-youtube-player .container {
    width: 100%;
    position: relative;
}

/* Styles for screens larger than 1200px (big desktop) */
@media (min-width: 1200px) {
    ngx-youtube-player .container {
        height: 657px;
    }
}

/* Styles for screens between 768px and 1199px (laptop) */
@media (min-width: 768px) and (max-width: 1199px) {
    ngx-youtube-player .container {
        height: 300px;
    }
}

/* Styles for screens between 576px and 767px (tablet) */
@media (min-width: 576px) and (max-width: 767px) {
    ngx-youtube-player .container {
        height: 253px;
    }
}

/* Styles for screens up to 575px (mobile) */
@media (max-width: 575px) {
    ngx-youtube-player .container {
        height: 200px; /* Adjust as needed for smaller screens */
    }
}

/* Additional styles for landscape orientation on mobile devices */
@media (max-width: 575px) and (orientation: landscape) {
    ngx-youtube-player .container {
        height: 150px; /* Adjust as needed for landscape orientation */
    }
}



@keyframes glowing {
    0% {
        background-color: #2ba805;
        box-shadow: 0 0 5px #2ba805;
    }

    50% {
        background-color: #49e819;
        box-shadow: 0 0 20px #49e819;
    }

    100% {
        background-color: #2ba805;
        box-shadow: 0 0 5px #2ba805;
    }
}

.a {
    animation: glowing 1300ms infinite;
}

.replies {
    list-style-type: none;
    margin-left: 20px;
    /* Adjust as needed for indentation */
}

.reply {
    margin-bottom: 15px;
    /* Adjust as needed */
}

.reply img {
    width: 40px;
    /* Adjust as needed */
    height: 40px;
    /* Adjust as needed */
    border-radius: 50%;
    margin-right: 8px;
}

/* Add this CSS in your Angular component stylesheet or global styles */
.whatsapp-button {
    position: fixed;
    bottom: 100px;
    right: 20px;
    z-index: 1000;
}

.whatsapp-button a {
    display: block;
    width: 60px;
    /* Adjust the width and height as needed */
    height: 60px;
    background-color: #25d366;
    /* WhatsApp green color */
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s;
}

.whatsapp-button img {
    width: 90%;
    /* Adjust the size of the WhatsApp icon */
    margin-top: 5%;
    /* Adjust the vertical alignment of the icon */
}

.whatsapp-button a:hover {
    background-color: #128c7e;
    /* Darker green color on hover */
}


.register-form .form-group i {
    position: absolute;
    top: 14px;
    right: 15px;
    line-height: 1;
    font-size: 18px;
    color: #7f7f7f;
    z-index: 1;
}

.bx {
    font-family: 'boxicons' !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
